import React, { useState, useEffect } from "react";
import { Table, Button, Tabs, Tab, Spinner } from "react-bootstrap";
import ReactPaginate from "react-paginate";
// import PropTypes from 'prop-types';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom";
import useGameHistory from "./useGameHistory";
import { casinoServer, client } from "../../config/keys";
import Result from "../RouleteeResultPopop/result";
import { useSelector } from "react-redux";
import user from "../../assets/images/header/owner.png";
import RouleteeSvg from "../../assets/images/footer/roulette.svg";
import BTC from "../../assets/images/roulette/btc.png";
import ETH from "../../assets/images/roulette/eth.png";
import LTC from "../../assets/images/roulette/ltc.png";
import doller from "../../assets/images/roulette/doller.jpg";
import WinHistory from "./WinHistory";
import LossHistory from "./LossHistory";
const BetHistory = () => {
  const gameCounts = useSelector((state) => state.game);
  const { historyCount } = gameCounts;
  const pageLimit = 10;
  const [lastBetsSkip] = useState(0);
  const [historyBetsSkip, setHistoryBetsSkip] = useState(0);
  const [userLastBetsSkip] = useState(0);
  const [historyPageCount, setHistoryPageCount] = useState();
  const [gameHistory, gamesBets, userBets, loading] = useGameHistory(
    lastBetsSkip,
    historyBetsSkip,
    userLastBetsSkip
  );
  const itemperPage = 10;
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState(null);
  const [show, setShow] = useState(false);
  const [gameResult, setGameResult] = useState();
  const [historySelected, setHistorySelected] = useState();
  const { winners } = useLocation();
  let activeState = winners || {};
  if (!winners) {
    activeState = "ALL BETS";
  }

  useEffect(() => {
    console.log("hhhh");
    setHistoryPageCount(Math.ceil(historyCount / pageLimit));
  }, [historyCount]);

  const historyHandlePageClick = ({ selected }) => {
    setHistoryBetsSkip(selected * pageLimit);
    setHistorySelected(selected);
  };

  const userBetsHandlePageClick = (event) => {
    const newOffset = (event.selected * itemperPage) % userBets.length;
    setItemOffset(newOffset);
  };

  const copyClipBoard = () => {
    toast.success("Copied to Clipboard", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: 1,
    });
  };

  const handleLogin = () => {
    window.location.href = `${client}?login=login`;
  };

  const handleShow = (gamedetails) => {
    setGameResult(gamedetails);
    setShow(true);
  };
  const handleClose = () => setShow(false);

  const handleAmount = (profit_loss) => {
    if (profit_loss) {
      return profit_loss?.toFixed(6);
    } else {
      return "0";
    }
  };

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemperPage;
    setCurrentItems(gamesBets.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(gamesBets.length / itemperPage));
  }, [itemOffset, itemperPage, gamesBets]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemperPage) % gamesBets.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemperPage;
    setCurrentItems(userBets.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(userBets.length / itemperPage));
  }, [itemOffset, itemperPage, userBets]);

  return (
    <div className="bottom-table">
      <Tabs defaultActiveKey={activeState} id={activeState}>
        <Tab eventKey="MY BETS" title="MY BETS">
          {loading ? (
            <div className="loader">
              <Spinner animation="border" role="status"></Spinner>
            </div>
          ) : (
            <Table bordered responsive variant="dark">
              <thead>
                <tr>
                  <th>Game</th>
                  <th> Bet Amount</th>
                  <th>Result</th>
                  <th>Payout (BSV)</th>
                </tr>
              </thead>
              <tbody>
                {localStorage.usertoken ? (
                  <>
                    {currentItems &&
                      currentItems?.length &&
                      currentItems.map((el, i) => (
                        <tr
                          onClick={() => handleShow(el)}
                          key={`gamesBetsTras-${Date.now() + i}`}
                          className="pointer-cursor"
                        >
                          <td>{el?.gameType ? el.gameType : "---"} </td>
                          <td>
                            {" "}
                            <img
                              src={
                                el.blockchain === "btc"
                                  ? BTC
                                  : el.blockchain === "ltc"
                                  ? LTC
                                  : ETH
                              }
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = doller;
                              }}
                            />
                            &nbsp;
                            {el?.betAmount
                              ? el.betAmount?.toFixed(6)
                              : "0"}{" "}
                          </td>
                          <td>
                            <span title={el?.result}>
                              {el?.result !== "undefined"
                                ? el?.result?.length > 25
                                  ? el?.result.slice(0, 25) + "..."
                                  : el?.result + "..."
                                : "---"}{" "}
                            </span>
                          </td>
                          <td
                            className={`${
                              el?.profit_loss
                                ? el.profit_loss > 0
                                  ? "text-success"
                                  : "text-danger"
                                : ""
                            }`}
                          >
                            {handleAmount(el?.profit_loss) > 0 ? (
                              <>
                                {
                                  <img
                                    src={
                                      el.blockchain === "btc"
                                        ? BTC
                                        : el.blockchain === "ltc"
                                        ? LTC
                                        : ETH
                                    }
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src = doller;
                                    }}
                                  />
                                }
                                {handleAmount(el?.profit_loss)}
                              </>
                            ) : (
                              <>
                                - {""}
                                {
                                  <img
                                    src={
                                      el.blockchain === "btc"
                                        ? BTC
                                        : el.blockchain === "ltc"
                                        ? LTC
                                        : ETH
                                    }
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src = doller;
                                    }}
                                  />
                                }
                                {handleAmount(el?.profit_loss).substring(1)}
                              </>
                            )}
                          </td>
                        </tr>
                      ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan={6} className="text-align-center">
                      <Button onClick={handleLogin} className="btn-warning">
                        {" "}
                        Login
                      </Button>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}

          <div className="pagination">
            {userBets?.length > 0 ? (
              <ReactPaginate
                previousLabel=" Previous"
                marginPagesDisplayed={-2}
                pageRangeDisplayed={3}
                pageCount={pageCount}
                onPageChange={userBetsHandlePageClick}
                containerClassName="pagination-chk"
                previousLinkClassName="pagination__link"
                nextLinkClassName="pagination__link"
                disabledClassName="pagination__link--disabled"
                activeClassName="pagination__link--active"
                nextLabel="Next "
              />
            ) : null}
          </div>
        </Tab>
        <Tab eventKey="ALL BETS" title="ALL BETS">
          {loading ? (
            <div className="loader">
              <Spinner animation="border" role="status"></Spinner>
            </div>
          ) : (
            <Table bordered responsive variant="dark">
              <thead>
                <tr>
                  <th>Game</th>
                  <th>User</th>
                  <th>Bet Amount</th>
                  <th>Result</th>
                  <th>Payout (BSV)</th>
                </tr>
              </thead>
              {console.log("gamesBets", gamesBets)}
              <tbody>
                {currentItems &&
                  currentItems?.length &&
                  currentItems.map((el, i) => (
                    <React.Fragment key={`gamesBetTr-${Date.now() + i}`}>
                      <tr
                        onClick={() => handleShow(el)}
                        key={`gamesBetTr-${Date.now() + i}`}
                        className="pointer-cursor"
                      >
                        <td>
                          <div className="user-pic">
                            <img src={RouleteeSvg} alt="jdhjfhdsjjfd" />{" "}
                            {el?.gameType ? el?.gameType : "---"}{" "}
                          </div>
                        </td>
                        <td>
                          <div className="user-pic">
                            <img
                              src={
                                el?.userid?.profilePic
                                  ? `${casinoServer}/${el?.userid?.profilePic}`
                                  : user
                              }
                              alt="avtar"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = user;
                              }}
                            />{" "}
                            <span title={el?.userid?.username}>
                              {el?.userid?.username.length > 8
                                ? el?.userid?.username.slice(0, 8) + "..."
                                : el?.userid?.username}
                            </span>
                          </div>
                        </td>
                        <td>
                          <img
                            src={
                              el.blockchain === "btc"
                                ? BTC
                                : el.blockchain === "ltc"
                                ? LTC
                                : ETH
                            }
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = doller;
                            }}
                          />
                          &nbsp;
                          {el?.betAmount ? el.betAmount?.toFixed(6) : "0"}
                        </td>
                        <td>
                          {" "}
                          <span title={el?.result}>
                            {el?.result !== "undefined"
                              ? el?.result?.length > 25
                                ? el?.result.slice(0, 25) + "..."
                                : el?.result
                              : "---"}{" "}
                          </span>
                        </td>
                        <td
                          className={`${
                            el?.profit_loss
                              ? el.profit_loss > 0
                                ? "text-success"
                                : "text-danger"
                              : ""
                          }`}
                        >
                          {handleAmount(el?.profit_loss) > 0 ? (
                            <>
                              {" "}
                              {
                                <img
                                  src={
                                    el.blockchain === "btc"
                                      ? BTC
                                      : el.blockchain === "ltc"
                                      ? LTC
                                      : ETH
                                  }
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = doller;
                                  }}
                                />
                              }
                              {handleAmount(el?.profit_loss)}
                            </>
                          ) : (
                            <>
                              - {""}
                              {
                                <img
                                  src={
                                    el.blockchain === "btc"
                                      ? BTC
                                      : el.blockchain === "ltc"
                                      ? LTC
                                      : ETH
                                  }
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = doller;
                                  }}
                                />
                              }
                              {handleAmount(el?.profit_loss).substring(1)}
                            </>
                          )}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </Table>
          )}
          <div className="pagination">
            {gamesBets?.length > 0 ? (
              <ReactPaginate
                previousLabel=" Previous"
                marginPagesDisplayed={-2}
                pageRangeDisplayed={3}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName="pagination-chk"
                previousLinkClassName="pagination__link"
                nextLinkClassName="pagination__link"
                disabledClassName="pagination__link--disabled"
                activeClassName="pagination__link--active"
                nextLabel="Next "
              />
            ) : null}
          </div>
        </Tab>
        <Tab eventKey="WINNERS" title="WINNERS">
          {loading ? (
            <div className="loader">
              <Spinner animation="border" role="status"></Spinner>
            </div>
          ) : (
            <Table bordered responsive variant="dark">
              <thead>
                <tr>
                  <th>#Nonce</th>
                  <th>Seed Hash</th>
                  <th>Result</th>
                  <th>Verify</th>
                </tr>
              </thead>

              <tbody>
                {gameHistory &&
                  gameHistory.length &&
                  gameHistory.map((el, i) => {
                    return (
                      <React.Fragment key={`key-value-${i}`}>
                        <tr>
                          <td>{el.nonce}</td>
                          <td>
                            {el?.secret && el?.secret.slice(0, 50)}...
                            <CopyToClipboard text={el.secret}>
                              <Button
                                className="copy-btn"
                                onClick={() => {
                                  copyClipBoard();
                                }}
                              >
                                <i className="fa fa-copy"></i>
                              </Button>
                            </CopyToClipboard>
                          </td>
                          <td>
                            {el?.result && el?.result?.number},
                            {el?.result && el?.result?.color}
                          </td>
                          <td>
                            <Link
                              to={`/rouletteresult/${el?.secret}/${el?.nonce}`}
                              target="_blank"
                            >
                              Verify
                            </Link>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
              </tbody>
            </Table>
          )}
          <div className="pagination">
            {gameHistory?.length > 0 ? (
              <ReactPaginate
                previousLabel=" Previous"
                marginPagesDisplayed={-2}
                pageRangeDisplayed={3}
                pageCount={historyPageCount}
                onPageChange={historyHandlePageClick}
                forcePage={historySelected}
                containerClassName="pagination-chk"
                previousLinkClassName="pagination__link"
                nextLinkClassName="pagination__link"
                disabledClassName="pagination__link--disabled"
                activeClassName="pagination__link--active"
                nextLabel="Next "
              />
            ) : null}
          </div>
        </Tab>
        <Tab eventKey="WIN" title="WIN">
          <WinHistory />
        </Tab>
        <Tab eventKey="LOSS" title="LOSS">
          <LossHistory />
        </Tab>
      </Tabs>
      <>
        <Result show={show} handleClose={handleClose} gameResult={gameResult} />
      </>
    </div>
  );
};

export default BetHistory;
