import { useEffect } from 'react';
import { socket } from '../../config/socket';
import { useDispatch } from 'react-redux';
import { addResultGameInfo } from '../../reducers/gameReducers';

const useGameHistorySocket = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    socket.on('gameResult', (data) => {
      const { type, bet } = data;
      if (type === 'gameResultInfo') {
        dispatch(addResultGameInfo({ bet }));
      }
    });
    return () => {
      socket.off('gameResult');
    };
  }, []);
};

export default useGameHistorySocket;
