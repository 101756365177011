import React, { useEffect, useState, useRef } from "react";
import { Dropdown, Button, Modal, Tabs, Tab, Card } from "react-bootstrap";
import PropTypes from "prop-types";
import user from "../../assets/images/header/owner.png";
// import menu1 from "../../assets/images/header/menu1.svg";
// import menu2 from "../../assets/images/header/menu5.svg";
// import mainlogo from "../../assets/images/header/logo-main.png";
// import mainlogoborder from "../../assets/images/header/logo-main-border.png";
import { client, casinoServer } from "../../config/keys";
import {
  Changecurrency,
  clearStateAfterLogout,
} from "../../reducers/gameReducers";
import { useSelector, useDispatch } from "react-redux";
import BTC from "../../assets/images/roulette/btc.png";
import ETH from "../../assets/images/roulette/eth.png";
import LTC from "../../assets/images/roulette/ltc.png";
import close from "../../assets/images/header/close.svg";
import { gameSound } from "../../reducers/gameReducers";
import MobileStatsPopup from "../../components/roulette/BetTable/MobileStatsPopup";
import arrow from "../../assets/images/roulette/down-arrow.png";
import ShowFixBetAmountMemo from "../roulette/ShowFixBetAmount";
import LeaveConfirmPopup from "../roulette/leaveConfirmation";

const Header = ({ setShowMobileBet, setShow, show }) => {
  //const [playSounds, setPlaySounds] = useState();
  // const [showMobileBets, setMobileShowBets] = useState(showMobileBet);

  //   const { profilePic } = userData || {};
  const [isSticky, setIsSticky] = useState(false);
  const [showSelect, setShowSelect] = useState(false);
  const [confirmExit, setConfirmExit] = useState(false);

  const dispatch = useDispatch();
  //   const selectref = useRef(null);
  const gameObj = useSelector((state) => state.game);
  const { cryptoWallet, profilePic, username } = gameObj.userData ?? {};
  const playSound = gameObj.playSound;

  const {
    selectedCurrency: { walletAmount, currency, blockchain },
  } = gameObj ?? {};
  // const showMobileBet=gameObj.showMobileBet;
  const handleHome = () => {
    window.location.href = `${client}`;
  };

  const watchScroll = () => {
    if (window.scrollY > 60) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    if (cryptoWallet) {
      const currData = {
        amount: cryptoWallet[blockchain.toLowerCase()].amount,
        curr: currency || "BTC",
        blockchain: blockchain || "btc",
      };
      dispatch(Changecurrency(currData));
    }
  }, [cryptoWallet]);

  useEffect(() => {
    if (window !== undefined && window.screen.width >= 0) {
      window.addEventListener("scroll", watchScroll);
    }
    return () => {
      window.removeEventListener("scroll", watchScroll);
    };
  }, []);

  const handlePlay = () => {
    localStorage.setItem("playSound", !playSound);
    //  setPlaySounds(!playSound);
    dispatch(gameSound(!playSound));
  };
  const handleProfle = () => {
    //window.location.href = `${client}/profile`;
    window.location.href = `${client}/profile`;
  };
  const handleWithdrawDeposit = () => {
    window.location.href = `${client}/withDrawDeposit`;
  };
  // const handleRegister = () => {
  //   window.location.href = `${client}?signup=signup`;
  // };

  // const handleLogin = () => {
  //   window.location.href = `${client}?login=login&gameType=roulette`;
  // };
  const handleShow = () => setShow(true);

  const handleLogOut = () => {
    dispatch(clearStateAfterLogout());
    localStorage.removeItem("usertoken");
    window.location.href = `${client}/`;
  };

  const handleClose = () => setShow(false);

  const handleSelect = () => {
    setShowSelect(true);
  };

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (event.srcElement.className !== "close-option") {
            setShowSelect(false);
          }
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const handleCurrency = (curr, amount) => {
    if (curr === "BTC") {
      //   setCurrency(BTC);
      //   setWalletAmount(amount);
      //   setShowSelect(false);
      //   setSelectedBlockChain("btc");
      const currData = {
        amount: amount,
        curr: "BTC",
        blockchain: "btc",
      };
      dispatch(Changecurrency(currData));
      setShowSelect(false);
    } else if (curr === "ETH") {
      const currData = {
        amount: amount,
        curr: "ETH",
        blockchain: "eth",
      };
      dispatch(Changecurrency(currData));
      setShowSelect(false);
    } else {
      const currData = {
        amount: amount,
        curr: "LTC",
        blockchain: "ltc",
      };
      dispatch(Changecurrency(currData));
      setShowSelect(false);
    }
  };

  const currImage = () => {
    if (currency === "BTC") {
      return BTC;
    }
    if (currency === "LTC") {
      return LTC;
    }
    if (currency === "ETH") {
      return ETH;
    }
  };
  const handleExitGame = () => {
    setConfirmExit(true);
  };
  return (
    <div className={`header ${isSticky ? " sticky" : ""}`}>
      <div className="container">
        <div className="header-top-area">
          <div className="main-game-list">
            <div className="header-logo wow animate__animated animate__slideInLeft">
              <div onClick={() => handleHome()}>
                {/* <img src={mainlogo} alt="logo" className="main-logo" /> */}
                {/* <img src={mainlogo} alt="logo" className="main-logo" /> */}

                {/* <img
                  src={mainlogoborder}
                  alt="logo"
                  className="main-logo-border"
                /> */}
              </div>
            </div>

            <Button
              onClick={() => {
                setShowMobileBet(true);
              }}
              // onClick={() => dispatch(mobileBet(!showMobileBet))}
              className="mobile-view-button btn-sm display-none-all"
              style={{ display: "none" }}
            >
              Place Bet
            </Button>
            <MobileStatsPopup />
            <div className="header-top-login">
              <div className="loggedin-menu wow animate__animated animate__slideInRight">
                <div className="volume-btn">
                  <button onClick={handlePlay}>
                    {playSound ? (
                      <i className="fa fa-volume-up"></i>
                    ) : (
                      <i className="fa fa-volume-down"></i>
                    )}
                  </button>
                </div>
                <div className="rules-box">
                  <span role="presentation" onClick={handleShow}>
                    Rules
                  </span>
                </div>
                {localStorage.getItem("usertoken") && (
                  <div className="wallet-input">
                    <div className="close-option">
                      <div className="custom-select-option" ref={wrapperRef}>
                        <h6 onClick={handleSelect}>
                          <div
                            className={`select-balance ${
                              showSelect ? `active-true` : ``
                            }`}
                          >
                            <img src={currImage()} className="icon-img" />{" "}
                            <ShowFixBetAmountMemo
                              betTotal={walletAmount || 0}
                            />
                          </div>
                          <img src={arrow} alt="arrow" className="arrow-img" />
                        </h6>
                        {showSelect && (
                          <div className="select-option">
                            <p
                              onClick={() =>
                                handleCurrency(
                                  "BTC",
                                  cryptoWallet?.btc?.amount || 0
                                )
                              }
                            >
                              {" "}
                              <img src={BTC} alt="btc" />
                              <ShowFixBetAmountMemo
                                betTotal={cryptoWallet?.btc?.amount || 0}
                              />
                            </p>
                            <p
                              onClick={() =>
                                handleCurrency(
                                  "ETH",
                                  cryptoWallet?.eth?.amount || 0
                                )
                              }
                            >
                              <img src={ETH} alt="eth" />
                              <ShowFixBetAmountMemo
                                betTotal={cryptoWallet?.eth?.amount || 0}
                              />
                            </p>
                            <p
                              onClick={() =>
                                handleCurrency(
                                  "LTC",
                                  cryptoWallet?.ltc?.amount || 0
                                )
                              }
                            >
                              <img src={LTC} alt="ltc" />
                              <ShowFixBetAmountMemo
                                betTotal={cryptoWallet?.ltc?.amount || 0}
                              />
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div>
                  {!localStorage.getItem("usertoken") ? (
                    ""
                  ) : (
                    // <div className="">
                    //   <ul>
                    //     <li>
                    //       <span
                    //         role="presentation"
                    //         onClick={() => handleLogin()}
                    //       >
                    //         Login
                    //       </span>
                    //     </li>
                    //     <li className="or"></li>
                    //     <li>
                    //       <span
                    //         role="presentation"
                    //         onClick={() => handleRegister()}
                    //       >
                    //         Sign Up
                    //       </span>
                    //     </li>
                    //   </ul>
                    // </div>
                    <div className="my-profile-menu">
                      <div className="p-pic">
                        <img
                          src={
                            profilePic ? `${casinoServer}/${profilePic}` : user
                          }
                          alt="avtar"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = user;
                          }}
                        />
                      </div>
                      <div className="p-name">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-profile"
                          >
                            <span title={username}>
                              {username ? (
                                <>
                                  {username.length > 8
                                    ? username?.slice(0, 8) + "..."
                                    : username}
                                </>
                              ) : (
                                ""
                              )}
                            </span>{" "}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleProfle()}>
                              Profile
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleWithdrawDeposit()}
                            >
                              Withdraw/Deposit
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleLogOut()}>
                              Log Out
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  )}
                </div>
                <Button className="exit-game" onClick={handleExitGame}>
                  <i className="fas fa-sign-out-alt" aria-hidden="true"></i>{" "}
                  Exit Game
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} className="rules-popup" centered>
        <Modal.Header closeButton>
          <Modal.Title>American Roulette</Modal.Title>
        </Modal.Header>
        <div className="close-icon">
          <img src={close} alt="close" onClick={handleClose} />
        </div>
        <Modal.Body>
          <Tabs
            defaultActiveKey="home"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="home" title="Rules">
              <div className="rules-tab">
                <h4>
                  {" "}
                  <b>American Roulette</b>
                </h4>
                <p>
                  Roulette is one of the most popular casino games in the
                  history of gambling. It is a simple and straightforward game,
                  enjoyed by everyone. Roulette is a game of guessing which
                  number slot the ball will land on the wheel. The European
                  Roulette version has only one zero, which offers a much better
                  chance to win for players than the American Roulette with two
                  zeros.
                </p>
                <p>
                  The American Roulette consists of a wheel with 38 slots (ball
                  pockets) and a ball that can land with equal chance on any of
                  those slots. The numbers on the wheel from 1 to 36 are
                  alternatively colored red and black. In addition, the wheel
                  has two green “0”,“00”. While the wheel is moving, the ball
                  can land on any slot, either black or red, even number or odd
                  number, or 0. Players can make multiple bets, on a single
                  number or a series of numbers, on a color and so on.
                </p>
                <b>How to Play</b>
                <p>Choose the betting amount</p>
                <p>
                  You should first select the value of chip. The default chip
                  value is 0.005 BSV.
                </p>
                <b>Select the betting area</b>
                <p>
                  Choose where to bet. It can be on a single number or several
                  numbers. Check Roulette bets section to see the selection of
                  bet combinations that pay differently in accordance with the
                  game.
                </p>
                <b>Bet and Spinning Starts</b>
                <p>
                  After you select your numbers and place the chips, you should
                  swipe/click the bet button and spinning will start.
                </p>
                <b>You Won!</b>
                <p>
                  If you have selected the right area and placed a bet, you will
                  receive the prize accordingly. You can check the winning areas
                  and you can start new game by selecting numbers.
                </p>
                <b>Bet Limits</b>
                <p>
                  The minimum amount users can bet is 0.001 BSV. In order to
                  manage the payout risk, the maximum amount that users can bet
                  is flexible in each round based the bankroll status. If a user
                  bets more than we allow, the total bet amount will be returned
                  to the user. Please check table limits section on Roulette
                  page.
                </p>
              </div>
            </Tab>
            <Tab eventKey="profile" title="Bets" className="profile-tabs">
              <h3>Roulette Bets</h3>
              <h6>inside bets</h6>
              <div className="rules-tab profile-tab">
                <Card>
                  <Card.Body variant="Secondary">
                    <b>Six Line</b>
                    <p>
                      This bet covers 6 numbers, you can place a bet on the
                      exterior top border line where the line dividing the two
                      streets is cut. Payout: x6
                    </p>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body variant="Secondary">
                    <b>Corner</b>
                    <p>
                      You can place a bet on four numbers at a time by placing
                      chips on the corners where the four numbers meet. Payout:
                      x9
                    </p>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body variant="Secondary">
                    <b>Street</b>
                    <p>
                      The bet covers three numbers that go in a sequence. The
                      player can also bet on the following groups: 0, 1 and 2 or
                      0, 2 and 3 Payout: x12
                    </p>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body variant="Secondary">
                    <b>Split</b>
                    <p>
                      This bet is placed on more than one number by placing your
                      chip(s) on the line between two numbers. Payout: x18
                    </p>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body variant="Secondary">
                    <b>Straight Up</b>
                    <p>
                      This is a bet that covers only one number and you can
                      place it on any single number as well as on 0. Payout: x36
                    </p>
                  </Card.Body>
                </Card>
                <b>Outside Bets</b>
                <Card>
                  <Card.Body variant="Secondary">
                    <b>Red/Black</b>
                    <p>
                      To be placed on either the “Red” or “Black” boxes of the
                      betting area. There are 18 red numbers and 18 black
                      numbers. The 0 is not included. Payout: x2
                    </p>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body variant="Secondary">
                    <b>Even/Odd</b>
                    <p>
                      To be placed on either the “Odd” or “Even” boxes of the
                      betting area. There are 18 odd numbers and 18 even
                      numbers. The 0 is not included. Payout: x2
                    </p>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body variant="Secondary">
                    <b>Low/High</b>
                    <p>
                      This bet covers 18 numbers. You can place the bet on
                      either the “1 to 18” or “19 to 36”. The 0 is not included.
                      Payout: x2
                    </p>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body variant="Secondary">
                    <b>Dozen</b>
                    <p>
                      This bet covers 12 numbers. The boxes are tagged “1st 12”,
                      “2nd 12” and “3rd 12”. You can bet on any of the three
                      columns by placing chips on these boxes. Payout: x3
                    </p>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body variant="Secondary">
                    <b>Column</b>
                    <p>
                      This bet covers 12 numbers. The boxes are tagged “2 to 1”.
                      You can bet on any of the three columns by placing chips
                      on the “2 to 1” tagged boxes. Payout: x3
                    </p>
                  </Card.Body>
                </Card>
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
      <LeaveConfirmPopup
        setConfirmExit={setConfirmExit}
        confirmExit={confirmExit}
        // handleExitRoom={handleExitRoom}
      />
    </div>
  );
};
export default Header;
Header.propTypes = {
  setShowMobileBet: PropTypes.func,
  setShow: PropTypes.any,
  show: PropTypes.bool,
};
