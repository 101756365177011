import React from "react";
import PropTypes from "prop-types";
import ShowFixBetAmountMemo from "../ShowFixBetAmount";
// import { Button } from "react-bootstrap";
// import ShowFixBetAmountMemo from "../ShowFixBetAmount";
const PayoutBetSection = ({
  selectedBetsTest,
  //resetOneBet,
  //makeBet,
  // resetAllBet,
  // prevSelectedBet,
  // revertResetBets,
  hover,
  bettingTimer,
  // selectedBlockchain,
  getBets,
}) => {
  console.log("datatattatat", getBets());
  console.log("hoverhover", hover);

  return (
    <>
      <div
        className={`payout-bet-section ${
          bettingTimer > 4 && selectedBetsTest && selectedBetsTest.totalBet > 0
            ? "revert-bet"
            : ""
        }`}
      >
        {/* {bettingTimer > 4 && selectedBetsTest && selectedBetsTest.totalBet > 0 && (
					<div className="bet-revert-section">
						{bettingTimer > 4 &&
							selectedBetsTest &&
							selectedBetsTest.totalBet > 0 && (
								<span
									title="revert one bet"
									className="bet-revert-icon"
									onClick={resetOneBet}
								>
									{' '}
									<i className="fa fa-undo"></i>
								</span>
							)}

						{bettingTimer > 4 &&
							selectedBetsTest &&
							selectedBetsTest.totalBetAmount > 0 && (
								<span
									title="reset all bets"
									className="bet-revert-icon"
									onClick={resetAllBet}
								>
									{' '}
									<i className="fa fa-times"></i>
								</span>
							)}

						{prevSelectedBet && prevSelectedBet.totalBet > 0 && (
							<span
								title="Revert previous reset bets"
								className="bet-revert-icon"
								onClick={revertResetBets}
							>
								{' '}
								<i className="fa fa-history"></i>
							</span>
						)}
					</div>
				)} */}
        <div className="payouts-game">
          <div className="payout-box">
            <span>Type</span>
            <span>{hover.type ? hover.type : "-"}</span>
          </div>
          <div className="payout-box">
            <span>Numberss</span>
            <span>
              {hover.value.length > 0 ? (
                <span>
                  {hover.specialValueType !== ""
                    ? hover.specialValueType
                    : hover.value.map((el) => el).join(",")}
                </span>
              ) : (
                "-"
              )}
            </span>
          </div>
          <div className="payout-box">
            <span>Payout</span>
            <span>{hover.payOut ? "x" + hover.payOut : "-"}</span>
          </div>
          <div className="payout-box">
            <span>Bets</span>
            <span>
              <ShowFixBetAmountMemo
                betTotal={
                  getBets().length > 0 &&
                  getBets()
                    .filter(
                      (el) =>
                        el.value[0] === hover.value[0] && el.type === hover.type
                    )
                    .reduce((prev, cur) => prev + cur.betAmount, 0)
                }
              />
            </span>
          </div>
        </div>
        {/* <GameHistory /> */}
        {/* Make bet here */}
      </div>
    </>
  );
};
export default PayoutBetSection;
PayoutBetSection.propTypes = {
  hover: PropTypes.object,
  selectedBetsTest: PropTypes.object,
  userBets: PropTypes.object,
  resetOneBet: PropTypes.func,
  makeBet: PropTypes.func,
  resetAllBet: PropTypes.func,
  prevSelectedBet: PropTypes.func,
  revertResetBets: PropTypes.func,
  bettingTimer: PropTypes.Number,
  selectedBlockchain: PropTypes.String,
  getBets: PropTypes.func,
};
