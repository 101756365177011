import { useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const useRevertBet = () => {
  const initialObj = {
    btc: {
      straight0: [],
      straight00: [],
      straight1: [],
      straight2: [],
      straight3: [],
      straight4: [],
      straight5: [],
      straight6: [],
      straight7: [],
      straight8: [],
      straight9: [],
      straight10: [],
      straight11: [],
      straight12: [],
      straight13: [],
      straight14: [],
      straight15: [],
      straight16: [],
      straight17: [],
      straight18: [],
      straight19: [],
      straight20: [],
      straight21: [],
      straight22: [],
      straight23: [],
      straight24: [],
      straight25: [],
      straight26: [],
      straight27: [],
      straight28: [],
      straight29: [],
      straight30: [],
      straight31: [],
      straight32: [],
      straight33: [],
      straight34: [],
      straight35: [],
      straight36: [],
      split0And00: [],
      split1And2: [],
      split2And3: [],
      split4And5: [],
      split5And6: [],
      split7And8: [],
      split8And9: [],
      split10And11: [],
      split11And12: [],
      split13And14: [],
      split14And15: [],
      split16And17: [],
      split17And18: [],
      split19And20: [],
      split20And21: [],
      split22And23: [],
      split23And24: [],
      split25And26: [],
      split26And27: [],
      split28And29: [],
      split29And30: [],
      split31And32: [],
      split32And33: [],
      split34And35: [],
      split35And36: [],
      split3And6: [],
      split3And2: [],
      split2And5: [],
      split1And4: [],
      split6And9: [],
      split6And5: [],
      split5And8: [],
      split4And7: [],
      split9And12: [],
      split8And11: [],
      split7And10: [],
      split12And15: [],
      split11And14: [],
      split10And13: [],
      split15And18: [],
      split14And17: [],
      split13And16: [],
      split18And21: [],
      split17And20: [],
      split16And19: [],
      split21And24: [],
      split20And23: [],
      split19And22: [],
      split24And27: [],
      split23And26: [],
      split22And25: [],
      split27And30: [],
      split26And29: [],
      split25And28: [],
      split30And33: [],
      split29And32: [],
      split28And31: [],
      split33And36: [],
      split32And35: [],
      split31And34: [],
      corner1to5: [],
      corner2to6: [],
      corner4to8: [],
      corner5to9: [],
      corner7to11: [],
      corner8to12: [],
      corner10to14: [],
      corner11to15: [],
      corner13to17: [],
      corner14to18: [],
      corner16to20: [],
      corner17to21: [],
      corner19to23: [],
      corner20to24: [],
      corner22to26: [],
      corner23to27: [],
      corner25to29: [],
      corner26to30: [],
      corner28to32: [],
      corner29to33: [],
      corner31to35: [],
      corner32to36: [],
      street1to3: [],
      street4to6: [],
      street7to9: [],
      street10to12: [],
      street13to15: [],
      street16to18: [],
      street19to21: [],
      street22to24: [],
      street25to27: [],
      street28to30: [],
      street31to33: [],
      street34to36: [],
      sixline1to6: [],
      sixline4to9: [],
      sixline7to12: [],
      sixline10to15: [],
      sixline13to18: [],
      sixline16to21: [],
      sixline19to24: [],
      sixline22to27: [],
      sixline25to30: [],
      sixline28to33: [],
      sixline31to36: [],
      column1st: [],
      column2nd: [],
      column3rd: [],
      dozen1st12: [],
      dozen2nd12: [],
      dozen3rd12: [],
      low1to18: [],
      high19to36: [],
      even: [],
      odd: [],
      red: [],
      Black: [],
      totalBet: 0,
      totalBetAmount: 0,
    },
    ltc: {
      straight0: [],
      straight00: [],
      straight1: [],
      straight2: [],
      straight3: [],
      straight4: [],
      straight5: [],
      straight6: [],
      straight7: [],
      straight8: [],
      straight9: [],
      straight10: [],
      straight11: [],
      straight12: [],
      straight13: [],
      straight14: [],
      straight15: [],
      straight16: [],
      straight17: [],
      straight18: [],
      straight19: [],
      straight20: [],
      straight21: [],
      straight22: [],
      straight23: [],
      straight24: [],
      straight25: [],
      straight26: [],
      straight27: [],
      straight28: [],
      straight29: [],
      straight30: [],
      straight31: [],
      straight32: [],
      straight33: [],
      straight34: [],
      straight35: [],
      straight36: [],
      split0And00: [],
      split1And2: [],
      split2And3: [],
      split4And5: [],
      split5And6: [],
      split6And5: [],
      split7And8: [],
      split8And9: [],
      split10And11: [],
      split11And12: [],
      split13And14: [],
      split14And15: [],
      split16And17: [],
      split17And18: [],
      split19And20: [],
      split20And21: [],
      split22And23: [],
      split23And24: [],
      split25And26: [],
      split26And27: [],
      split28And29: [],
      split29And30: [],
      split31And32: [],
      split32And33: [],
      split34And35: [],
      split35And36: [],
      split3And6: [],
      split3And2: [],
      split2And5: [],
      split1And4: [],
      split6And9: [],
      split5And8: [],
      split4And7: [],
      split9And12: [],
      split8And11: [],
      split7And10: [],
      split12And15: [],
      split11And14: [],
      split10And13: [],
      split15And18: [],
      split14And17: [],
      split13And16: [],
      split18And21: [],
      split17And20: [],
      split16And19: [],
      split21And24: [],
      split20And23: [],
      split19And22: [],
      split24And27: [],
      split23And26: [],
      split22And25: [],
      split27And30: [],
      split26And29: [],
      split25And28: [],
      split30And33: [],
      split29And32: [],
      split28And31: [],
      split33And36: [],
      split32And35: [],
      split31And34: [],
      corner1to5: [],
      corner2to6: [],
      corner4to8: [],
      corner5to9: [],
      corner7to11: [],
      corner8to12: [],
      corner10to14: [],
      corner11to15: [],
      corner13to17: [],
      corner14to18: [],
      corner16to20: [],
      corner17to21: [],
      corner19to23: [],
      corner20to24: [],
      corner22to26: [],
      corner23to27: [],
      corner25to29: [],
      corner26to30: [],
      corner28to32: [],
      corner29to33: [],
      corner31to35: [],
      corner32to36: [],
      street1to3: [],
      street4to6: [],
      street7to9: [],
      street10to12: [],
      street13to15: [],
      street16to18: [],
      street19to21: [],
      street22to24: [],
      street25to27: [],
      street28to30: [],
      street31to33: [],
      street34to36: [],
      sixline1to6: [],
      sixline4to9: [],
      sixline7to12: [],
      sixline10to15: [],
      sixline13to18: [],
      sixline16to21: [],
      sixline19to24: [],
      sixline22to27: [],
      sixline25to30: [],
      sixline28to33: [],
      sixline31to36: [],
      column1st: [],
      column2nd: [],
      column3rd: [],
      dozen1st12: [],
      dozen2nd12: [],
      dozen3rd12: [],
      low1to18: [],
      high19to36: [],
      even: [],
      odd: [],
      red: [],
      Black: [],
      totalBet: 0,
      totalBetAmount: 0,
    },
    eth: {
      straight0: [],
      straight00: [],
      straight1: [],
      straight2: [],
      straight3: [],
      straight4: [],
      straight5: [],
      straight6: [],
      straight7: [],
      straight8: [],
      straight9: [],
      straight10: [],
      straight11: [],
      straight12: [],
      straight13: [],
      straight14: [],
      straight15: [],
      straight16: [],
      straight17: [],
      straight18: [],
      straight19: [],
      straight20: [],
      straight21: [],
      straight22: [],
      straight23: [],
      straight24: [],
      straight25: [],
      straight26: [],
      straight27: [],
      straight28: [],
      straight29: [],
      straight30: [],
      straight31: [],
      straight32: [],
      straight33: [],
      straight34: [],
      straight35: [],
      straight36: [],
      split0And00: [],
      split1And2: [],
      split2And3: [],
      split3And2: [],
      split4And5: [],
      split5And6: [],
      split7And8: [],
      split8And9: [],
      split10And11: [],
      split11And12: [],
      split13And14: [],
      split14And15: [],
      split16And17: [],
      split17And18: [],
      split19And20: [],
      split20And21: [],
      split22And23: [],
      split23And24: [],
      split25And26: [],
      split26And27: [],
      split28And29: [],
      split29And30: [],
      split31And32: [],
      split32And33: [],
      split34And35: [],
      split35And36: [],
      split3And6: [],
      split2And5: [],
      split1And4: [],
      split6And9: [],
      split5And8: [],
      split6And5: [],
      split4And7: [],
      split9And12: [],
      split8And11: [],
      split7And10: [],
      split12And15: [],
      split11And14: [],
      split10And13: [],
      split15And18: [],
      split14And17: [],
      split13And16: [],
      split18And21: [],
      split17And20: [],
      split16And19: [],
      split21And24: [],
      split20And23: [],
      split19And22: [],
      split24And27: [],
      split23And26: [],
      split22And25: [],
      split27And30: [],
      split26And29: [],
      split25And28: [],
      split30And33: [],
      split29And32: [],
      split28And31: [],
      split33And36: [],
      split32And35: [],
      split31And34: [],
      corner1to5: [],
      corner2to6: [],
      corner4to8: [],
      corner5to9: [],
      corner7to11: [],
      corner8to12: [],
      corner10to14: [],
      corner11to15: [],
      corner13to17: [],
      corner14to18: [],
      corner16to20: [],
      corner17to21: [],
      corner19to23: [],
      corner20to24: [],
      corner22to26: [],
      corner23to27: [],
      corner25to29: [],
      corner26to30: [],
      corner28to32: [],
      corner29to33: [],
      corner31to35: [],
      corner32to36: [],
      street1to3: [],
      street4to6: [],
      street7to9: [],
      street10to12: [],
      street13to15: [],
      street16to18: [],
      street19to21: [],
      street22to24: [],
      street25to27: [],
      street28to30: [],
      street31to33: [],
      street34to36: [],
      sixline1to6: [],
      sixline4to9: [],
      sixline7to12: [],
      sixline10to15: [],
      sixline13to18: [],
      sixline16to21: [],
      sixline19to24: [],
      sixline22to27: [],
      sixline25to30: [],
      sixline28to33: [],
      sixline31to36: [],
      column1st: [],
      column2nd: [],
      column3rd: [],
      dozen1st12: [],
      dozen2nd12: [],
      dozen3rd12: [],
      low1to18: [],
      high19to36: [],
      even: [],
      odd: [],
      red: [],
      Black: [],
      totalBet: 0,
      totalBetAmount: 0,
    },
    totalBet: 0,
    totalBetAmount: 0,
  };

  const gameObj = useSelector((state) => state.game);
  const { userData, showingResult } = gameObj;
  const { cryptoWallet } = userData || {};
  const [selectedBets /*setSelectedBets*/] = useState(() => []);
  const [selectedBetsTest, setSelectedBetsTest] = useState(() => {
    return { ...initialObj };
  });
  // Every new bet will be added in bet stack
  // We use this do achive revert one bet
  // Because we are storing data in object of array
  // this is how we will ensure each and every bet
  const [betsStack, setBetsStack] = useState([]);
  //prevs = {...prevs, [blockchain]: {...prevs[blockchain], [type]: [ ...prevs[blockchain][type], {amount:1}]}};
  // If user use revert all bet function
  // so we will store all the last bets in this state
  // so if user wants bets back which he revert
  // so we can use this state in that case
  const [prevSelectedBet, setPrevSelectedBet] = useState(() => {
    return { ...initialObj };
  });

  const [previousBets, setPreviousBets] = useState({});
  const [lastBetsOrder, setLastBetsOrder] = useState([]);

  const getBets = () => {
    const betsCurrency = ["btc", "ltc", "eth"];
    let result = [];
    betsCurrency.forEach((el) => {
      // here we will get all bets
      Object.keys(selectedBetsTest[el]).forEach((elem) => {
        if (elem !== "totalBet" && elem !== "totalBetAmount") {
          result = [...result, ...selectedBetsTest[el][elem]];
        }
      });
    });
    return result;
  };
  // console.log("getBets", getBets());

  const addBet = (blockchain, betAmount, type, betType, value, timer) => {
    if (localStorage.getItem("usertoken") && timer <= 4) {
      toast.error("You cant bet now ", {
        position: "top-right",
        autoClose: 5000,
        toastId: 1,
      });
      return;
    }

    let total = selectedBetsTest[blockchain.toLowerCase()][type].reduce(
      (prev, cur) => prev + cur.betAmount,
      0
    );

    total += betAmount;

    //console.log("getObjOfSameType", getObjOfSameType());

    const totalOb = {
      Straight: 0.2,
      Split: 0.5,
      Street: 0.8,
      Corner: 1.1,
      Sixline: 1.6,
      "1st Column": 3.3,
      "2nd Column": 3.3,
      "3rd Column": 3.3,
      "Dozen 1st 12": 3.3,
      "Dozen 2nd 12": 3.3,
      "Dozen 3rd 12": 3.3,
      Red: 5,
      Black: 5,
      Even: 5,
      Odd: 5,
      Low: 5,
      High: 5,
    };

    if (total > totalOb[betType]) {
      toast.error(
        `Max bet is ${
          totalOb[betType]
        } ${blockchain.toUpperCase()} for this area !`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: 1,
        }
      );
      return;
    }

    if (showingResult) {
      return;
    }

    if (!localStorage.getItem("usertoken")) {
      toast.error("Please login then you can bet !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 1,
      });
      return;
    }

    if (!cryptoWallet[blockchain]?.amount) {
      toast.error(`You dont have enough ${blockchain}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 1,
      });
      return;
    }

    if (betAmount <= 0) {
      toast.error("Bet amount should be greater then 0", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 1,
      });
      return;
    }

    if (betAmount > cryptoWallet[blockchain]?.amount) {
      toast.error("Amount is not sufficient to make bet!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 1,
      });
      return;
    }

    setSelectedBetsTest((prev) => {
      return {
        ...prev,
        [blockchain]: {
          ...prev[blockchain],
          [type]: [
            ...prev[blockchain][type],
            { betAmount, blockchain, type: betType, value },
          ],
          totalBet: prev[blockchain].totalBet + 1,
          totalBetAmount: prev[blockchain].totalBetAmount + betAmount,
        },
        totalBet: prev.totalBet + 1,
        totalBetAmount: prev.totalBetAmount + betAmount,
      };
    });

    setBetsStack((prev) => {
      return [...prev, { blockchain, betAmount, type }];
    });

    // const date = selectedBets
    //   .filter((el) => el.value[0] === value[0])
    //   .reduce((prev, curr) => prev + curr.betAmount, 1);

    // Uncommment letter if gets error ===============================
    // setSelectedBetsTest((prev) => {
    //   let dd = [...prev, { betAmount, type, value, blockchain }];
    //   let filter = dd.filter((el) => el.value[0] === value[0]);
    //   setNumberBet(filter);

    //   return [...prev, { betAmount, type, value, blockchain }];
    // });
    let element = document.getElementById("coinsound");
    if (element) {
      element.play();
    }
  };
  // this function will reset all bet and store
  // reset bet in PrevSelectedBet state
  const resetAllBet = () => {
    setPrevSelectedBet(() => {
      return JSON.parse(JSON.stringify(selectedBetsTest));
    });
    setSelectedBetsTest(() => {
      return { ...initialObj };
    });
  };

  // Revert reset bets
  const revertResetBets = () => {
    if (prevSelectedBet?.totalBet > 0) {
      setSelectedBetsTest(() => {
        return JSON.parse(JSON.stringify(prevSelectedBet));
      });
      console.log("HERE!@#$", JSON.stringify(selectedBetsTest));
      setPrevSelectedBet(() => {
        return { ...initialObj };
      });
    }
  };

  const resetOneBet = () => {
    //let lastbet = {};
    if (!betsStack.length) {
      return;
    }

    let prevBet = {};
    setBetsStack((prev) => {
      prevBet = prev.pop();
      return prev;
    });

    console.log({ prevBet });

    setSelectedBetsTest((prev) => {
      // We are removing one element from last bet
      // This is hapening below
      // SelectedBetsTest.btc.staight0.pop()
      const prevArr = [...prev[prevBet.blockchain][prevBet.type]];
      prevArr.pop();
      return {
        ...prev,
        [prevBet.blockchain]: {
          ...prev[prevBet.blockchain],
          [prevBet.type]: prevArr,
          totalBet: prev[prevBet.blockchain].totalBet - 1,
          totalBetAmount:
            prev[prevBet.blockchain].totalBetAmount - prevBet.betAmount,
        },
        totalBet: prev.totalBet - 1,
        totalBetAmount: prev.totalBetAmount - prevBet.betAmount,
      };
    });
  };

  const revertLastRoundBets = () => {
    setBetsStack(() => [...lastBetsOrder]);
    setSelectedBetsTest(() => JSON.parse(JSON.stringify(previousBets)));
    setLastBetsOrder(() => []);
  };

  const resetAfterBet = () => {
    setPreviousBets(() => JSON.parse(JSON.stringify(selectedBetsTest)));
    setLastBetsOrder(() => [...betsStack]);

    setPrevSelectedBet(() => []);
    setSelectedBetsTest(() => {
      return { ...initialObj };
    });
    setBetsStack([]);
  };

  return [
    selectedBets,
    prevSelectedBet,
    addBet,
    resetAllBet,
    revertResetBets,
    resetOneBet,
    resetAfterBet,
    selectedBetsTest,
    getBets,
    revertLastRoundBets,
    lastBetsOrder,
  ];
};

export default useRevertBet;
