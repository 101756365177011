import React, { useState } from 'react';
import { Modal, Tabs, Tab, Card } from 'react-bootstrap';
import close from '../../../assets/images/header/close.svg';
const GameRule = () => {
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	return (
		<>
			<Modal show={show} onHide={handleClose} className="rules-popup" centered>
				<Modal.Header closeButton>
					<Modal.Title>American Roulette</Modal.Title>
				</Modal.Header>
				<div className="close-icon">
					<img src={close} alt="close" onClick={handleClose} />
				</div>
				<Modal.Body>
					<Tabs
						defaultActiveKey="home"
						id="uncontrolled-tab-example"
						className="mb-3"
					>
						<Tab eventKey="home" title="Rules">
							<div className="rules-tab">
								<h4>
									{' '}
									<b>American Roulette</b>
								</h4>
								<p>
									Roulette is one of the most popular casino games in the
									history of gambling. It is a simple and straightforward game,
									enjoyed by everyone. Roulette is a game of guessing which
									number slot the ball will land on the wheel. The European
									Roulette version has only one zero, which offers a much better
									chance to win for players than the American Roulette with two
									zeros.
								</p>
								<p>
									The American Roulette consists of a wheel with 38 slots (ball
									pockets) and a ball that can land with equal chance on any of
									those slots. The numbers on the wheel from 1 to 36 are
									alternatively colored red and black. In addition, the wheel
									has two green “0”,“00”. While the wheel is moving, the ball
									can land on any slot, either black or red, even number or odd
									number, or 0. Players can make multiple bets, on a single
									number or a series of numbers, on a color and so on.
								</p>
								<b>How to Play</b>
								<p>Choose the betting amount</p>
								<p>
									You should first select the value of chip. The default chip
									value is 0.005 BSV.
								</p>
								<b>Select the betting area</b>
								<p>
									Choose where to bet. It can be on a single number or several
									numbers. Check Roulette bets section to see the selection of
									bet combinations that pay differently in accordance with the
									game.
								</p>
								<b>Bet and Spinning Starts</b>
								<p>
									After you select your numbers and place the chips, you should
									swipe/click the bet button and spinning will start.
								</p>
								<b>You Won!</b>
								<p>
									If you have selected the right area and placed a bet, you will
									receive the prize accordingly. You can check the winning areas
									and you can start new game by selecting numbers.
								</p>
								<b>Bet Limits</b>
								<p>
									The minimum amount users can bet is 0.001 BSV. In order to
									manage the payout risk, the maximum amount that users can bet
									is flexible in each round based the bankroll status. If a user
									bets more than we allow, the total bet amount will be returned
									to the user. Please check table limits section on Roulette
									page.
								</p>
							</div>
						</Tab>
						<Tab eventKey="profile" title="Bets" className="profile-tabs">
							<h3>Roulette Bets</h3>
							<h6>inside bets</h6>
							<div className="rules-tab profile-tab">
								<Card>
									<Card.Body variant="Secondary">
										<b>Six Line</b>
										<p>
											This bet covers 6 numbers, you can place a bet on the
											exterior top border line where the line dividing the two
											streets is cut. Payout: x6
										</p>
									</Card.Body>
								</Card>
								<Card>
									<Card.Body variant="Secondary">
										<b>Corner</b>
										<p>
											You can place a bet on four numbers at a time by placing
											chips on the corners where the four numbers meet. Payout:
											x9
										</p>
									</Card.Body>
								</Card>
								<Card>
									<Card.Body variant="Secondary">
										<b>Street</b>
										<p>
											The bet covers three numbers that go in a sequence. The
											player can also bet on the following groups: 0, 1 and 2 or
											0, 2 and 3 Payout: x12
										</p>
									</Card.Body>
								</Card>
								<Card>
									<Card.Body variant="Secondary">
										<b>Split</b>
										<p>
											This bet is placed on more than one number by placing your
											chip(s) on the line between two numbers. Payout: x18
										</p>
									</Card.Body>
								</Card>
								<Card>
									<Card.Body variant="Secondary">
										<b>Straight Up</b>
										<p>
											This is a bet that covers only one number and you can
											place it on any single number as well as on 0. Payout: x36
										</p>
									</Card.Body>
								</Card>
								<b>Outside Bets</b>
								<Card>
									<Card.Body variant="Secondary">
										<b>Red/Black</b>
										<p>
											To be placed on either the “Red” or “Black” boxes of the
											betting area. There are 18 red numbers and 18 black
											numbers. The 0 is not included. Payout: x2
										</p>
									</Card.Body>
								</Card>
								<Card>
									<Card.Body variant="Secondary">
										<b>Even/Odd</b>
										<p>
											To be placed on either the “Odd” or “Even” boxes of the
											betting area. There are 18 odd numbers and 18 even
											numbers. The 0 is not included. Payout: x2
										</p>
									</Card.Body>
								</Card>
								<Card>
									<Card.Body variant="Secondary">
										<b>Low/High</b>
										<p>
											This bet covers 18 numbers. You can place the bet on
											either the “1 to 18” or “19 to 36”. The 0 is not included.
											Payout: x2
										</p>
									</Card.Body>
								</Card>
								<Card>
									<Card.Body variant="Secondary">
										<b>Dozen</b>
										<p>
											This bet covers 12 numbers. The boxes are tagged “1st 12”,
											“2nd 12” and “3rd 12”. You can bet on any of the three
											columns by placing chips on these boxes. Payout: x3
										</p>
									</Card.Body>
								</Card>
								<Card>
									<Card.Body variant="Secondary">
										<b>Column</b>
										<p>
											This bet covers 12 numbers. The boxes are tagged “2 to 1”.
											You can bet on any of the three columns by placing chips
											on the “2 to 1” tagged boxes. Payout: x3
										</p>
									</Card.Body>
								</Card>
							</div>
						</Tab>
					</Tabs>
				</Modal.Body>
			</Modal>
		</>
	);
};
export default GameRule;
