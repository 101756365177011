import React from "react";
import PropTypes from "prop-types";
import ShowChipsMemo from "../ShowChips";

const WebBetTable = ({
  userBets,
  selectedBets,
  betAmount,
  addBet,
  selectedBlockchain,
  handleMouseOver,
  handleMouseOut,
  hover,
  bettingTimer,
  selectedBetsTest,
}) => {
  const toggleClass = (betNumber) => {
    if (hover.value.find((el) => el === betNumber)) {
      return "hover-class";
    }

    if (
      userBets?.length > 0 &&
      userBets.find((el) => el?.value?.find((ele) => ele === betNumber))
    ) {
      return "active-class";
    }

    if (
      selectedBets?.length > 0 &&
      selectedBets?.find((el) => el?.value?.find((ele) => ele === betNumber))
    ) {
      return "active-class";
    }

    return "";
  };

  return (
    <>
      <div className="rolulette-right-table-section" id="table">
        <div className="roulette-bet-table">
          <table role="grid" className="bet-table">
            <tbody>
              <tr>
                <td
                  className={`green ${toggleClass("0")}`}
                  rowSpan="7"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight0",
                      "Straight",
                      ["0"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["0"], "36", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  0
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight0?.length ||
                    !!selectedBetsTest?.ltc.straight0?.length ||
                    !!selectedBetsTest?.eth.straight0?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight0,
                        ...selectedBetsTest?.ltc.straight0,
                        ...selectedBetsTest?.eth.straight0,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area-zero"
                  rowSpan="7"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split0And00",
                      "Split",
                      ["0", "00"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["0", "00"], "18", "0,00")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split0And00?.length ||
                    !!selectedBetsTest?.ltc.split0And00?.length ||
                    !!selectedBetsTest?.eth.split0And00?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split0And00,
                        ...selectedBetsTest?.ltc.split0And00,
                        ...selectedBetsTest?.eth.split0And00,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`green ${toggleClass("00")}`}
                  rowSpan="7"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight00",
                      "Straight",
                      ["00"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["00"], "36", "00")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight00?.length ||
                    !!selectedBetsTest?.ltc.straight00?.length ||
                    !!selectedBetsTest?.eth.straight00?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight00,
                        ...selectedBetsTest?.ltc.straight00,
                        ...selectedBetsTest?.eth.straight00,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  00
                </td>
                <td className="selected-area" />
                <td className="selected-area" />
                <td className="selected-area" />
                <td className="selected-area" />
                <td className="selected-area" />
                <td className="selected-area" />
                <td className="selected-area" />
                <td className="selected-area" />
                <td className="selected-area" />
                <td className="selected-area" />
                <td className="selected-area" />
                <td className="selected-area" />
                <td className="selected-area" />
                <td className="selected-area" />
                <td className="selected-area" />
                <td className="selected-area" />
                <td className="selected-area" />
                <td className="selected-area" />
                <td className="selected-area" />
                <td className="selected-area" />
                <td className="selected-area" />
                <td className="selected-area" />
                <td className="selected-area" />
                <td className="selected-area" />
                <td className="selected-area" />
              </tr>
              <tr>
                <td
                  className={`red ${toggleClass("3")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight3",
                      "Straight",
                      ["3"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["3"], "36", "3")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight3?.length ||
                    !!selectedBetsTest?.ltc.straight3?.length ||
                    !!selectedBetsTest?.eth.straight3?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight3,
                        ...selectedBetsTest?.ltc.straight3,
                        ...selectedBetsTest?.eth.straight3,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  3
                </td>
                <td
                  className="selected-area split1"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split3And6",
                      "Split",
                      ["3", "6"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["3", "6"], "18", "3,6")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split3And6?.length ||
                    !!selectedBetsTest?.ltc.split3And6?.length ||
                    !!selectedBetsTest?.eth.split3And6?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split3And6,
                        ...selectedBetsTest?.ltc.split3And6,
                        ...selectedBetsTest?.eth.split3And6,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`black ${toggleClass("6")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight6",
                      "Straight",
                      ["6"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["6"], "36", "6")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {" "}
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight6?.length ||
                    !!selectedBetsTest?.ltc.straight6?.length ||
                    !!selectedBetsTest?.eth.straight6?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight6,
                        ...selectedBetsTest?.ltc.straight6,
                        ...selectedBetsTest?.eth.straight6,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  6
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split6And9",
                      "Split",
                      ["6", "9"],

                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["6", "9"], "18", "6,9")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split6And9?.length ||
                    !!selectedBetsTest?.ltc.split6And9?.length ||
                    !!selectedBetsTest?.eth.split6And9?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split6And9,
                        ...selectedBetsTest?.ltc.split6And9,
                        ...selectedBetsTest?.eth.split6And9,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`red ${toggleClass("9")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight9",
                      "Straight",
                      ["9"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["9"], "36", "9")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight9?.length ||
                    !!selectedBetsTest?.ltc.straight9?.length ||
                    !!selectedBetsTest?.eth.straight9?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight9,
                        ...selectedBetsTest?.ltc.straight9,
                        ...selectedBetsTest?.eth.straight9,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  9
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split9And12",
                      "Split",
                      ["9", "12"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["9", "12"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split9And12?.length ||
                    !!selectedBetsTest?.ltc.split9And12?.length ||
                    !!selectedBetsTest?.eth.split9And12?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split9And12,
                        ...selectedBetsTest?.ltc.split9And12,
                        ...selectedBetsTest?.eth.split9And12,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`red ${toggleClass("12")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight12",
                      "Straight",
                      ["12"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["12"], "36", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight12?.length ||
                    !!selectedBetsTest?.ltc.straight12?.length ||
                    !!selectedBetsTest?.eth.straight12?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight12,
                        ...selectedBetsTest?.ltc.straight12,
                        ...selectedBetsTest?.eth.straight12,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  12
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split12And15",
                      "Split",
                      ["12", "15"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["12", "15"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split12And15?.length ||
                    !!selectedBetsTest?.ltc.split12And15?.length ||
                    !!selectedBetsTest?.eth.split12And15?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split12And15,
                        ...selectedBetsTest?.ltc.split12And15,
                        ...selectedBetsTest?.eth.split12And15,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`black ${toggleClass("15")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight15",
                      "Straight",
                      ["15"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["15"], "36", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight15?.length ||
                    !!selectedBetsTest?.ltc.straight15?.length ||
                    !!selectedBetsTest?.eth.straight15?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight15,
                        ...selectedBetsTest?.ltc.straight15,
                        ...selectedBetsTest?.eth.straight15,
                      ]}
                    />
                  ) : (
                    ""
                  )}{" "}
                  15
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split15And18",
                      "Split",
                      ["15", "18"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["15", "18"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split15And18?.length ||
                    !!selectedBetsTest?.ltc.split15And18?.length ||
                    !!selectedBetsTest?.eth.split15And18?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split15And18,
                        ...selectedBetsTest?.ltc.split15And18,
                        ...selectedBetsTest?.eth.split15And18,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`red ${toggleClass("18")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight18",
                      "Straight",
                      ["18"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["18"], "36", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight18?.length ||
                    !!selectedBetsTest?.ltc.straight18?.length ||
                    !!selectedBetsTest?.eth.straight18?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight18,
                        ...selectedBetsTest?.ltc.straight18,
                        ...selectedBetsTest?.eth.straight18,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  18
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split18And21",
                      "Split",
                      ["18", "21"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["18", "21"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split18And21?.length ||
                    !!selectedBetsTest?.ltc.split18And21?.length ||
                    !!selectedBetsTest?.eth.split18And21?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split18And21,
                        ...selectedBetsTest?.ltc.split18And21,
                        ...selectedBetsTest?.eth.split18And21,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`red ${toggleClass("21")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight21",
                      "Straight",
                      ["21"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["21"], "36", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight21?.length ||
                    !!selectedBetsTest?.ltc.straight21?.length ||
                    !!selectedBetsTest?.eth.straight21?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight21,
                        ...selectedBetsTest?.ltc.straight21,
                        ...selectedBetsTest?.eth.straight21,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  21
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split21And24",
                      "Split",
                      ["21", "24"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["21", "24"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split21And24?.length ||
                    !!selectedBetsTest?.ltc.split21And24?.length ||
                    !!selectedBetsTest?.eth.split21And24?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split21And24,
                        ...selectedBetsTest?.ltc.split21And24,
                        ...selectedBetsTest?.eth.split21And24,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`black ${toggleClass("24")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight24",
                      "Straight",
                      ["24"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["24"], "36", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight24?.length ||
                    !!selectedBetsTest?.ltc.straight24?.length ||
                    !!selectedBetsTest?.eth.straight24?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight24,
                        ...selectedBetsTest?.ltc.straight24,
                        ...selectedBetsTest?.eth.straight24,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  24
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split24And27",
                      "Split",
                      ["24", "27"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["24", "27"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split24And27?.length ||
                    !!selectedBetsTest?.ltc.split24And27?.length ||
                    !!selectedBetsTest?.eth.split24And27?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split24And27,
                        ...selectedBetsTest?.ltc.split24And27,
                        ...selectedBetsTest?.eth.split24And27,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`red ${toggleClass("27")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight27",
                      "Straight",
                      ["27"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["27"], "36", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight27?.length ||
                    !!selectedBetsTest?.ltc.straight27?.length ||
                    !!selectedBetsTest?.eth.straight27?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight27,
                        ...selectedBetsTest?.ltc.straight27,
                        ...selectedBetsTest?.eth.straight27,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  27
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split27And30",
                      "Split",
                      ["27", "30"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["27", "30"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split27And30?.length ||
                    !!selectedBetsTest?.ltc.split27And30?.length ||
                    !!selectedBetsTest?.eth.split27And30?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split27And30,
                        ...selectedBetsTest?.ltc.split27And30,
                        ...selectedBetsTest?.eth.split27And30,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`red ${toggleClass("30")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight30",
                      "Straight",
                      ["30"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["30"], "36", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight30?.length ||
                    !!selectedBetsTest?.ltc.straight30?.length ||
                    !!selectedBetsTest?.eth.straight30?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight30,
                        ...selectedBetsTest?.ltc.straight30,
                        ...selectedBetsTest?.eth.straight30,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  30
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split30And33",
                      "Split",
                      ["30", "33"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["30", "33"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split30And33?.length ||
                    !!selectedBetsTest?.ltc.split30And33?.length ||
                    !!selectedBetsTest?.eth.split30And33?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split30And33,
                        ...selectedBetsTest?.ltc.split30And33,
                        ...selectedBetsTest?.eth.split30And33,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`black ${toggleClass("33")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight33",
                      "Straight",
                      ["33"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["33"], "36", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight33?.length ||
                    !!selectedBetsTest?.ltc.straight33?.length ||
                    !!selectedBetsTest?.eth.straight33?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight33,
                        ...selectedBetsTest?.ltc.straight33,
                        ...selectedBetsTest?.eth.straight33,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  33
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split33And36",
                      "Split",
                      ["33", "36"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["33", "36"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split33And36?.length ||
                    !!selectedBetsTest?.ltc.split33And36?.length ||
                    !!selectedBetsTest?.eth.split33And36?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split33And36,
                        ...selectedBetsTest?.ltc.split33And36,
                        ...selectedBetsTest?.eth.split33And36,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`red ${toggleClass("36")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight36",
                      "Straight",
                      ["36"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["36"], "36", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight36?.length ||
                    !!selectedBetsTest?.ltc.straight36?.length ||
                    !!selectedBetsTest?.eth.straight36?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight36,
                        ...selectedBetsTest?.ltc.straight36,
                        ...selectedBetsTest?.eth.straight36,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  36
                </td>
                <td className="selected-area" />
                <td
                  className={`black ${
                    hover.specialValueType === "Right" ? "hover-class" : ""
                  }`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "column1st",
                      "1st Column",
                      [
                        "3",
                        "6",
                        "9",
                        "12",
                        "15",
                        "18",
                        "21",
                        "24",
                        "27",
                        "30",
                        "33",
                        "36",
                      ],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver(
                      "Column",

                      [
                        "3",
                        "6",
                        "9",
                        "12",
                        "15",
                        "18",
                        "21",
                        "24",
                        "27",
                        "30",
                        "33",
                        "36",
                      ],
                      "3",
                      "Right"
                    )
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.column1st?.length ||
                    !!selectedBetsTest?.ltc.column1st?.length ||
                    !!selectedBetsTest?.eth.column1st?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.column1st,
                        ...selectedBetsTest?.ltc.column1st,
                        ...selectedBetsTest?.eth.column1st,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  2 to 1
                </td>
              </tr>
              <tr>
                <td
                  className="selected-area hhhhhhhhh"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split3And2",
                      "Split",
                      ["3", "2"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["3", "2"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split3And2?.length ||
                    !!selectedBetsTest?.ltc.split3And2?.length ||
                    !!selectedBetsTest?.eth.split3And2?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split3And2,
                        ...selectedBetsTest?.ltc.split3And2,
                        ...selectedBetsTest?.eth.split3And2,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "corner2to6",
                      "Corner",
                      ["2", "3", "5", "6"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Corner", ["2", "3", "5", "6"], "9", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.corner2to6?.length ||
                    !!selectedBetsTest?.ltc.corner2to6?.length ||
                    !!selectedBetsTest?.eth.corner2to6?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.corner2to6,
                        ...selectedBetsTest?.ltc.corner2to6,
                        ...selectedBetsTest?.eth.corner2to6,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split6And5",
                      "Split",
                      ["6", "5"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["6", "5"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split6And5?.length ||
                    !!selectedBetsTest?.ltc.split6And5?.length ||
                    !!selectedBetsTest?.eth.split6And5?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split6And5,
                        ...selectedBetsTest?.ltc.split6And5,
                        ...selectedBetsTest?.eth.split6And5,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "corner5to9",
                      "Corner",
                      ["5", "6", "8", "9"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Corner", ["5", "6", "8", "9"], "9", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.corner5to9?.length ||
                    !!selectedBetsTest?.ltc.corner5to9?.length ||
                    !!selectedBetsTest?.eth.corner5to9?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.corner5to9,
                        ...selectedBetsTest?.ltc.corner5to9,
                        ...selectedBetsTest?.eth.corner5to9,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split8And9",
                      "Split",
                      ["8", "9"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["8", "9"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split8And9?.length ||
                    !!selectedBetsTest?.ltc.split8And9?.length ||
                    !!selectedBetsTest?.eth.split8And9?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split8And9,
                        ...selectedBetsTest?.ltc.split8And9,
                        ...selectedBetsTest?.eth.split8And9,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "corner8to12",
                      "Corner",
                      ["8", "9", "11", "12"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Corner", ["8", "9", "11", "12"], "9", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.corner8to12?.length ||
                    !!selectedBetsTest?.ltc.corner8to12?.length ||
                    !!selectedBetsTest?.eth.corner8to12?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.corner8to12,
                        ...selectedBetsTest?.ltc.corner8to12,
                        ...selectedBetsTest?.eth.corner8to12,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split11And12",
                      "Split",
                      ["11", "12"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["11", "12"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split11And12?.length ||
                    !!selectedBetsTest?.ltc.split11And12?.length ||
                    !!selectedBetsTest?.eth.split11And12?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split11And12,
                        ...selectedBetsTest?.ltc.split11And12,
                        ...selectedBetsTest?.eth.split11And12,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "corner11to15",
                      "Corner",
                      ["11", "12", "14", "15"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Corner", ["11", "12", "14", "15"], "9", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.corner11to15?.length ||
                    !!selectedBetsTest?.ltc.corner11to15?.length ||
                    !!selectedBetsTest?.eth.corner11to15?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.corner11to15,
                        ...selectedBetsTest?.ltc.corner11to15,
                        ...selectedBetsTest?.eth.corner11to15,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split14And15",
                      "Split",
                      ["14", "15"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["14", "15"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split14And15?.length ||
                    !!selectedBetsTest?.ltc.split14And15?.length ||
                    !!selectedBetsTest?.eth.split14And15?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split14And15,
                        ...selectedBetsTest?.ltc.split14And15,
                        ...selectedBetsTest?.eth.split14And15,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "corner14to18",
                      "Corner",
                      ["14", "15", "17", "18"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Corner", ["14", "15", "17", "18"], "9", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.corner14to18?.length ||
                    !!selectedBetsTest?.ltc.corner14to18?.length ||
                    !!selectedBetsTest?.eth.corner14to18?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.corner14to18,
                        ...selectedBetsTest?.ltc.corner14to18,
                        ...selectedBetsTest?.eth.corner14to18,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split17And18",
                      "Split",
                      ["17", "18"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["17", "18"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split17And18?.length ||
                    !!selectedBetsTest?.ltc.split17And18?.length ||
                    !!selectedBetsTest?.eth.split17And18?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split17And18,
                        ...selectedBetsTest?.ltc.split17And18,
                        ...selectedBetsTest?.eth.split17And18,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "corner17to21",
                      "Corner",
                      ["17", "18", "20", "21"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Corner", ["17", "18", "20", "21"], "9", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.corner17to21?.length ||
                    !!selectedBetsTest?.ltc.corner17to21?.length ||
                    !!selectedBetsTest?.eth.corner17to21?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.corner17to21,
                        ...selectedBetsTest?.ltc.corner17to21,
                        ...selectedBetsTest?.eth.corner17to21,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split20And21",
                      "Split",
                      ["20", "21"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["20", "21"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split20And21?.length ||
                    !!selectedBetsTest?.ltc.split20And21?.length ||
                    !!selectedBetsTest?.eth.split20And21?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split20And21,
                        ...selectedBetsTest?.ltc.split20And21,
                        ...selectedBetsTest?.eth.split20And21,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "corner20to24",
                      "Corner",
                      ["20", "21", "23", "24"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Corner", ["20", "21", "23", "24"], "9", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.corner20to24?.length ||
                    !!selectedBetsTest?.ltc.corner20to24?.length ||
                    !!selectedBetsTest?.eth.corner20to24?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.corner20to24,
                        ...selectedBetsTest?.ltc.corner20to24,
                        ...selectedBetsTest?.eth.corner20to24,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split23And24",
                      "Split",
                      ["23", "24"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["23", "24"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split23And24?.length ||
                    !!selectedBetsTest?.ltc.split23And24?.length ||
                    !!selectedBetsTest?.eth.split23And24?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split23And24,
                        ...selectedBetsTest?.ltc.split23And24,
                        ...selectedBetsTest?.eth.split23And24,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "corner23to27",
                      "Corner",
                      ["23", "24", "26", "27"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Corner", ["23", "24", "26", "27"], "9", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.corner23to27?.length ||
                    !!selectedBetsTest?.ltc.corner23to27?.length ||
                    !!selectedBetsTest?.eth.corner23to27?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.corner23to27,
                        ...selectedBetsTest?.ltc.corner23to27,
                        ...selectedBetsTest?.eth.corner23to27,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split26And27",
                      "Split",
                      ["26", "27"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["26", "27"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split26And27?.length ||
                    !!selectedBetsTest?.ltc.split26And27?.length ||
                    !!selectedBetsTest?.eth.split26And27?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split26And27,
                        ...selectedBetsTest?.ltc.split26And27,
                        ...selectedBetsTest?.eth.split26And27,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "corner26to30",
                      "Corner",
                      ["26", "27", "29", "30"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Corner", ["26", "27", "29", "30"], "9", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.corner26to30?.length ||
                    !!selectedBetsTest?.ltc.corner26to30?.length ||
                    !!selectedBetsTest?.eth.corner26to30?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.corner26to30,
                        ...selectedBetsTest?.ltc.corner26to30,
                        ...selectedBetsTest?.eth.corner26to30,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split29And30",
                      "Split",
                      ["29", "30"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["29", "30"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split29And30?.length ||
                    !!selectedBetsTest?.ltc.split29And30?.length ||
                    !!selectedBetsTest?.eth.split29And30?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split29And30,
                        ...selectedBetsTest?.ltc.split29And30,
                        ...selectedBetsTest?.eth.split29And30,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "corner29to33",
                      "Corner",
                      ["29", "30", "32", "33"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Corner", ["29", "30", "32", "33"], "9", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.corner29to33?.length ||
                    !!selectedBetsTest?.ltc.corner29to33?.length ||
                    !!selectedBetsTest?.eth.corner29to33?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.corner29to33,
                        ...selectedBetsTest?.ltc.corner29to33,
                        ...selectedBetsTest?.eth.corner29to33,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split32And33",
                      "Split",
                      ["33", "32"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["33", "32"], "18")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split32And33?.length ||
                    !!selectedBetsTest?.ltc.split32And33?.length ||
                    !!selectedBetsTest?.eth.split32And33?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split32And33,
                        ...selectedBetsTest?.ltc.split32And33,
                        ...selectedBetsTest?.eth.split32And33,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "corner32to36",
                      "Corner",
                      ["32", "33", "35", "36"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Corner", ["32", "33", "35", "36"], "9", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.corner32to36?.length ||
                    !!selectedBetsTest?.ltc.corner32to36?.length ||
                    !!selectedBetsTest?.eth.corner32to36?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.corner32to36,
                        ...selectedBetsTest?.ltc.corner32to36,
                        ...selectedBetsTest?.eth.corner32to36,
                      ]}
                    />
                  ) : (
                    ""
                  )}{" "}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split35And36",
                      "Split",
                      ["36", "35"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["36", "35"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split35And36?.length ||
                    !!selectedBetsTest?.ltc.split35And36?.length ||
                    !!selectedBetsTest?.eth.split35And36?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split35And36,
                        ...selectedBetsTest?.ltc.split35And36,
                        ...selectedBetsTest?.eth.split35And36,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td className="selected-area" />
                <td className="selected-area" />
              </tr>
              <tr>
                <td
                  className={`black ${toggleClass("2")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight2",
                      "Straight",
                      ["2"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["2"], "36", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight2?.length ||
                    !!selectedBetsTest?.ltc.straight2?.length ||
                    !!selectedBetsTest?.eth.straight2?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight2,
                        ...selectedBetsTest?.ltc.straight2,
                        ...selectedBetsTest?.eth.straight2,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  2{" "}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split2And5",
                      "Split",
                      ["2", "5"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["2", "5"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split2And5?.length ||
                    !!selectedBetsTest?.ltc.split2And5?.length ||
                    !!selectedBetsTest?.eth.split2And5?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split2And5,
                        ...selectedBetsTest?.ltc.split2And5,
                        ...selectedBetsTest?.eth.split2And5,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`red ${toggleClass("5")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight5",
                      "Straight",
                      ["5"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["5"], "36", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight5?.length ||
                    !!selectedBetsTest?.ltc.straight5?.length ||
                    !!selectedBetsTest?.eth.straight5?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight5,
                        ...selectedBetsTest?.ltc.straight5,
                        ...selectedBetsTest?.eth.straight5,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  5
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split5And8",
                      "Split",
                      ["5", "8"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["5", "8"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split5And8?.length ||
                    !!selectedBetsTest?.ltc.split5And8?.length ||
                    !!selectedBetsTest?.eth.split5And8?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split5And8,
                        ...selectedBetsTest?.ltc.split5And8,
                        ...selectedBetsTest?.eth.split5And8,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`black ${toggleClass("8")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight8",
                      "Straight",
                      ["8"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["8"], "36", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight8?.length ||
                    !!selectedBetsTest?.ltc.straight8?.length ||
                    !!selectedBetsTest?.eth.straight8?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight8,
                        ...selectedBetsTest?.ltc.straight8,
                        ...selectedBetsTest?.eth.straight8,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  8
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split8And11",
                      "Split",
                      ["8", "11"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["8", "11"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split8And11?.length ||
                    !!selectedBetsTest?.ltc.split8And11?.length ||
                    !!selectedBetsTest?.eth.split8And11?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split8And11,
                        ...selectedBetsTest?.ltc.split8And11,
                        ...selectedBetsTest?.eth.split8And11,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`black ${toggleClass("11")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight11",
                      "Straight",
                      ["11"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["11"], "36", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight11?.length ||
                    !!selectedBetsTest?.ltc.straight11?.length ||
                    !!selectedBetsTest?.eth.straight11?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight11,
                        ...selectedBetsTest?.ltc.straight11,
                        ...selectedBetsTest?.eth.straight11,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  11
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split11And14",
                      "Split",
                      ["11", "14"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["11", "14"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split11And14?.length ||
                    !!selectedBetsTest?.ltc.split11And14?.length ||
                    !!selectedBetsTest?.eth.split11And14?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split11And14,
                        ...selectedBetsTest?.ltc.split11And14,
                        ...selectedBetsTest?.eth.split11And14,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`red ${toggleClass("14")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight14",
                      "Straight",
                      ["14"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["14"], "36", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight14?.length ||
                    !!selectedBetsTest?.ltc.straight14?.length ||
                    !!selectedBetsTest?.eth.straight14?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight14,
                        ...selectedBetsTest?.ltc.straight14,
                        ...selectedBetsTest?.eth.straight14,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  14
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split14And17",
                      "Split",

                      ["14", "17"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["14", "17"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split14And17?.length ||
                    !!selectedBetsTest?.ltc.split14And17?.length ||
                    !!selectedBetsTest?.eth.split14And17?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split14And17,
                        ...selectedBetsTest?.ltc.split14And17,
                        ...selectedBetsTest?.eth.split14And17,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`black ${toggleClass("17")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight17",
                      "Straight",
                      ["17"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["17"], "36", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight17?.length ||
                    !!selectedBetsTest?.ltc.straight17?.length ||
                    !!selectedBetsTest?.eth.straight17?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight17,
                        ...selectedBetsTest?.ltc.straight17,
                        ...selectedBetsTest?.eth.straight17,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  17
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split17And20",
                      "Split",
                      ["17", "20"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["17", "20"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split17And20?.length ||
                    !!selectedBetsTest?.ltc.split17And20?.length ||
                    !!selectedBetsTest?.eth.split17And20?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split17And20,
                        ...selectedBetsTest?.ltc.split17And20,
                        ...selectedBetsTest?.eth.split17And20,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`black ${toggleClass("20")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight20",
                      "Straight",
                      ["20"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["20"], "36", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight20?.length ||
                    !!selectedBetsTest?.ltc.straight20?.length ||
                    !!selectedBetsTest?.eth.straight20?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight20,
                        ...selectedBetsTest?.ltc.straight20,
                        ...selectedBetsTest?.eth.straight20,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  20
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split20And23",
                      "Split",
                      ["20", "23"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["20", "23"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split20And23?.length ||
                    !!selectedBetsTest?.ltc.split20And23?.length ||
                    !!selectedBetsTest?.eth.split20And23?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split20And23,
                        ...selectedBetsTest?.ltc.split20And23,
                        ...selectedBetsTest?.eth.split20And23,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`red ${toggleClass("23")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight23",
                      "Straight",
                      ["23"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["23"], "36", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight23?.length ||
                    !!selectedBetsTest?.ltc.straight23?.length ||
                    !!selectedBetsTest?.eth.straight23?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight23,
                        ...selectedBetsTest?.ltc.straight23,
                        ...selectedBetsTest?.eth.straight23,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  23
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split23And26",
                      "Split",
                      ["23", "26"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["23", "26"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split23And26?.length ||
                    !!selectedBetsTest?.ltc.split23And26?.length ||
                    !!selectedBetsTest?.eth.split23And26?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split23And26,
                        ...selectedBetsTest?.ltc.split23And26,
                        ...selectedBetsTest?.eth.split23And26,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`black ${toggleClass("26")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight26",
                      "Straight",
                      ["26"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["26"], "26", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight26?.length ||
                    !!selectedBetsTest?.ltc.straight26?.length ||
                    !!selectedBetsTest?.eth.straight26?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight26,
                        ...selectedBetsTest?.ltc.straight26,
                        ...selectedBetsTest?.eth.straight26,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  26
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split26And29",
                      "Split",
                      ["26", "29"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["26", "29"], "18")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split26And29?.length ||
                    !!selectedBetsTest?.ltc.split26And29?.length ||
                    !!selectedBetsTest?.eth.split26And29?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split26And29,
                        ...selectedBetsTest?.ltc.split26And29,
                        ...selectedBetsTest?.eth.split26And29,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`black ${toggleClass("29")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight29",
                      "Straight",
                      ["29"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() => handleMouseOver("Straight", ["29"], "36")}
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight29?.length ||
                    !!selectedBetsTest?.ltc.straight29?.length ||
                    !!selectedBetsTest?.eth.straight29?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight29,
                        ...selectedBetsTest?.ltc.straight29,
                        ...selectedBetsTest?.eth.straight29,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  29
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split29And32",
                      "Split",
                      ["29", "32"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["29", "32"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split29And32?.length ||
                    !!selectedBetsTest?.ltc.split29And32?.length ||
                    !!selectedBetsTest?.eth.split29And32?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split29And32,
                        ...selectedBetsTest?.ltc.split29And32,
                        ...selectedBetsTest?.eth.split29And32,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`red ${toggleClass("32")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight32",
                      "Straight",
                      ["32"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["32"], "36", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight32?.length ||
                    !!selectedBetsTest?.ltc.straight32?.length ||
                    !!selectedBetsTest?.eth.straight32?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight32,
                        ...selectedBetsTest?.ltc.straight32,
                        ...selectedBetsTest?.eth.straight32,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  32
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split32And35",
                      "Split",
                      ["32", "35"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["32", "35"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split32And35?.length ||
                    !!selectedBetsTest?.ltc.split32And35?.length ||
                    !!selectedBetsTest?.eth.split32And35?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split32And35,
                        ...selectedBetsTest?.ltc.split32And35,
                        ...selectedBetsTest?.eth.split32And35,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`black ${toggleClass("35")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight35",
                      "Straight",
                      ["35"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["35"], "36", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight35?.length ||
                    !!selectedBetsTest?.ltc.straight35?.length ||
                    !!selectedBetsTest?.eth.straight35?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight35,
                        ...selectedBetsTest?.ltc.straight35,
                        ...selectedBetsTest?.eth.straight35,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  35
                </td>
                <td className="selected-area" />
                <td
                  className={`black ${
                    hover.specialValueType === "Middle" ? "hover-class" : ""
                  }`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "column2nd",
                      "2nd Column",
                      [
                        "2",
                        "5",
                        "8",
                        "11",
                        "14",
                        "17",
                        "20",
                        "23",
                        "26",
                        "29",
                        "32",
                        "35",
                      ],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver(
                      "Column",

                      [
                        "2",
                        "5",
                        "8",
                        "11",
                        "14",
                        "17",
                        "20",
                        "23",
                        "26",
                        "29",
                        "32",
                        "35",
                      ],
                      "3",
                      "Middle"
                    )
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.column2nd?.length ||
                    !!selectedBetsTest?.ltc.column2nd?.length ||
                    !!selectedBetsTest?.eth.column2nd?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.column2nd,
                        ...selectedBetsTest?.ltc.column2nd,
                        ...selectedBetsTest?.eth.column2nd,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  2 to 1
                </td>
              </tr>
              <tr>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split1And2",
                      "Split",
                      ["1", "2"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["1", "2"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split1And2?.length ||
                    !!selectedBetsTest?.ltc.split1And2?.length ||
                    !!selectedBetsTest?.eth.split1And2?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split1And2,
                        ...selectedBetsTest?.ltc.split1And2,
                        ...selectedBetsTest?.eth.split1And2,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "corner1to5",
                      "Corner",
                      ["1", "2", "4", "5"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Corner", ["1", "2", "4", "5"], "9", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.corner1to5?.length ||
                    !!selectedBetsTest?.ltc.corner1to5?.length ||
                    !!selectedBetsTest?.eth.corner1to5?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.corner1to5,
                        ...selectedBetsTest?.ltc.corner1to5,
                        ...selectedBetsTest?.eth.corner1to5,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split4And5",
                      "Split",
                      ["4", "5"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["4", "5"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split4And5?.length ||
                    !!selectedBetsTest?.ltc.split4And5?.length ||
                    !!selectedBetsTest?.eth.split4And5?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split4And5,
                        ...selectedBetsTest?.ltc.split4And5,
                        ...selectedBetsTest?.eth.split4And5,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "corner4to8",
                      "Corner",
                      ["4", "5", "7", "8"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Corner", ["4", "5", "7", "8"], "9")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.corner4to8?.length ||
                    !!selectedBetsTest?.ltc.corner4to8?.length ||
                    !!selectedBetsTest?.eth.corner4to8?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.corner4to8,
                        ...selectedBetsTest?.ltc.corner4to8,
                        ...selectedBetsTest?.eth.corner4to8,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split7And8",
                      "Split",
                      ["7", "8"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() => handleMouseOver("Split", ["7", "8"], "18")}
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split7And8?.length ||
                    !!selectedBetsTest?.ltc.split7And8?.length ||
                    !!selectedBetsTest?.eth.split7And8?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split7And8,
                        ...selectedBetsTest?.ltc.split7And8,
                        ...selectedBetsTest?.eth.split7And8,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "corner7to11",
                      "Corner",
                      ["7", "8", "10", "11"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Corner", ["7", "8", "10", "11"], "9", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.corner7to11?.length ||
                    !!selectedBetsTest?.ltc.corner7to11?.length ||
                    !!selectedBetsTest?.eth.corner7to11?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.corner7to11,
                        ...selectedBetsTest?.ltc.corner7to11,
                        ...selectedBetsTest?.eth.corner7to11,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split10And11",
                      "Split",
                      ["10", "11"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["10", "11"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split10And11?.length ||
                    !!selectedBetsTest?.ltc.split10And11?.length ||
                    !!selectedBetsTest?.eth.split10And11?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split10And11,
                        ...selectedBetsTest?.ltc.split10And11,
                        ...selectedBetsTest?.eth.split10And11,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "corner10to14",
                      "Corner",
                      ["10", "11", "13", "14"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Corner", ["10", "11", "13", "14"], "9", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.corner10to14?.length ||
                    !!selectedBetsTest?.ltc.corner10to14?.length ||
                    !!selectedBetsTest?.eth.corner10to14?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.corner10to14,
                        ...selectedBetsTest?.ltc.corner10to14,
                        ...selectedBetsTest?.eth.corner10to14,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split13And14",
                      "Split",
                      ["13", "14"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["13", "14"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split13And14?.length ||
                    !!selectedBetsTest?.ltc.split13And14?.length ||
                    !!selectedBetsTest?.eth.split13And14?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split13And14,
                        ...selectedBetsTest?.ltc.split13And14,
                        ...selectedBetsTest?.eth.split13And14,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "corner13to17",
                      "Corner",
                      ["13", "14", "16", "17"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Corner", ["13", "14", "16", "17"], "9", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.corner13to17?.length ||
                    !!selectedBetsTest?.ltc.corner13to17?.length ||
                    !!selectedBetsTest?.eth.corner13to17?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.corner13to17,
                        ...selectedBetsTest?.ltc.corner13to17,
                        ...selectedBetsTest?.eth.corner13to17,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split16And17",
                      "Split",
                      ["16", "17"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["16", "17"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split16And17?.length ||
                    !!selectedBetsTest?.ltc.split16And17?.length ||
                    !!selectedBetsTest?.eth.split16And17?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split16And17,
                        ...selectedBetsTest?.ltc.split16And17,
                        ...selectedBetsTest?.eth.split16And17,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "corner16to20",
                      "Corner",
                      ["16", "17", "19", "20"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Corner", ["16", "17", "19", "20"], "9", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.corner16to20?.length ||
                    !!selectedBetsTest?.ltc.corner16to20?.length ||
                    !!selectedBetsTest?.eth.corner16to20?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.corner16to20,
                        ...selectedBetsTest?.ltc.corner16to20,
                        ...selectedBetsTest?.eth.corner16to20,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split19And20",
                      "Split",
                      ["19", "20"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["19", "20"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split19And20?.length ||
                    !!selectedBetsTest?.ltc.split19And20?.length ||
                    !!selectedBetsTest?.eth.split19And20?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split19And20,
                        ...selectedBetsTest?.ltc.split19And20,
                        ...selectedBetsTest?.eth.split19And20,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "corner19to23",
                      "Corner",
                      ["19", "20", "22", "23"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Corner", ["19", "20", "22", "23"], "9", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.corner19to23?.length ||
                    !!selectedBetsTest?.ltc.corner19to23?.length ||
                    !!selectedBetsTest?.eth.corner19to23?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.corner19to23,
                        ...selectedBetsTest?.ltc.corner19to23,
                        ...selectedBetsTest?.eth.corner19to23,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split22And23",
                      "Split",
                      ["22", "23"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["22", "23"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split22And23?.length ||
                    !!selectedBetsTest?.ltc.split22And23?.length ||
                    !!selectedBetsTest?.eth.split22And23?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split22And23,
                        ...selectedBetsTest?.ltc.split22And23,
                        ...selectedBetsTest?.eth.split22And23,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "corner22to26",
                      "Corner",
                      ["22", "23", "25", "26"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Corner", ["22", "23", "25", "26"], "9", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.corner22to26?.length ||
                    !!selectedBetsTest?.ltc.corner22to26?.length ||
                    !!selectedBetsTest?.eth.corner22to26?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.corner22to26,
                        ...selectedBetsTest?.ltc.corner22to26,
                        ...selectedBetsTest?.eth.corner22to26,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split25And26",
                      "Split",
                      ["25", "26"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["25", "26"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split25And26?.length ||
                    !!selectedBetsTest?.ltc.split25And26?.length ||
                    !!selectedBetsTest?.eth.split25And26?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split25And26,
                        ...selectedBetsTest?.ltc.split25And26,
                        ...selectedBetsTest?.eth.split25And26,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "corner25to29",
                      "Corner",
                      ["25", "26", "28", "29"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Corner", ["25", "26", "28", "29"], "9", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.corner25to29?.length ||
                    !!selectedBetsTest?.ltc.corner25to29?.length ||
                    !!selectedBetsTest?.eth.corner25to29?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.corner25to29,
                        ...selectedBetsTest?.ltc.corner25to29,
                        ...selectedBetsTest?.eth.corner25to29,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split28And29",
                      "Split",
                      ["28", "29"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["28", "29"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split28And29?.length ||
                    !!selectedBetsTest?.ltc.split28And29?.length ||
                    !!selectedBetsTest?.eth.split28And29?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split28And29,
                        ...selectedBetsTest?.ltc.split28And29,
                        ...selectedBetsTest?.eth.split28And29,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "corner28to32",
                      "Corner",
                      ["28", "29", "31", "32"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Corner", ["28", "29", "31", "32"], "9", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.corner28to32?.length ||
                    !!selectedBetsTest?.ltc.corner28to32?.length ||
                    !!selectedBetsTest?.eth.corner28to32?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.corner28to32,
                        ...selectedBetsTest?.ltc.corner28to32,
                        ...selectedBetsTest?.eth.corner28to32,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split31And32",
                      "Split",
                      ["31", "32"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["31", "32"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split31And32?.length ||
                    !!selectedBetsTest?.ltc.split31And32?.length ||
                    !!selectedBetsTest?.eth.split31And32?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split31And32,
                        ...selectedBetsTest?.ltc.split31And32,
                        ...selectedBetsTest?.eth.split31And32,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "corner31to35",
                      "Corner",
                      ["31", "32", "34", "35"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Corner", ["31", "32", "34", "35"], "9", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.corner31to35?.length ||
                    !!selectedBetsTest?.ltc.corner31to35?.length ||
                    !!selectedBetsTest?.eth.corner31to35?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.corner31to35,
                        ...selectedBetsTest?.ltc.corner31to35,
                        ...selectedBetsTest?.eth.corner31to35,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split34And35",
                      "Split",
                      ["34", "35"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["34", "35"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {" "}
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split34And35?.length ||
                    !!selectedBetsTest?.ltc.split34And35?.length ||
                    !!selectedBetsTest?.eth.split34And35?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split34And35,
                        ...selectedBetsTest?.ltc.split34And35,
                        ...selectedBetsTest?.eth.split34And35,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td className="selected-area" />
                <td className="selected-area" />
              </tr>
              <tr>
                <td
                  className={`red ${toggleClass("1")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight1",
                      "Straight",
                      ["1"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["1"], "36", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  1
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight1?.length ||
                    !!selectedBetsTest?.ltc.straight1?.length ||
                    !!selectedBetsTest?.eth.straight1?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight1,
                        ...selectedBetsTest?.ltc.straight1,
                        ...selectedBetsTest?.eth.straight1,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split1And4",
                      "Split",
                      [("1", "4")],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["1", "4"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split1And4?.length ||
                    !!selectedBetsTest?.ltc.split1And4?.length ||
                    !!selectedBetsTest?.eth.split1And4?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split1And4,
                        ...selectedBetsTest?.ltc.split1And4,
                        ...selectedBetsTest?.eth.split1And4,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`black ${toggleClass("4")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight4",
                      "Straight",
                      ["4"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["4"], "36", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight4?.length ||
                    !!selectedBetsTest?.ltc.straight4?.length ||
                    !!selectedBetsTest?.eth.straight4?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight4,
                        ...selectedBetsTest?.ltc.straight4,
                        ...selectedBetsTest?.eth.straight4,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  4
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split4And7",
                      "Split",
                      ["4", "7"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["4", "7"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split4And7?.length ||
                    !!selectedBetsTest?.ltc.split4And7?.length ||
                    !!selectedBetsTest?.eth.split4And7?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split4And7,
                        ...selectedBetsTest?.ltc.split4And7,
                        ...selectedBetsTest?.eth.split4And7,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`red ${toggleClass("7")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight7",
                      "Straight",
                      ["7"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["7"], "36", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight7?.length ||
                    !!selectedBetsTest?.ltc.straight7?.length ||
                    !!selectedBetsTest?.eth.straight7?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight7,
                        ...selectedBetsTest?.ltc.straight7,
                        ...selectedBetsTest?.eth.straight7,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  7
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split7And10",
                      "Split",
                      ["7", "10"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["7", "10"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split7And10?.length ||
                    !!selectedBetsTest?.ltc.split7And10?.length ||
                    !!selectedBetsTest?.eth.split7And10?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split7And10,
                        ...selectedBetsTest?.ltc.split7And10,
                        ...selectedBetsTest?.eth.split7And10,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`black ${toggleClass("10")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight10",
                      "Straight",
                      ["10"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["10"], "36", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight10?.length ||
                    !!selectedBetsTest?.ltc.straight10?.length ||
                    !!selectedBetsTest?.eth.straight10?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight10,
                        ...selectedBetsTest?.ltc.straight10,
                        ...selectedBetsTest?.eth.straight10,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  10
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split10And13",
                      "Split",
                      ["10", "13"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["10", "13"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split10And13?.length ||
                    !!selectedBetsTest?.ltc.split10And13?.length ||
                    !!selectedBetsTest?.eth.split10And13?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split10And13,
                        ...selectedBetsTest?.ltc.split10And13,
                        ...selectedBetsTest?.eth.split10And13,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`black ${toggleClass("13")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight13",
                      "Straight",
                      ["13"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["13"], "36", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight13?.length ||
                    !!selectedBetsTest?.ltc.straight13?.length ||
                    !!selectedBetsTest?.eth.straight13?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight13,
                        ...selectedBetsTest?.ltc.straight13,
                        ...selectedBetsTest?.eth.straight13,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  13
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split13And16",
                      "Split",
                      ["13", "16"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["13", "16"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split13And16?.length ||
                    !!selectedBetsTest?.ltc.split13And16?.length ||
                    !!selectedBetsTest?.eth.split13And16?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split13And16,
                        ...selectedBetsTest?.ltc.split13And16,
                        ...selectedBetsTest?.eth.split13And16,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`red ${toggleClass("16")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight16",
                      "Straight",
                      ["16"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["16"], "36", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight16?.length ||
                    !!selectedBetsTest?.ltc.straight16?.length ||
                    !!selectedBetsTest?.eth.straight16?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight16,
                        ...selectedBetsTest?.ltc.straight16,
                        ...selectedBetsTest?.eth.straight16,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  16
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split16And19",
                      "Split",
                      ["16", "19"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["16", "19"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split16And19?.length ||
                    !!selectedBetsTest?.ltc.split16And19?.length ||
                    !!selectedBetsTest?.eth.split16And19?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split16And19,
                        ...selectedBetsTest?.ltc.split16And19,
                        ...selectedBetsTest?.eth.split16And19,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`red ${toggleClass("19")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight19",
                      "Straight",
                      ["19"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["19"], "36", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight19?.length ||
                    !!selectedBetsTest?.ltc.straight19?.length ||
                    !!selectedBetsTest?.eth.straight19?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight19,
                        ...selectedBetsTest?.ltc.straight19,
                        ...selectedBetsTest?.eth.straight19,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  19
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split19And22",
                      "Split",
                      ["19", "22"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["19", "22"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split19And22?.length ||
                    !!selectedBetsTest?.ltc.split19And22?.length ||
                    !!selectedBetsTest?.eth.split19And22?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split19And22,
                        ...selectedBetsTest?.ltc.split19And22,
                        ...selectedBetsTest?.eth.split19And22,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`black ${toggleClass("22")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight22",
                      "Straight",
                      ["22"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["22"], "36", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight22?.length ||
                    !!selectedBetsTest?.ltc.straight22?.length ||
                    !!selectedBetsTest?.eth.straight22?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight22,
                        ...selectedBetsTest?.ltc.straight22,
                        ...selectedBetsTest?.eth.straight22,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  22
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split22And25",
                      "Split",
                      ["22", "25"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["22", "25"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split22And25?.length ||
                    !!selectedBetsTest?.ltc.split22And25?.length ||
                    !!selectedBetsTest?.eth.split22And25?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split22And25,
                        ...selectedBetsTest?.ltc.split22And25,
                        ...selectedBetsTest?.eth.split22And25,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`red ${toggleClass("25")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight25",
                      "Straight",
                      ["25"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["25"], "36", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight25?.length ||
                    !!selectedBetsTest?.ltc.straight25?.length ||
                    !!selectedBetsTest?.eth.straight25?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight25,
                        ...selectedBetsTest?.ltc.straight25,
                        ...selectedBetsTest?.eth.straight25,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  25
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split25And28",
                      "Split",
                      ["25", "28"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["25", "28"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split25And28?.length ||
                    !!selectedBetsTest?.ltc.split25And28?.length ||
                    !!selectedBetsTest?.eth.split25And28?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split25And28,
                        ...selectedBetsTest?.ltc.split25And28,
                        ...selectedBetsTest?.eth.split25And28,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`black ${toggleClass("28")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight28",
                      "Straight",
                      ["28"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["28"], "36", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight28?.length ||
                    !!selectedBetsTest?.ltc.straight28?.length ||
                    !!selectedBetsTest?.eth.straight28?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight28,
                        ...selectedBetsTest?.ltc.straight28,
                        ...selectedBetsTest?.eth.straight28,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  28
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split28And31",
                      "Split",
                      ["28", "31"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["28", "31"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split28And31?.length ||
                    !!selectedBetsTest?.ltc.split28And31?.length ||
                    !!selectedBetsTest?.eth.split28And31?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split28And31,
                        ...selectedBetsTest?.ltc.split28And31,
                        ...selectedBetsTest?.eth.split28And31,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`black ${toggleClass("31")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight31",
                      "Straight",
                      ["31"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["31"], "36", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight31?.length ||
                    !!selectedBetsTest?.ltc.straight31?.length ||
                    !!selectedBetsTest?.eth.straight31?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight31,
                        ...selectedBetsTest?.ltc.straight31,
                        ...selectedBetsTest?.eth.straight31,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  31
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "split31And34",
                      "Split",
                      ["31", "34"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Split", ["31", "34"], "18", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.split31And34?.length ||
                    !!selectedBetsTest?.ltc.split31And34?.length ||
                    !!selectedBetsTest?.eth.split31And34?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.split31And34,
                        ...selectedBetsTest?.ltc.split31And34,
                        ...selectedBetsTest?.eth.split31And34,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={`red ${toggleClass("34")}`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "straight34",
                      "Straight",
                      ["34"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Straight", ["34"], "36", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.straight34?.length ||
                    !!selectedBetsTest?.ltc.straight34?.length ||
                    !!selectedBetsTest?.eth.straight34?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.straight34,
                        ...selectedBetsTest?.ltc.straight34,
                        ...selectedBetsTest?.eth.straight34,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  34
                </td>
                <td className="selected-area" />
                <td
                  className={`black ${
                    hover.specialValueType === "Left" ? "hover-class" : ""
                  }`}
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "column3rd",
                      "3rd Column",
                      [
                        "1",
                        "4",
                        "7",
                        "10",
                        "13",
                        "16",
                        "19",
                        "22",
                        "25",
                        "28",
                        "31",
                        "34",
                      ],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver(
                      "Column",
                      [
                        "1",
                        "4",
                        "7",
                        "10",
                        "13",
                        "16",
                        "19",
                        "22",
                        "25",
                        "28",
                        "31",
                        "34",
                      ],
                      "3",
                      "Left"
                    )
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.column3rd?.length ||
                    !!selectedBetsTest?.ltc.column3rd?.length ||
                    !!selectedBetsTest?.eth.column3rd?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.column3rd,
                        ...selectedBetsTest?.ltc.column3rd,
                        ...selectedBetsTest?.eth.column3rd,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  2 to 1
                </td>
              </tr>
              <tr>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "street1to3",
                      "Street",
                      ["1", "2", "3"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Street", ["1", "2", "3"], "12", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.street1to3?.length ||
                    !!selectedBetsTest?.ltc.street1to3?.length ||
                    !!selectedBetsTest?.eth.street1to3?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.street1to3,
                        ...selectedBetsTest?.ltc.street1to3,
                        ...selectedBetsTest?.eth.street1to3,
                      ]}
                    />
                  ) : (
                    ""
                  )}{" "}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "sixline1to6",
                      "Sixline",
                      ["1", "2", "3", "4", "5", "6"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver(
                      "Six Line",
                      ["1", "2", "3", "4", "5", "6"],
                      "6",
                      "1 to 6"
                    )
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.sixline1to6?.length ||
                    !!selectedBetsTest?.ltc.sixline1to6?.length ||
                    !!selectedBetsTest?.eth.sixline1to6?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.sixline1to6,
                        ...selectedBetsTest?.ltc.sixline1to6,
                        ...selectedBetsTest?.eth.sixline1to6,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "street4to6",
                      "Street",
                      ["4", "5", "6"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Street", ["4", "5", "6"], "12", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.street4to6?.length ||
                    !!selectedBetsTest?.ltc.street4to6?.length ||
                    !!selectedBetsTest?.eth.street4to6?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.street4to6,
                        ...selectedBetsTest?.ltc.street4to6,
                        ...selectedBetsTest?.eth.street4to6,
                      ]}
                    />
                  ) : (
                    ""
                  )}{" "}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "sixline4to9",
                      "Sixline",
                      ["4", "5", "6", "7", "8", "9"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver(
                      "Six Line",
                      ["4", "5", "6", "7", "8", "9"],
                      "6",
                      "4 to 9"
                    )
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.sixline4to9?.length ||
                    !!selectedBetsTest?.ltc.sixline4to9?.length ||
                    !!selectedBetsTest?.eth.sixline4to9?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.sixline4to9,
                        ...selectedBetsTest?.ltc.sixline4to9,
                        ...selectedBetsTest?.eth.sixline4to9,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "street7to9",
                      "Street",
                      ["7", "8", "9"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Street", ["7", "8", "9"], "12", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.street7to9?.length ||
                    !!selectedBetsTest?.ltc.street7to9?.length ||
                    !!selectedBetsTest?.eth.street7to9?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.street7to9,
                        ...selectedBetsTest?.ltc.street7to9,
                        ...selectedBetsTest?.eth.street7to9,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "sixline7to12",
                      "Sixline",
                      ["7", "8", "9", "10", "11", "12"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver(
                      "Six Line",
                      ["7", "8", "9", "10", "11", "12"],
                      "6",
                      "7 to 12"
                    )
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.sixline7to12?.length ||
                    !!selectedBetsTest?.ltc.sixline7to12?.length ||
                    !!selectedBetsTest?.eth.sixline7to12?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.sixline7to12,
                        ...selectedBetsTest?.ltc.sixline7to12,
                        ...selectedBetsTest?.eth.sixline7to12,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "street10to12",
                      "Street",
                      ["10", "11", "12"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Street", ["10", "11", "12"], "12", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.street10to12?.length ||
                    !!selectedBetsTest?.ltc.street10to12?.length ||
                    !!selectedBetsTest?.eth.street10to12?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.street10to12,
                        ...selectedBetsTest?.ltc.street10to12,
                        ...selectedBetsTest?.eth.street10to12,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "sixline10to15",
                      "Sixline",
                      ["10", "11", "12", "13", "14", "15"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver(
                      "Sixline",
                      ["10", "11", "12", "13", "14", "15"],
                      "6",
                      "10 to 15"
                    )
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.sixline10to15?.length ||
                    !!selectedBetsTest?.ltc.sixline10to15?.length ||
                    !!selectedBetsTest?.eth.sixline10to15?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.sixline10to15,
                        ...selectedBetsTest?.ltc.sixline10to15,
                        ...selectedBetsTest?.eth.sixline10to15,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "street13to15",
                      "Street",
                      ["13", "14", "15"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Street", ["13", "14", "15"], "12", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.street13to15?.length ||
                    !!selectedBetsTest?.ltc.street13to15?.length ||
                    !!selectedBetsTest?.eth.street13to15?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.street13to15,
                        ...selectedBetsTest?.ltc.street13to15,
                        ...selectedBetsTest?.eth.street13to15,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "sixline13to18",
                      "Sixline",
                      ["13", "14", "15", "16", "17", "18"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver(
                      "Six Line",
                      ["13", "14", "15", "16", "17", "18"],
                      "6",
                      "13 to 18"
                    )
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.sixline13to18?.length ||
                    !!selectedBetsTest?.ltc.sixline13to18?.length ||
                    !!selectedBetsTest?.eth.sixline13to18?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.sixline13to18,
                        ...selectedBetsTest?.ltc.sixline13to18,
                        ...selectedBetsTest?.eth.sixline13to18,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "street16to18",
                      "Street",
                      ["16", "17", "18"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Street", ["16", "17", "18"], "12", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.street16to18?.length ||
                    !!selectedBetsTest?.ltc.street16to18?.length ||
                    !!selectedBetsTest?.eth.street16to18?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.street16to18,
                        ...selectedBetsTest?.ltc.street16to18,
                        ...selectedBetsTest?.eth.street16to18,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "sixline16to21",
                      "Sixline",
                      ["16", "17", "18", "19", "20", "21"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver(
                      "Six Line",
                      ["16", "17", "18", "19", "20", "21"],
                      "6",
                      "16 to 21"
                    )
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.sixline16to21?.length ||
                    !!selectedBetsTest?.ltc.sixline16to21?.length ||
                    !!selectedBetsTest?.eth.sixline16to21?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.sixline16to21,
                        ...selectedBetsTest?.ltc.sixline16to21,
                        ...selectedBetsTest?.eth.sixline16to21,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "street19to21",
                      "Street",
                      ["19", "20", "21"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Street", ["19", "20", "21"], "12", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.street19to21?.length ||
                    !!selectedBetsTest?.ltc.street19to21?.length ||
                    !!selectedBetsTest?.eth.street19to21?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.street19to21,
                        ...selectedBetsTest?.ltc.street19to21,
                        ...selectedBetsTest?.eth.street19to21,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "sixline19to24",
                      "Sixline",
                      ["19", "20", "21", "22", "23", "24"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver(
                      "Six Line",
                      ["19", "20", "21", "22", "23", "24"],
                      "6",
                      "19 to 24"
                    )
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.sixline19to24?.length ||
                    !!selectedBetsTest?.ltc.sixline19to24?.length ||
                    !!selectedBetsTest?.eth.sixline19to24?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.sixline19to24,
                        ...selectedBetsTest?.ltc.sixline19to24,
                        ...selectedBetsTest?.eth.sixline19to24,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area "
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "street22to24",
                      "Street",
                      ["22", "23", "24"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Street", ["22", "23", "24"], "12", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.street22to24?.length ||
                    !!selectedBetsTest?.ltc.street22to24?.length ||
                    !!selectedBetsTest?.eth.street22to24?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.street22to24,
                        ...selectedBetsTest?.ltc.street22to24,
                        ...selectedBetsTest?.eth.street22to24,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "sixline22to27",
                      "Sixline",
                      ["22", "23", "24", "25", "26", "27"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver(
                      "Six Line",
                      ["22", "23", "24", "25", "26", "27"],
                      "6",
                      "22 to 27"
                    )
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.sixline22to27?.length ||
                    !!selectedBetsTest?.ltc.sixline22to27?.length ||
                    !!selectedBetsTest?.eth.sixline22to27?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.sixline22to27,
                        ...selectedBetsTest?.ltc.sixline22to27,
                        ...selectedBetsTest?.eth.sixline22to27,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "street25to27",
                      "Street",
                      ["25", "26", "27"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Street", ["25", "26", "27"], "12", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.street25to27?.length ||
                    !!selectedBetsTest?.ltc.street25to27?.length ||
                    !!selectedBetsTest?.eth.street25to27?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.street25to27,
                        ...selectedBetsTest?.ltc.street25to27,
                        ...selectedBetsTest?.eth.street25to27,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "sixline25to30",
                      "Sixline",
                      ["25", "26", "27", "28", "29", "30"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver(
                      "Six Line",
                      ["25", "26", "27", "28", "29", "30"],
                      "6",
                      "25 to 30"
                    )
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.sixline25to30?.length ||
                    !!selectedBetsTest?.ltc.sixline25to30?.length ||
                    !!selectedBetsTest?.eth.sixline25to30?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.sixline25to30,
                        ...selectedBetsTest?.ltc.sixline25to30,
                        ...selectedBetsTest?.eth.sixline25to30,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "street28to30",
                      "Street",
                      ["28", "29", "30"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Street", ["28", "29", "30"], "12", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.street28to30?.length ||
                    !!selectedBetsTest?.ltc.street28to30?.length ||
                    !!selectedBetsTest?.eth.street28to30?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.street28to30,
                        ...selectedBetsTest?.ltc.street28to30,
                        ...selectedBetsTest?.eth.street28to30,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "sixline28to33",
                      "Sixline",
                      ["28", "29", "30", "31", "32", "33"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver(
                      "Six Line",
                      ["28", "29", "30", "31", "32", "33"],
                      "6",
                      "28 to 33"
                    )
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.sixline28to33?.length ||
                    !!selectedBetsTest?.ltc.sixline28to33?.length ||
                    !!selectedBetsTest?.eth.sixline28to33?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.sixline28to33,
                        ...selectedBetsTest?.ltc.sixline28to33,
                        ...selectedBetsTest?.eth.sixline28to33,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "street31to33",
                      "Street",
                      ["31", "32", "33"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Street", ["31", "32", "33"], "12", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.street31to33?.length ||
                    !!selectedBetsTest?.ltc.street31to33?.length ||
                    !!selectedBetsTest?.eth.street31to33?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.street31to33,
                        ...selectedBetsTest?.ltc.street31to33,
                        ...selectedBetsTest?.eth.street31to33,
                      ]}
                    />
                  ) : (
                    ""
                  )}{" "}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "sixline31to36",
                      "Sixline",
                      ["31", "32", "33", "34", "35", "36"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver(
                      "Six Line",
                      ["31", "32", "33", "34", "35", "36"],
                      "6",
                      "31 to 36"
                    )
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.sixline31to36?.length ||
                    !!selectedBetsTest?.ltc.sixline31to36?.length ||
                    !!selectedBetsTest?.eth.sixline31to36?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.sixline31to36,
                        ...selectedBetsTest?.ltc.sixline31to36,
                        ...selectedBetsTest?.eth.sixline31to36,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className="selected-area"
                  onClick={() =>
                    addBet(
                      selectedBlockchain,
                      betAmount,
                      "street34to36",
                      "Street",
                      ["34", "35", "36"],
                      bettingTimer.timer
                    )
                  }
                  onMouseOver={() =>
                    handleMouseOver("Street", ["34", "35", "36"], "12", "")
                  }
                  onMouseOut={() => handleMouseOut()}
                >
                  {selectedBetsTest?.totalBet > 0 &&
                  (!!selectedBetsTest?.btc.street34to36?.length ||
                    !!selectedBetsTest?.ltc.street34to36?.length ||
                    !!selectedBetsTest?.eth.street34to36?.length) ? (
                    <ShowChipsMemo
                      selectedBets={[
                        ...selectedBetsTest?.btc.street34to36,
                        ...selectedBetsTest?.ltc.street34to36,
                        ...selectedBetsTest?.eth.street34to36,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td className="selected-area" />
                <td className="selected-area" />
              </tr>
            </tbody>
          </table>
          <div role="grid" className="grid-bet-table">
            <div
              className={`row-selection ${
                hover.specialValueType === "1 to 12" ? "hover-class" : ""
              }`}
              onClick={() =>
                addBet(
                  selectedBlockchain,
                  betAmount,
                  "dozen1st12",
                  "Dozen 1st 12",
                  [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "11",
                    "12",
                  ],
                  bettingTimer.timer
                )
              }
              onMouseOver={() =>
                handleMouseOver(
                  "Dozen",
                  [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "11",
                    "12",
                  ],
                  "3",
                  "1 to 12"
                )
              }
              onMouseOut={() => handleMouseOut()}
            >
              {selectedBetsTest?.totalBet > 0 &&
              (!!selectedBetsTest?.btc.dozen1st12?.length ||
                !!selectedBetsTest?.ltc.dozen1st12?.length ||
                !!selectedBetsTest?.eth.dozen1st12?.length) ? (
                <ShowChipsMemo
                  selectedBets={[
                    ...selectedBetsTest?.btc.dozen1st12,
                    ...selectedBetsTest?.ltc.dozen1st12,
                    ...selectedBetsTest?.eth.dozen1st12,
                  ]}
                />
              ) : (
                ""
              )}
              1st 12
            </div>
            <div
              className={`row-selection ${
                hover.specialValueType === "13 to 24" ? "hover-class" : ""
              }`}
              onClick={() =>
                addBet(
                  selectedBlockchain,
                  betAmount,
                  "dozen2nd12",
                  "Dozen 2nd 12",
                  [
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                    "19",
                    "20",
                    "21",
                    "22",
                    "23",
                    "24",
                  ],
                  bettingTimer.timer
                )
              }
              onMouseOver={() =>
                handleMouseOver(
                  "Dozen",
                  [
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                    "19",
                    "20",
                    "21",
                    "22",
                    "23",
                    "24",
                  ],
                  "3",
                  "13 to 24"
                )
              }
              onMouseOut={() => handleMouseOut()}
            >
              {selectedBetsTest?.totalBet > 0 &&
              (!!selectedBetsTest?.btc.dozen2nd12?.length ||
                !!selectedBetsTest?.ltc.dozen2nd12?.length ||
                !!selectedBetsTest?.eth.dozen2nd12?.length) ? (
                <ShowChipsMemo
                  selectedBets={[
                    ...selectedBetsTest?.btc.dozen2nd12,
                    ...selectedBetsTest?.ltc.dozen2nd12,
                    ...selectedBetsTest?.eth.dozen2nd12,
                  ]}
                />
              ) : (
                ""
              )}
              2nd 12
            </div>
            <div
              className={`row-selection ${
                hover.specialValueType === "25 to 36" ? "hover-class" : ""
              }`}
              onClick={() =>
                addBet(
                  selectedBlockchain,
                  betAmount,
                  "dozen3rd12",
                  "Dozen 3rd 12",
                  [
                    "25",
                    "26",
                    "27",
                    "28",
                    "29",
                    "30",
                    "31",
                    "32",
                    "33",
                    "34",
                    "35",
                    "36",
                  ],
                  bettingTimer.timer
                )
              }
              onMouseOver={() =>
                handleMouseOver(
                  "Dozen",
                  [
                    "25",
                    "26",
                    "27",
                    "28",
                    "29",
                    "30",
                    "31",
                    "32",
                    "33",
                    "34",
                    "35",
                    "36",
                  ],
                  "3",
                  "25 to 36"
                )
              }
              onMouseOut={() => handleMouseOut()}
            >
              3rd 12
              {selectedBetsTest?.totalBet > 0 &&
              (!!selectedBetsTest?.btc.dozen3rd12?.length ||
                !!selectedBetsTest?.ltc.dozen3rd12?.length ||
                !!selectedBetsTest?.eth.dozen3rd12?.length) ? (
                <ShowChipsMemo
                  selectedBets={[
                    ...selectedBetsTest?.btc.dozen3rd12,
                    ...selectedBetsTest?.ltc.dozen3rd12,
                    ...selectedBetsTest?.eth.dozen3rd12,
                  ]}
                />
              ) : (
                ""
              )}
            </div>
            <div
              className={`${hover.type === "Low" ? "hover-class" : ""}`}
              onClick={() =>
                addBet(
                  selectedBlockchain,

                  betAmount,
                  "low1to18",
                  "Low",
                  [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                  ],
                  bettingTimer.timer
                )
              }
              onMouseOver={() =>
                handleMouseOver(
                  "Low",
                  [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                  ],
                  "2",
                  "1 to 18"
                )
              }
              onMouseOut={() => handleMouseOut()}
            >
              {selectedBetsTest?.totalBet > 0 &&
              (!!selectedBetsTest?.btc.low1to18?.length ||
                !!selectedBetsTest?.ltc.low1to18?.length ||
                !!selectedBetsTest?.eth.low1to18?.length) ? (
                <ShowChipsMemo
                  selectedBets={[
                    ...selectedBetsTest?.btc.low1to18,
                    ...selectedBetsTest?.ltc.low1to18,
                    ...selectedBetsTest?.eth.low1to18,
                  ]}
                />
              ) : (
                ""
              )}
              1 to 18
            </div>
            <div
              className={`${hover.type === "Even" ? "hover-class" : ""}`}
              onClick={() =>
                addBet(
                  selectedBlockchain,
                  betAmount,
                  "even",
                  "Even",
                  [
                    "2",
                    "4",
                    "6",
                    "8",
                    "10",
                    "12",
                    "14",
                    "16",
                    "18",
                    "20",
                    "22",
                    "24",
                    "26",
                    "28",
                    "30",
                    "32",
                    "34",
                    "36",
                  ],
                  bettingTimer.timer
                )
              }
              onMouseOver={() =>
                handleMouseOver(
                  "Even",
                  [
                    "2",
                    "4",
                    "6",
                    "8",
                    "10",
                    "12",
                    "14",
                    "16",
                    "18",
                    "20",
                    "22",
                    "24",
                    "26",
                    "28",
                    "30",
                    "32",
                    "34",
                    "36",
                  ],
                  "2",
                  "All Evens"
                )
              }
              onMouseOut={() => handleMouseOut()}
            >
              {selectedBetsTest?.totalBet > 0 &&
              (!!selectedBetsTest?.btc.even?.length ||
                !!selectedBetsTest?.ltc.even?.length ||
                !!selectedBetsTest?.eth.even?.length) ? (
                <ShowChipsMemo
                  selectedBets={[
                    ...selectedBetsTest?.btc.even,
                    ...selectedBetsTest?.ltc.even,
                    ...selectedBetsTest?.eth.even,
                  ]}
                />
              ) : (
                ""
              )}
              EVEN
            </div>
            <div
              className={`color-selection ${
                hover.type === "Red" ? "hover-class" : ""
              }`}
              onClick={() =>
                addBet(
                  selectedBlockchain,
                  betAmount,
                  "red",
                  "Red",
                  [
                    "1",
                    "3",
                    "5",
                    "7",
                    "9",
                    "12",
                    "14",
                    "16",
                    "18",
                    "19",
                    "21",
                    "23",
                    "25",
                    "27",
                    "30",
                    "32",
                    "34",
                    "36",
                  ],
                  bettingTimer.timer
                )
              }
              onMouseOver={() =>
                handleMouseOver(
                  "Red",
                  [
                    "1",
                    "3",
                    "5",
                    "7",
                    "9",
                    "12",
                    "14",
                    "16",
                    "18",
                    "19",
                    "21",
                    "23",
                    "25",
                    "27",
                    "30",
                    "32",
                    "34",
                    "36",
                  ],
                  "2",
                  "All Reds"
                )
              }
              onMouseOut={() => handleMouseOut()}
            >
              {selectedBetsTest?.totalBet > 0 &&
              (!!selectedBetsTest?.btc.red?.length ||
                !!selectedBetsTest?.ltc.red?.length ||
                !!selectedBetsTest?.eth.red?.length) ? (
                <ShowChipsMemo
                  selectedBets={[
                    ...selectedBetsTest?.btc.red,
                    ...selectedBetsTest?.ltc.red,
                    ...selectedBetsTest?.eth.red,
                  ]}
                />
              ) : (
                ""
              )}
              <svg
                viewBox="0 0 70 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M35.27.531l-33.776 12 33.777 12 33.223-12-33.223-12z"
                  fill="#fe2247"
                ></path>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M35.272 0l34.693 12.531-34.693 12.531L0 12.532 35.272 0zM2.987 12.531L35.27 24l31.753-11.469L35.269 1.062 2.987 12.532z"
                  fill="#F0ECFF"
                ></path>
              </svg>
            </div>
            <div
              className={`color-selection ${
                hover.type === "Black" ? "hover-class" : ""
              }`}
              onClick={() =>
                addBet(
                  selectedBlockchain,
                  betAmount,
                  "Black",
                  "Black",
                  [
                    "2",
                    "4",
                    "6",
                    "8",
                    "10",
                    "11",
                    "13",
                    "15",
                    "17",
                    "20",
                    "22",
                    "24",
                    "26",
                    "28",
                    "29",
                    "31",
                    "33",
                    "35",
                  ],
                  bettingTimer.timer
                )
              }
              onMouseOver={() =>
                handleMouseOver(
                  "Black",
                  [
                    "2",
                    "4",
                    "6",
                    "8",
                    "10",
                    "11",
                    "13",
                    "15",
                    "17",
                    "20",
                    "22",
                    "24",
                    "26",
                    "28",
                    "29",
                    "31",
                    "33",
                    "35",
                  ],
                  "2",
                  "All Blacks"
                )
              }
              onMouseOut={() => handleMouseOut()}
            >
              {selectedBetsTest?.totalBet > 0 &&
              (!!selectedBetsTest?.btc.Black?.length ||
                !!selectedBetsTest?.ltc.Black?.length ||
                !!selectedBetsTest?.eth.Black?.length) ? (
                <ShowChipsMemo
                  selectedBets={[
                    ...selectedBetsTest?.btc.Black,
                    ...selectedBetsTest?.ltc.Black,
                    ...selectedBetsTest?.eth.Black,
                  ]}
                />
              ) : (
                ""
              )}
              <svg
                viewBox="0 0 71 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M35.777 1L2 13l33.777 12L69 13 35.777 1z"
                  fill="#000000"
                ></path>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M35.778.469L70.472 13 35.778 25.531.506 13.001 35.778.468zM3.493 13L35.776 24.47 67.528 13 35.776 1.531 3.493 13.001z"
                  fill="#F0ECFF"
                ></path>
              </svg>
            </div>
            <div
              className={`${hover.type === "Odd" ? "hover-class" : ""}`}
              onClick={() =>
                addBet(
                  selectedBlockchain,
                  betAmount,
                  "odd",
                  "Odd",
                  [
                    "1",
                    "3",
                    "5",
                    "7",
                    "9",
                    "11",
                    "13",
                    "15",
                    "17",
                    "19",
                    "21",
                    "23",
                    "25",
                    "27",
                    "29",
                    "31",
                    "33",
                    "35",
                  ],
                  bettingTimer.timer
                )
              }
              onMouseOver={() =>
                handleMouseOver(
                  "Odd",
                  [
                    "1",
                    "3",
                    "5",
                    "7",
                    "9",
                    "11",
                    "13",
                    "15",
                    "17",
                    "19",
                    "21",
                    "23",
                    "25",
                    "27",
                    "29",
                    "31",
                    "33",
                    "35",
                  ],
                  "2",
                  "All Odds"
                )
              }
              onMouseOut={() => handleMouseOut()}
            >
              {selectedBetsTest?.totalBet > 0 &&
              (!!selectedBetsTest?.btc.odd?.length ||
                !!selectedBetsTest?.ltc.odd?.length ||
                !!selectedBetsTest?.eth.odd?.length) ? (
                <ShowChipsMemo
                  selectedBets={[
                    ...selectedBetsTest?.btc.odd,
                    ...selectedBetsTest?.ltc.odd,
                    ...selectedBetsTest?.eth.odd,
                  ]}
                />
              ) : (
                ""
              )}
              ODD
            </div>
            <div
              className={`${hover.type === "High" ? "hover-class" : ""}`}
              onClick={() =>
                addBet(
                  selectedBlockchain,
                  betAmount,
                  "high19to36",
                  "High",
                  [
                    "19",
                    "20",
                    "21",
                    "22",
                    "23",
                    "24",
                    "25",
                    "26",
                    "27",
                    "28",
                    "29",
                    "30",
                    "31",
                    "32",
                    "33",
                    "34",
                    "35",
                    "36",
                  ],
                  bettingTimer.timer
                )
              }
              onMouseOver={() =>
                handleMouseOver(
                  "High",
                  [
                    "19",
                    "20",
                    "21",
                    "22",
                    "23",
                    "24",
                    "25",
                    "26",
                    "27",
                    "28",
                    "29",
                    "30",
                    "31",
                    "32",
                    "33",
                    "34",
                    "35",
                    "36",
                  ],
                  "2",
                  "19 to 36"
                )
              }
              onMouseOut={() => handleMouseOut()}
            >
              {selectedBetsTest?.totalBet > 0 &&
              (!!selectedBetsTest?.btc.high19to36?.length ||
                !!selectedBetsTest?.ltc.high19to36?.length ||
                !!selectedBetsTest?.eth.high19to36?.length) ? (
                <ShowChipsMemo
                  selectedBets={[
                    ...selectedBetsTest?.btc.high19to36,
                    ...selectedBetsTest?.ltc.high19to36,
                    ...selectedBetsTest?.eth.high19to36,
                  ]}
                />
              ) : (
                ""
              )}{" "}
              19 to 36
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

WebBetTable.propTypes = {
  userBets: PropTypes.func,
  selectedBets: PropTypes.func,
  betAmount: PropTypes.number,
  addBet: PropTypes.func,
  selectedBlockchain: PropTypes.func,
  handleMouseOver: PropTypes.object,
  handleMouseOut: PropTypes.object,
  hover: PropTypes.object,
  bettingTimer: PropTypes.number,
  tableRef: PropTypes.number,
  selectedBetsTest: PropTypes.object,
};

export default WebBetTable;
