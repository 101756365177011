import { useState } from "react";
/* import Footer from "../footer/footer";
 */ import PropTypes from "prop-types";
import Header from "../header/header";
import { Helmet } from "react-helmet";

const Layout = ({ children, setShowMobileBet, showMobileBet }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Helmet>
        <html lang="en" className={`${showMobileBet ? "add-sidebar" : ""}`} />
      </Helmet>
      <div className={`wrapper`}>
        <Header
          show={show}
          setShow={setShow}
          setShowMobileBet={setShowMobileBet}
        />
        <div className="content-wrapper">
          <div className="roulette-wrapper">
            <div className="container">
              <div className="roulette-wrapper-container">
                <div className="roulette-game-page">{children}</div>
              </div>
            </div>
          </div>
        </div>

        {/* <Footer  show={show} setShow={setShow} /> */}
      </div>
    </>
  );
};

export default Layout;

Layout.propTypes = {
  children: PropTypes.array,
  setShowMobileBet: PropTypes.func,
  showMobileBet: PropTypes.any,
};
