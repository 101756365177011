import { useEffect, useState } from "react";
import {
  getLastBets,
  getLastUserBets,
  getGameHistory,
  getWinLossHistory,
} from "../../reducers/gameReducers";
import { useSelector, useDispatch } from "react-redux";

const useGameHistory = (lastBetsSkip, historyBetsSkip, userLastBetsSkip) => {
  const gameObj = useSelector((state) => state.game);
  const gamesBets = gameObj.gamesBets;
  const userBets = gameObj.userBets;
  const gameHistory = gameObj.gameHistory;
  const winHistory = gameObj.winHistory;
  const lossHistory = gameObj.lossHistory;

  console.log("winHistorywinHistorywinHistorywinHistory", lossHistory);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const userToken = localStorage.getItem("usertoken");
  useEffect(() => {
    setLoading(true);
    dispatch(getLastBets({ skip: lastBetsSkip }));
    setLoading(false);
  }, [lastBetsSkip, userToken]);

  useEffect(() => {
    if (userToken) {
      setLoading(true);
      dispatch(getLastUserBets({ skip: userLastBetsSkip }));
      setLoading(false);
    }
  }, [userLastBetsSkip, userToken]);

  useEffect(() => {
    setLoading(true);
    dispatch(getGameHistory({ skip: historyBetsSkip }));
    setLoading(false);
  }, [historyBetsSkip]);

  useEffect(() => {
    setLoading(true);
    dispatch(getWinLossHistory());
    setLoading(false);
  }, []);

  return [gameHistory, gamesBets, userBets, loading, winHistory, lossHistory];
};

export default useGameHistory;
