import openSocket from 'socket.io-client';
import { server } from './keys';

const socket = openSocket(server, {
  transports: ['websocket'],
  rejectUnauthorized: false,
  query: `token=${localStorage.getItem('usertoken')}`,
});

socket.on('connect', () => {
  const isLogin = localStorage.getItem('usertoken');
  const userid = localStorage.getItem('userid');

  if (isLogin && userid) {
    socket.emit('join', userid);
  }
});

const tryReconnect = () => {
  setTimeout(() => {
    socket.io.open((err) => {
      if (err) {
        tryReconnect();
      }
    });
  }, 2000);
};

socket.io.on('close', tryReconnect);

export { socket };
