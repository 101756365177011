import React from "react";
import PropTypes from "prop-types";
import coin1 from "../../../assets/images/roulette/0.001.svg";
import coin2 from "../../../assets/images/roulette/0.005.svg";
import coin3 from "../../../assets/images/roulette/0.01.svg";
import coin4 from "../../../assets/images/roulette/0.05.svg";
import coin5 from "../../../assets/images/roulette/0.1.svg";
import coin6 from "../../../assets/images/roulette/1.svg";
import arrow from "../../../assets/images/roulette/down-arrow.png";
import BTC from "../../../assets/images/roulette/btc.png";
import ETH from "../../../assets/images/roulette/eth.png";
import LTC from "../../../assets/images/roulette/ltc.png";
import { useSelector } from "react-redux";
import ShowFixBetAmountMemo from "../ShowFixBetAmount";
import undo from "../../../assets/images/roulette/history.png";
import cross from "../../../assets/images/roulette/close.png";
import { Button } from "react-bootstrap";
//import { Button } from 'react-bootstrap';
//import PayoutBetSection from './PayoutBetSection';

const PlaceBetSection = ({
  betAmount,
  token,
  increseBetAmount,
  handleinfoShow,
  handleSelect,
  showSelect,
  handleCurrency,
  selectedBets,
  setBetAmount,
  wrapperRef,
  WalletAmount,
  Currency,
  // userBets,
  selectedBetsTest,
  bettingTimer,
  resetOneBet,
  resetAllBet,
  prevSelectedBet,
  revertResetBets,
  revertLastRoundBets,
  lastBetsOrder,
}) => {
  const gameObj = useSelector((state) => state.game);
  const { showingResult } = gameObj;
  const { userData } = gameObj;
  const { wallet: userWallet, cryptoWallet } = userData || {};

  const winAmount = () => {
    return sessionStorage.getItem("rouletteWinAmount")
      ? sessionStorage.getItem("rouletteWinAmount") * 1
      : 0;
  };

  console.log("winAmount", winAmount());

  const currImage = () => {
    if (Currency === "BTC") {
      return BTC;
    }
    if (Currency === "LTC") {
      return LTC;
    }
    if (Currency === "ETH") {
      return ETH;
    }
  };

  // console.log("selectedBetsTest", selectedBetsTest);
  console.log("LAST BETS ORDERM  ", { lastBetsOrder });
  return (
    <>
      <div className="winner-section">
        <div className="select-chips-section">
          <h6>
            Place Bet here{" "}
            <span className="md-ddnone">
              <i
                onClick={() => handleinfoShow()}
                className="fa fa-info-circle"
                aria-hidden="true"
              >
                {" "}
              </i>{" "}
              &nbsp; Limit
            </span>
          </h6>
          {bettingTimer > 4 &&
            ((selectedBetsTest && selectedBetsTest.totalBet > 0) ||
              prevSelectedBet.totalBet > 0) && (
              <div className="bet-revert-section">
                {bettingTimer > 4 &&
                  selectedBetsTest &&
                  selectedBetsTest.totalBet > 0 && (
                    <span
                      title="revert one bet"
                      className="bet-revert-icon"
                      onClick={resetOneBet}
                    >
                      {" "}
                      <i className="fa fa-undo"></i>
                    </span>
                  )}

                {/* <Button onClick={makeBet} disabled={!selectedBets.length}>
            Place bet
          </Button> */}

                {bettingTimer > 4 &&
                  selectedBetsTest &&
                  selectedBetsTest.totalBetAmount > 0 && (
                    <span
                      title="reset all bets"
                      className="bet-revert-icon"
                      onClick={resetAllBet}
                      // onClick={() => {
                      //   console.log("");
                      // }}
                    >
                      {" "}
                      <i className="fa fa-times"></i>
                    </span>
                  )}
                {/* prevSelectedBet && prevSelectedBet.length */}
                {prevSelectedBet && prevSelectedBet.totalBet > 0 && (
                  <span
                    title="Revert previous reset bets"
                    className="bet-revert-icon"
                    onClick={revertResetBets}
                    // onClick={() => {
                    //   console.log("");
                    // }}
                  >
                    {" "}
                    <i className="fa fa-history"></i>
                  </span>
                )}
              </div>
            )}
          {/* <div className="place-bet"></div> */}
          <div className="chips-box-list">
            <div className="place-bet-heading">
              <div className="bet-coins">
                <div
                  className={`bet-box ${
                    showingResult || betAmount === userWallet || token
                      ? "bet-box-disabled"
                      : betAmount == 0.001
                      ? "active-bet-box"
                      : ""
                  }`}
                  onClick={() => increseBetAmount(0.001)}
                >
                  <img src={coin1} />
                </div>
                <div
                  className={`bet-box ${
                    showingResult || betAmount === userWallet || token
                      ? "bet-box-disabled"
                      : betAmount === 0.005
                      ? "active-bet-box"
                      : ""
                  }`}
                  onClick={() => increseBetAmount(0.005)}
                >
                  <img src={coin2} />
                </div>
                <div
                  className={`bet-box ${
                    showingResult || betAmount === userWallet || token
                      ? "bet-box-disabled"
                      : betAmount === 0.01
                      ? "active-bet-box"
                      : ""
                  }`}
                  onClick={() => increseBetAmount(0.01)}
                >
                  <img src={coin3} />
                </div>
                <div
                  className={`bet-box ${
                    showingResult || betAmount === userWallet || token
                      ? "bet-box-disabled"
                      : betAmount === 0.05
                      ? "active-bet-box"
                      : ""
                  }`}
                  onClick={() => increseBetAmount(0.05)}
                >
                  <img src={coin4} />
                </div>
                <div
                  className={`bet-box ${
                    showingResult || betAmount === userWallet || token
                      ? "bet-box-disabled"
                      : betAmount === 0.1
                      ? "active-bet-box"
                      : ""
                  }`}
                  onClick={() => increseBetAmount(0.1)}
                >
                  <img src={coin5} />{" "}
                </div>
                <div
                  className={`bet-box ${
                    showingResult || betAmount === userWallet || token
                      ? "bet-box-disabled"
                      : betAmount === 1
                      ? "active-bet-box"
                      : ""
                  }`}
                  onClick={() => {
                    if (showingResult) {
                      return;
                    }
                    setBetAmount(1);
                  }}
                >
                  <img src={coin6} />{" "}
                </div>
              </div>
              {lastBetsOrder && !!lastBetsOrder.length && (
                <Button onClick={() => revertLastRoundBets()}>
                  Revert Last Round Bet
                </Button>
              )}
              {bettingTimer > 4 && selectedBets && selectedBets.length > 0 ? (
                <div className="revert-bet-section">
                  {bettingTimer > 4 && selectedBets && selectedBets.length > 0 && (
                    <span
                      title="revert one bet"
                      className="bet-revert-icon"
                      // onClick={resetOneBet}
                      onClick={() => console.log("")}
                    >
                      {" "}
                      {/* <i className="fa fa-undo"></i> */}
                      <img src={undo} alt="undo" />
                    </span>
                  )}
                  {bettingTimer > 4 && selectedBets && selectedBets.length > 0 && (
                    <span
                      title="reset all bets"
                      className="bet-revert-icon"
                      // onClick={resetAllBet}
                      onClick={() => console.log("")}
                    >
                      {" "}
                      <img src={cross} alt="undo" />
                      {/* <i className="fa fa-times"></i> */}
                    </span>
                  )}
                  {/* {prevSelectedBet && prevSelectedBet.length > 0 && ( */}
                  {[] && [].length > 0 && (
                    <span
                      title="Revert previous reset bets"
                      className="bet-revert-icon"
                      // onClick={revertResetBets}
                      onClick={() => console.log("")}
                    >
                      {" "}
                      <i className="fa fa-history"></i>
                    </span>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="bet-total-container">
              <div className="sm-ddnone">
                <h6>
                  Limit
                  <i
                    onClick={() => handleinfoShow()}
                    className="fa fa-info-circle"
                    aria-hidden="true"
                  >
                    {" "}
                  </i>
                </h6>
              </div>
              {localStorage.getItem("usertoken") && (
                <div className="close-option">
                  <div className="custom-select-option" ref={wrapperRef}>
                    <h6 onClick={handleSelect}>
                      <div className="">
                        <img src={currImage()} className="icon-img" />{" "}
                        <ShowFixBetAmountMemo betTotal={WalletAmount} />{" "}
                      </div>
                      <img src={arrow} alt="arrow" />
                    </h6>
                    {showSelect && (
                      <div className="select-option">
                        <p
                          onClick={() =>
                            handleCurrency("BTC", cryptoWallet["btc"]?.amount)
                          }
                        >
                          {" "}
                          <img src={BTC} alt="eth" />{" "}
                          <ShowFixBetAmountMemo
                            betTotal={cryptoWallet["btc"]?.amount}
                          />
                        </p>
                        <p
                          onClick={() =>
                            handleCurrency("ETH", cryptoWallet["eth"]?.amount)
                          }
                        >
                          <img src={ETH} alt="eth" />{" "}
                          <ShowFixBetAmountMemo
                            betTotal={cryptoWallet["eth"]?.amount}
                          />
                        </p>
                        <p
                          onClick={() =>
                            handleCurrency("LTC", cryptoWallet["ltc"]?.amount)
                          }
                        >
                          <img src={LTC} alt="ltc" />
                          <ShowFixBetAmountMemo
                            betTotal={cryptoWallet["ltc"]?.amount}
                          />
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="bet-total-container-child sm-ddnone">
                <h6 className="bet-heading">Selected Bet Amount</h6>
                <h4 className="bet-amount">
                  <ShowFixBetAmountMemo
                    betTotal={selectedBetsTest?.totalBetAmount}
                  />
                  <img src={currImage()} className="btc-img" />{" "}
                </h4>
              </div>
              <div className="bet-total-container-child sm-ddnone">
                <h6 className="bet-heading">Win Amount</h6>
                <h4 style={{ marginLeft: "100px" }} className="bet-amount">
                  {winAmount().toFixed(6)}
                </h4>
                <img src={currImage()} className="btc-img" />{" "}
              </div>
            </div>
            <div className="bet-total-container2 md-ddnone">
              <div className="bet-total-container-child">
                <h6 className="bet-heading">Selected Bet Amount</h6>
                <h4 className="bet-amount">
                  <ShowFixBetAmountMemo
                    betTotal={
                      selectedBets && selectedBets.length > 0
                        ? selectedBets.reduce(
                            (prev, curr) => prev + curr.betAmount,
                            0
                            // eslint-disable-next-line no-mixed-spaces-and-tabs
                          )
                        : 0
                    }
                  />
                  <img className="btc-img" src={currImage()} />
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6"> </div>
        </div>
      </div>
    </>
  );
};
export default PlaceBetSection;
PlaceBetSection.propTypes = {
  betAmount: PropTypes.number,
  token: PropTypes.bool,
  increseBetAmount: PropTypes.func,
  handleinfoShow: PropTypes.func,
  handleSelect: PropTypes.func,
  showSelect: PropTypes.bool,
  handleCurrency: PropTypes.func,
  selectedBets: PropTypes.func,
  setBetAmount: PropTypes.number,
  wrapperRef: PropTypes.any,
  WalletAmount: PropTypes.number,
  Currency: PropTypes.any,
  userBets: PropTypes.object,
  tableRef: PropTypes.number,
  selectedBetsTest: PropTypes.object,
  bettingTimer: PropTypes.any,
  resetOneBet: PropTypes.any,
  resetAllBet: PropTypes.any,
  prevSelectedBet: PropTypes.any,
  revertResetBets: PropTypes.any,
  hover: PropTypes.any,
  selectedBlockchain: PropTypes.any,
  getBets: PropTypes.any,
  makeBet: PropTypes.any,
  // handlePreviousRoundBets: PropTypes.func,
  lastBetsOrder: PropTypes.array,
  revertLastRoundBets: PropTypes.func,
};
