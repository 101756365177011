import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { gameInstance, betInstances } from "../config/axios";

const initialState = {
  userData: {},
  fareResult: {},
  gameHistory: [],
  winHistory: [],
  lossHistory: [],
  historyCount: 0,
  currentGameUsers: [],
  prevGameData: [],
  showingResult: false,
  playSound: true,
  showMobileBet: false,
  gamesBets: [],
  lastBetsCount: 0,
  userBets: [],
  userLastBetsCount: 0,
  lastGameHistory: {},
  selectedCurrency: {
    currency: "BTC",
    walletAmount: 0,
    blockchain: "btc",
  },
  statics: {
    colorStats: {
      black: {
        count: 0,
        percent: 0,
      },
      red: {
        count: 0,
        percent: 0,
      },
      green: {
        count: 0,
        percent: 0,
      },
    },
    oddEvenStats: {
      odd: {
        count: 0,
        percent: 0,
      },
      even: {
        count: 0,
        percent: 0,
      },
    },
    hotColdStats: {
      cold: [],
      hot: [],
    },
    numbersStats: {
      oneToNineteen: {
        count: 0,
        percent: 0,
        name: "1-18",
      },
      nineteenToThirtySix: {
        count: 0,
        percent: 0,
        name: "19-36",
      },
      zero: {
        count: 0,
        percent: 0,
        name: "0",
      },
    },
    dozenStats: {
      firstDozen: {
        count: 0,
        percent: 0,
        name: "1-12",
      },
      secondDozen: {
        count: 0,
        percent: 0,
        name: "13-24",
      },
      thirdDozen: {
        count: 0,
        percent: 0,
        name: "25-36",
      },
      zero: {
        count: 0,
        percent: 0,
        name: "0",
      },
    },
    columnStats: {
      firstColumn: {
        count: 0,
        percent: 0,
      },
      secondColumn: {
        count: 0,
        percent: 0,
      },
      thirdColumn: {
        count: 0,
        percent: 0,
      },
    },
  },
};
// get user Profile
export const getUserProfile = createAsyncThunk(
  "game/getUserProfile",
  async ({ rejectWithValue }) => {
    try {
      const res = await gameInstance().get("/getUserProfile");
      return {
        userData: res?.data,
        currentGameUsers: res?.data?.bets,
      };
    } catch (err) {
      localStorage.removeItem("usertoken");
      return rejectWithValue({
        msg: err.response.data.msg ?? err.response.data.error,
      });
    }
  }
);

// get Fairness result
export const getFareResult = createAsyncThunk(
  "game/getFareResult",
  async (data, { rejectWithValue }) => {
    const { nonce, secret, gameType } = data;
    try {
      const res = await gameInstance().get("/getFareResult", {
        params: { nonce, secret, gameType },
      });

      return {
        fareResult: res?.data,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg ?? err.response.data.error,
      });
    }
  }
);

// get Fairness result
export const getStatics = createAsyncThunk(
  "game/getStatics",
  async (data, { rejectWithValue }) => {
    try {
      const { limit } = data;
      const res = await gameInstance().get("/getStatics", {
        params: { limit },
      });

      return {
        statics: res?.data,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg ?? "Some error occur",
      });
    }
  }
);

// get latest game history
// export const getGameHistory = createAsyncThunk(
//   'game/getGameHistory',
//   async ({ rejectWithValue }) => {
//     try {
//       const res = await gameInstance().get('/getGameHistory');
//
//       return {
//         gamehistory: res.data,
//       };
//     } catch (err) {
//       return rejectWithValue({
//         msg: err.response.data.msg ?? err.response.data.error,
//       });
//     }
//   }
// );
// join game
export const joinGame = createAsyncThunk(
  "game/joinGame",
  async (data, { rejectWithValue }) => {
    try {
      const res = await gameInstance().post("/joinGame", data);

      return res.data;
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg ?? err.response.data.error,
      });
    }
  }
);

export const getLastBets = createAsyncThunk("bet/getBets", async (data) => {
  const { skip } = data || {};

  const res = await betInstances().get("/getLastBets", {
    params: { skip },
  });

  return res.data;
});
export const getLastUserBets = createAsyncThunk(
  "bet/UserBets",
  async (data) => {
    const { skip } = data || {};

    const res = await betInstances().get("/getLastUserBets", {
      params: { skip },
    });
    return res.data;
  }
);
export const getGameHistory = createAsyncThunk(
  "bet/GameHistory",
  async (data) => {
    const { skip } = data || {};

    const res = await betInstances().get("/getGameHistory", {
      params: { skip },
    });
    return res.data;
  }
);
export const getWinLossHistory = createAsyncThunk(
  "bet/getWinLossHistory",
  async (data) => {
    const { skip } = data || {};
    const res = await betInstances().get("/getWinLossHistory", {
      params: { skip },
    });
    return res.data;
  }
);

export const getLastGameHistory = createAsyncThunk(
  "bet/getLastBetHistory",
  async (data, { rejectWithValue }) => {
    try {
      const res = await betInstances().get("/getLastBetHistory");

      return {
        lastGameHistory: res?.data,
      };
    } catch (err) {
      return rejectWithValue({
        msg: err.response.data.msg ?? "Some error occur",
      });
    }
  }
);

//game sound

// slices

export const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    updateShowingResult: (state, action) => {
      state.showingResult = action?.payload?.currentValue;
    },
    addJoinUser: (state, { payload }) => {
      state.currentGameUsers.push(payload.joinData);
    },
    resetCurrentGame: (state) => {
      state.currentGameUsers = [];
      state.showingResult = false;
    },
    gameSound: (state, action) => {
      state.playSound = action.payload;
    },
    mobileBet: (state, action) => {
      state.showMobileBet = action.payload;
    },

    updateWallet: (state, { payload }) => {
      state.userData.cryptoWallet[payload?.blockchain].amount =
        state.userData.cryptoWallet[payload?.blockchain].amount +
        payload?.winAmount;
    },
    addResultGameInfo: (state, { payload }) => {
      const { bet } = payload;
      const { _id } = { ...state.userData };
      const prevGamesBets = [...state.gamesBets];
      if (prevGamesBets.length >= 20) {
        prevGamesBets.pop();
      }
      prevGamesBets.unshift(bet);
      state.gamesBets = [...prevGamesBets];

      if (bet.userid._id.toString() === _id.toString()) {
        const prevUserGamesBets = [...state.userBets];
        if (prevUserGamesBets.length >= 20) {
          prevUserGamesBets.pop();
        }
        prevUserGamesBets.unshift(bet);
        state.userBets = [...prevUserGamesBets];
      }
    },
    clearStateAfterLogout: (state) => {
      state.userData = {};
      state.userBets = [];
    },

    Changecurrency: (state, action) => {
      state.selectedCurrency.currency = action.payload.curr;
      state.selectedCurrency.walletAmount = action.payload.amount;
      state.selectedCurrency.blockchain = action.payload.blockchain;
    },

    prevGameData: (state, action) => {
      state.prevGameData = action.payload.storePreviousBets;
    },
  },

  extraReducers: (builder) => {
    // getUserProfile   slice

    builder.addCase(getUserProfile.fulfilled, (state, { payload }) => {
      const userData = payload && payload.userData.findUser;
      const data = payload;
      return /* state = */ {
        ...state,
        userData,
        currentGameUsers: data.currentGameUsers,
      };
    });

    // getFareResult slice
    builder.addCase(getFareResult.fulfilled, (state, { payload }) => {
      const { fareResult } = payload;
      return /* state = */ { ...state, fareResult };
    });
    // get satasticss
    builder.addCase(getStatics.fulfilled, (state, { payload }) => {
      const { statics } = payload;
      const {
        colorStats,
        oddEvenStats,
        hotColdStats,
        numbersStats,
        dozenStats,
        columnStats,
        //dozen1st12And1stColumn,
        // dozen2nd12And2ndColumn,
        // dozen3rd12And3rdColumn,
      } = statics;

      const newColorStats = {
        [`${colorStats[0]?._id}`]: {
          count: colorStats[0]?.count,
          percent: colorStats[0]?.percent,
        },
        [`${colorStats[1]?._id}`]: {
          count: colorStats[1]?.count,
          percent: colorStats[1]?.percent,
        },
        [`${colorStats[2]?._id}`]: {
          count: colorStats[2]?.count,
          percent: colorStats[2]?.percent,
        },
      };

      const newOddEvenStats = {
        [`${oddEvenStats[0]?._id}`]: {
          count: oddEvenStats[0]?.count,
          percent: oddEvenStats[0]?.percent,
        },
        [`${oddEvenStats[1]?._id}`]: {
          count: oddEvenStats[1]?.count,
          percent: oddEvenStats[1]?.percent,
        },
      };

      const { hot, cold } = hotColdStats[0] ?? { hot: [], cold: [] };

      const newHotCold = {
        hot: [...hot],
        cold: [...cold],
      };

      const newNumberStats = {
        [`${numbersStats[0]?._id}`]: {
          count: numbersStats[0]?.count,
          percent: numbersStats[0]?.percent,
        },
        [`${numbersStats[1]?._id}`]: {
          count: numbersStats[1]?.count,
          percent: numbersStats[1]?.percent,
        },
        [`${numbersStats[2]?._id}`]: {
          count: numbersStats[2]?.count,
          percent: numbersStats[2]?.percent,
        },
      };
      const newDozenStats = {
        [`${dozenStats[0]?._id}`]: {
          count: dozenStats[0]?.count,
          percent: dozenStats[0]?.percent,
        },
        [`${dozenStats[1]?._id}`]: {
          count: dozenStats[1]?.count,
          percent: dozenStats[1]?.percent,
        },
        [`${dozenStats[2]?._id}`]: {
          count: dozenStats[2]?.count,
          percent: dozenStats[2]?.percent,
        },
        [`${dozenStats[3]?._id}`]: {
          count: dozenStats[3]?.count,
          percent: dozenStats[3]?.percent,
        },
      };
      const newColumnStats = {
        [`${columnStats[0]?._id}`]: {
          count: columnStats[0]?.count,
          percent: columnStats[0]?.percent,
        },
        [`${columnStats[1]?._id}`]: {
          count: columnStats[1]?.count,
          percent: columnStats[1]?.percent,
        },
        [`${columnStats[2]?._id}`]: {
          count: columnStats[2]?.count,
          percent: columnStats[2]?.percent,
        },
        [`${columnStats[3]?._id}`]: {
          count: columnStats[3]?.count,
          percent: columnStats[3]?.percent,
        },
      };

      return {
        ...state,
        statics: {
          colorStats: { ...newColorStats },
          oddEvenStats: { ...newOddEvenStats },
          hotColdStats: { ...newHotCold },
          numbersStats: { ...numbersStats, ...newNumberStats },
          dozenStats: { ...dozenStats, ...newDozenStats },
          columnStats: { ...columnStats, ...newColumnStats },
        },
      };
    });

    // getGameHistory
    // builder.addCase(getGameHistory.fulfilled, (state, { payload }) => {
    //   const { gamehistory } = payload || [];
    //   return (state = { ...state, gamehistory });
    // });
    builder.addCase(joinGame.fulfilled, (state, { payload }) => {
      const { updatedWallet } = payload;
      state.userData.cryptoWallet = updatedWallet;
      // const { gamehistory } = payload;
      // return (state = { ...state, gamehistory });
    });

    builder.addCase(getLastBets.fulfilled, (state, { payload }) => {
      const { bets, lastBetsCount } = payload;
      state.gamesBets = [...bets];
      state.lastBetsCount = lastBetsCount;
      // state.userBets = [...userBets];
      // state.gameHistory = [...history];
    });
    builder.addCase(getLastUserBets.fulfilled, (state, { payload }) => {
      const { userBets, userLastBetsCount } = payload || [];
      // state.gamesBets = [...bets];
      state.userBets = [...userBets];
      state.userLastBetsCount = userLastBetsCount;
      // state.gameHistory = [...history];
    });
    builder.addCase(getGameHistory.fulfilled, (state, { payload }) => {
      const { history, historyCount } = payload || [];
      // state.gamesBets = [...bets];
      // state.userBets = [...userBets];
      state.gameHistory = [...history];
      state.historyCount = historyCount;
    });
    builder.addCase(getWinLossHistory.fulfilled, (state, { payload }) => {
      const { winHistory, lossHistory } = payload || [];
      //console.log("lossHistory", lossHistory);
      state.winHistory = [...winHistory];
      state.lossHistory = [...lossHistory];
    });

    builder.addCase(getLastGameHistory.fulfilled, (state, { payload }) => {
      const { lastGameHistory } = payload || [];
      console.log("lossHistorytrying", lastGameHistory);
      state.lastGameHistory = lastGameHistory;
      //state.lossHistory = [...lossHistory];
    });
  },
});

export const {
  updateShowingResult,
  addJoinUser,
  resetCurrentGame,
  updateWallet,
  gameSound,
  addResultGameInfo,
  clearStateAfterLogout,
  mobileBet,
  Changecurrency,
  prevGameData,
} = gameSlice.actions;

// Action creators are generated for each case reducer function

export default gameSlice.reducer;
