import React from "react";
import { HashRouter, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import Roulette from "./components/roulette/roulette";
import RouletteResult from "./components/roulette/RouletteResult";
import SlotResult from "./components/slotResult";

import "animate.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { socket } from "./config/socket";
import NewRoulette from "./components/roulette/newRoulette";
import useGameHistorySocket from "./components/BetHistory/useGameHistorySocket";
import "./responsive.css";
import { getUserProfile } from "./reducers/gameReducers";
const App = () => {
  const dispatch = useDispatch();
  useGameHistorySocket();
  if (localStorage.getItem("usertoken")) {
    dispatch(getUserProfile("profile"));
  }

  return (
    <div className="App">
      <div className="main-wrapper">
        <React.Fragment>
          <HashRouter>
            <main>
              <div className="main_content">
                <Route
                  exact
                  path="/"
                  render={() => <Roulette socket={socket} />}
                />
                <Route
                  exact
                  path="/new"
                  render={() => <NewRoulette socket={socket} />}
                />
                <Route
                  path="/rouletteresult/:secret?/:nonce?"
                  render={() => <RouletteResult socket={socket} />}
                />
                <Route
                  path="/slotresult/:secret?/:nonce?"
                  render={() => <SlotResult socket={socket} />}
                />
              </div>
            </main>
          </HashRouter>
        </React.Fragment>
      </div>
      <ToastContainer />
    </div>
  );
};

export default App;
