import React from "react";
import PropTypes from "prop-types";
import { useEffect, useState, memo } from "react";

let t;
const ShowChips = ({ selectedBets }) => {
  console.log({ selectedBets });
  const [chippp, setChippp] = useState([]);

  const obj = {
    0.1: {
      primary: "#1949F3",
      secondary: "#DAE2FF",
      centerUpper: "#CAD6FF",
      center: "#DFE6FF",
      textColor: "#021454",
    },
    0.01: {
      primary: "#4B0AB5",
      secondary: "#CEB0FF",
      centerUpper: "#D7BFFF",
      center: "#EFE6FF",
      textColor: "#1E024B",
    },
    0.001: {
      primary: "#D3326C",
      secondary: "#FFC4DA",
      centerUpper: "#FECCDF",
      center: "#FFE2ED",
      textColor: "#70092E",
    },
    0.05: {
      primary: "#FEBB0F",
      secondary: "#FFE8AB",
      centerUpper: "#FEEAB7",
      center: "#FFF3D5",
      textColor: "#412802",
    },
    0.005: {
      primary: "#00866E",
      secondary: "#95F9E6",
      centerUpper: "#B5FFF1",
      center: "#E0FFF9",
      textColor: "#013D32",
    },
    1: {
      primary: "#FF3B3B",
      secondary: "#FFB9B9",
      centerUpper: "#FFC6C6",
      center: "#FFDBDB",
      textColor: "#360202",
    },
  };
  const total = () => {
    if (selectedBets.length > 0) {
      return selectedBets.reduce((prev, cur) => prev + cur.betAmount, 0);
      // userBets
      // 	.filter(
      // 		(el) =>
      // 			el.type === matchWith.type &&
      // 			JSON.stringify(el.value) === JSON.stringify(matchWith.value)
      // 	)
      // 	.reduce((prev, cur) => prev + cur.amount, 0)
    } else {
      return 0;
    }
  };

  useEffect(() => {
    t = total();
    console.log("total", t);

    let ccc = [];
    [1, 0.1, 0.05, 0.01, 0.005, 0.001].forEach((element) => {
      const count = t / element;

      const numberBeforeDecimal = parseInt(count);
      if (numberBeforeDecimal > 0) {
        t -= numberBeforeDecimal * element;
        [...Array(numberBeforeDecimal).keys()].forEach(() => {
          ccc.push({ type: element });
        });
      }
    });
    if (ccc.length > 0) {
      setChippp(ccc);
    }
  }, [selectedBets]);

  return (
    <>
      {chippp.length > 0 ? (
        <div className="chips-section">
          <div className="chips-box">
            {chippp &&
              chippp.map((el, i) => {
                return (
                  <>
                    <svg
                      key={`svg-chips-${i + el.betAmount}`}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 48 50"
                    >
                      <g
                        clipPath="url(#clip-chip-0.05-f480a8a7-f9c9-4182-87da-0781e49d5856)"
                        filter="url(#filter-chip-0.05-f480a8a7-f9c9-4182-87da-0781e49d5856)"
                      >
                        <path
                          fill={
                            obj[el.type]?.primary
                              ? obj[el.type]?.primary
                              : obj[el.type]?.primary
                          }
                          d="M40.97 40.97c9.373-9.372 9.373-24.568 0-33.94-9.372-9.373-24.568-9.373-33.94 0-9.373 9.372-9.373 24.568 0 33.94 9.372 9.373 24.568 9.373 33.94 0z"
                        ></path>

                        <path
                          fill={
                            obj[el.type]?.secondary
                              ? obj[el.type]?.secondary
                              : obj[el.type]?.secondary
                          }
                          d="M9.866 4.601a24.13 24.13 0 00-2.721 2.313l-.022.021a24.131 24.131 0 00-2.34 2.685l4.827 4.887 2.539-2.506L14.69 9.49 9.866 4.6zM.31 20.121a24.121 24.121 0 00-.309 3.549v.035a24.207 24.207 0 00.218 3.56l6.87.085.046-3.567.046-3.573-6.87-.088zM9.37 33.119l-4.951 4.761a24.126 24.126 0 004.952 5.148l4.95-4.763-2.473-2.57-2.478-2.576zm10.62 7.65l-.177 6.868c2.358.413 4.765.475 7.141.184l.174-6.87-3.566-.09-3.572-.092zm18.146-6.902l-2.603 2.44-2.606 2.444 4.697 5.012a24.14 24.14 0 005.211-4.885l-4.698-5.01-.001-.001zm2.855-12.77l-.138 3.565-.137 3.57 6.864.265a24.05 24.05 0 00.402-3.541v-.022a24.23 24.23 0 00-.126-3.575l-6.866-.262zM39.122 5.362l-5.071 4.633 2.405 2.634 2.41 2.64 5.073-4.632a24.13 24.13 0 00-4.817-5.274zM24 0c-1.197 0-2.392.088-3.576.265l.002 6.87h4.017l.439.022 3.569.183.353-6.86A24.11 24.11 0 0024 0z"
                          style={{ transform: "rotate(0deg)" }}
                        ></path>
                        <path
                          fill={
                            obj[el.type]?.centerUpper
                              ? obj[el.type]?.centerUpper
                              : obj[el.type]?.centerUpper
                          }
                          d="M37.218 37.217c7.3-7.3 7.3-19.135 0-26.435-7.3-7.3-19.136-7.3-26.435 0-7.3 7.3-7.3 19.135 0 26.435 7.3 7.3 19.135 7.3 26.435 0z"
                        ></path>
                        <path
                          fill={
                            obj[el.type]?.center
                              ? obj[el.type]?.center
                              : obj[el.type]?.center
                          }
                          d="M35.45 35.467c6.335-6.336 6.337-16.607.002-22.94-6.334-6.335-16.605-6.334-22.94.001-6.336 6.336-6.337 16.607-.003 22.941s16.605 6.333 22.941-.002z"
                        ></path>
                        <text
                          xmlns="http://www.w3.org/2000/svg"
                          textAnchor="middle"
                          x="50%"
                          y="50%"
                          style={{
                            fontSize: "10px",
                          }}
                          fill={
                            obj[el.type]?.textColor
                              ? obj[el.type]?.textColor
                              : obj[el.type]?.textColor
                          }
                        >
                          {el.type ? el.type : el.type}
                        </text>
                      </g>
                      <defs>
                        <filter
                          id="filter-chip-0.05-f480a8a7-f9c9-4182-87da-0781e49d5856"
                          width="48"
                          height="52"
                          x="0"
                          y="0"
                          colorInterpolationFilters="sRGB"
                          filterUnits="userSpaceOnUse"
                        >
                          <feFlood
                            floodOpacity="0"
                            result="BackgroundImageFix"
                          ></feFlood>
                          <feColorMatrix
                            in="SourceAlpha"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          ></feColorMatrix>
                          <feOffset dy="2"></feOffset>
                          <feColorMatrix values="0 0 0 0 0.8875 0 0 0 0 0.640776 0 0 0 0 0.0073958 0 0 0 1 0"></feColorMatrix>
                          <feBlend
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow"
                          ></feBlend>
                          <feBlend
                            in="SourceGraphic"
                            in2="effect1_dropShadow"
                            result="shape"
                          ></feBlend>
                        </filter>
                        <clipPath id="clip-chip-0.05-f480a8a7-f9c9-4182-87da-0781e49d5856">
                          <path fill="#fff" d="M0 0H48V50H0z"></path>
                        </clipPath>
                      </defs>
                    </svg>
                  </>
                );
              })}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
const ShowChipsMemo = memo(ShowChips);
export default ShowChipsMemo;
ShowChips.propTypes = {
  selectedBets: PropTypes.array,
  value: PropTypes.string,
  matchWith: PropTypes.object,
  userBets: PropTypes.array,
  betAmount: PropTypes.number,
  //color: PropTypes.string,
  //chipsObj: PropTypes.object,
};
