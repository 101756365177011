/* eslint-disable react/prop-types */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { socket } from "../../config/socket";
import {
  updateShowingResult,
  resetCurrentGame,
  getGameHistory,
  getLastBets,
  getStatics,
  getLastGameHistory,
  // gameSound,
} from "../../reducers/gameReducers";
import ValidAgeImage from "../../assets/images/header/age-check-a6a6b31af103e88f920b7c393d2df387.png";
import GameStartMusic from "../../assets/sounds/RouletteWheel.mp3";
import GameWin from "../../assets/sounds/game-win.mp3";
import GameLose from "../../assets/sounds/loose.wav";

import placeBets from "../../assets/sounds/place-your-bets.mp3";
import Zero from "../../assets/sounds/betssound/0-of-green.mp3";
import DoubleZero from "../../assets/sounds/betssound/00-of-green.mp3";
import NoMoreBets from "../../assets/sounds/betssound/no-more-bets.mp3";
import CountDownOne from "../../assets/sounds/betssound/1.mp3";
import CountDownTwo from "../../assets/sounds/betssound/2.mp3";
import CountDownThree from "../../assets/sounds/betssound/3.mp3";
import GO from "../../assets/sounds/betssound/go.mp3";
import Welcome from "../../assets/sounds/betssound/WELCOME-TO-THE-roulette-GAME.mp3";
import One from "../../assets/sounds/betssound/1-odd-red.mp3";
import Two from "../../assets/sounds/betssound/2-even-black.mp3";
import Three from "../../assets/sounds/betssound/3-odd-red.mp3";
import Four from "../../assets/sounds/betssound/4-even-black.mp3";
import Five from "../../assets/sounds/betssound/5-odd-red.mp3";
import Six from "../../assets/sounds/betssound/6-even-black.mp3";
import Seven from "../../assets/sounds/betssound/7-odd-red.mp3";
import Eight from "../../assets/sounds/betssound/8-even-black.mp3";
import Nine from "../../assets/sounds/betssound/9-odd-red.mp3";
import Ten from "../../assets/sounds/betssound/10-even-black.mp3";
import Eleven from "../../assets/sounds/betssound/11-odd-black.mp3";
import Tweleve from "../../assets/sounds/betssound/12-even-red.mp3";
import Thirteen from "../../assets/sounds/betssound/13-odd-black.mp3";
import Fourteen from "../../assets/sounds/betssound/14-even-red.mp3";
import Fifteen from "../../assets/sounds/betssound/15-odd-black.mp3";
import Sixteen from "../../assets/sounds/betssound/16-even-red.mp3";
import Seventeen from "../../assets/sounds/betssound/17-odd-black.mp3";
import Eighteen from "../../assets/sounds/betssound/18-even-red.mp3";
import Nineteen from "../../assets/sounds/betssound/19-odd-red.mp3";
import Twenty from "../../assets/sounds/betssound/20-even-black.mp3";
import TwentyOne from "../../assets/sounds/betssound/21-odd-red.mp3";
import TwentyTWo from "../../assets/sounds/betssound/22-even-black.mp3";
import TwentyThree from "../../assets/sounds/betssound/23-odd-red.mp3";
import TwentyFour from "../../assets/sounds/betssound/24-even-black.mp3";
import TwentyFive from "../../assets/sounds/betssound/25-odd-red.mp3";
import TwentySix from "../../assets/sounds/betssound/26-even-black.mp3";
import TwentySeven from "../../assets/sounds/betssound/27-odd-red.mp3";
import TwentyEight from "../../assets/sounds/betssound/28-even-black.mp3";
import TwentyNine from "../../assets/sounds/betssound/29-odd-black.mp3";
import Thirty from "../../assets/sounds/betssound/30-even-red.mp3";
import ThirtyOne from "../../assets/sounds/betssound/31-odd-black.mp3";
import ThirtyTwo from "../../assets/sounds/betssound/32-even-red.mp3";
import ThirtyThree from "../../assets/sounds/betssound/33-odd-black.mp3";
import ThirtyFour from "../../assets/sounds/betssound/34-even-red.mp3";
import ThirtyFive from "../../assets/sounds/betssound/35-odd-black.mp3";
import ThirtySix from "../../assets/sounds/betssound/36-even-red.mp3";
import { Button, Modal } from "react-bootstrap";
import mainlogo from "../../assets/images/header/logo-main.png";
import { client } from "../../config/keys";
import close from "../../assets/images/header/close.svg";
import BTC from "../../assets/images/roulette/btc.png";
import ETH from "../../assets/images/roulette/eth.png";
import LTC from "../../assets/images/roulette/ltc.png";
// import loseImg from "./../../assets/images/roulette/you-lose-new.png";
// import winnerImg from "./../../assets/images/roulette/winner-new.png";

const RouletteWheel = ({
  playSound,
  setUserBets,
  bettingTimer,
  resetAfterBet,
  setShowMobileBet,
  wheelRef,
}) => {
  const gameObj = useSelector((state) => state.game);
  //const gamesBets = gameObj.gamesBets;
  //const userBets = gameObj.userBets;
  const { currentGameUsers, userData } = gameObj;

  const dispatch = useDispatch();

  const [maskDefault] = useState("Place Your Bets");
  const [isResult, setIsResult] = useState(false);
  const [timer] = useState(9000);
  const [maskText, setmaskText] = useState("Place Your Bets");
  const [result, setResult] = useState([]);
  // const [bettingTimer, setBettingTimer] = useState(0);

  const [outcome, setOutcome] = useState({});
  const [show, setShow] = useState(true);
  const [welcomePopUp, setWelcomePopUp] = useState(true);
  const [spin, setSpin] = useState(false);
  const [winnerPopUp, setWinnerPopUp] = useState(false);
  const [userwinnerPopUp, setUserWinPopup] = useState(false);
  const [userLossPopup, setUserLossPopup] = useState(false);
  const [gameWinLoseAmount, setGameWinLoseAmount] = useState(0);
  const [currency, setCurrency] = useState("");
  const handleClose = () => {
    setShow(false);
    window.location.href = `${client}`;
  };
  const handleWelcome = () => {
    localStorage.setItem("welcomeuser", true);
    setWelcomePopUp(false);
  };

  const [red] = useState([
    "32",
    "19",
    "21",
    "25",
    "34",
    "27",
    "36",
    "30",
    "23",
    "5",
    "16",
    "1",
    "14",
    "9",
    "18",
    "7",
    "12",
    "3",
  ]);
  let wel = localStorage.getItem("welcomeuser");
  useEffect(async () => {
    if (wel) {
      let element = document.getElementById("Welcome");
      if (element) {
        element.play();
      }
    }
  }, [wel]);

  useEffect(() => {
    socket.on("gameStrt", () => {});
    socket.on("pleaseBet", () => {
      let element = document.getElementById("placebets");
      if (element) {
        element.play();
      }
    });
    socket.on("spin", (data) => {
      setSpin(true);
      let element = document.getElementById("startGameMusic");
      if (element) {
        element.play();
      }
      handleSpin(data);
      setmaskText("");
      dispatch(updateShowingResult({ currentValue: true }));
    });

    socket.on("reset", () => {
      dispatch(getGameHistory("history"));
      dispatch(getLastBets());
      // her update the statics
      dispatch(getStatics());
      handleReset();
      dispatch(resetCurrentGame());
      setIsResult(false);
      setWinnerPopUp(false);
      setUserWinPopup(false);
      setUserLossPopup(false);
      resetAfterBet();
      dispatch(getLastGameHistory());
      setUserBets(() => []);
    });
    socket.on("winners", (/* data */) => {});
    return () => {
      socket.off("gameStrt");
      socket.off("pleaseBet");
      socket.off("spin");
      socket.off("reset");
      socket.off("winners");
    };
  }, [currentGameUsers, userData]);

  useEffect(() => {
    socket.on(
      "preTimer",
      (data) => {
        if (data.timer === 3) {
          let inner = document.getElementById("Three");
          if (inner) {
            inner.play();
          }
        } else if (data.timer === 2) {
          let inner = document.getElementById("Two");
          if (inner) {
            inner.play();
          }
        } else if (data.timer === 1) {
          let inner = document.getElementById("One");
          if (inner) {
            inner.play();
          }
        } else if (data.timer === 0) {
          let inner = document.getElementById("Go");
          if (inner) {
            inner.play();
            setShowMobileBet(false);
          }
        }
        // setBettingTimer(data);
      },

      []
    );
    //socket.off("preTimer");
  }, []);

  const handleSpin = (data) => {
    setGameWinLoseAmount();
    setCurrency("");
    let color = null;
    let color1 = null;
    // get a random number between 0 and 36 and apply it to the nth-child selector
    let inner = document.getElementsByClassName("inner")[0];
    inner.setAttribute("data-spinto", data.number);
    let input = inner.children[data.number];

    data.number !== "00" && input.setAttribute("checked", true);

    // Get current user bets and status
    // idle means user have not bet
    let getCurUserBets = [];
    let curUserGameStatus = "idle";
    if (currentGameUsers.length > 0 && userData?.username) {
      // filter user current bets
      getCurUserBets = currentGameUsers.filter(
        (el) => el.username === userData.username
      );
      if (getCurUserBets.length > 0) {
        const isWin = getCurUserBets.some((el) =>
          el.value.find((elem) => elem === data.number)
        );
        if (isWin) {
          curUserGameStatus = "win";
        } else {
          curUserGameStatus = "loss";
        }
      }
    }

    setTimeout(function () {
      let inner = document.getElementById("NoMoreBets");
      if (inner) {
        inner.play();
      }
      //setmaskText("");
    });
    setTimeout(function () {
      setmaskText(maskDefault);
    }, timer + 500);

    // remove the disabled attribute when the ball has stopped
    setTimeout(function () {
      setWinnerPopUp(true);
      setSpin(false);
      if (red.find((el) => el === data.number)) {
        color = "red";
      } else {
        color = "black";
      }
      if (data.number == "0") {
        color = "green";
      }
      if (data.number == "00") {
        color = "green";
      }
      if (red.find((el) => el === data.number)) {
        color1 = "#eb2958";
      } else {
        color1 = "black";
      }
      if (data.number == "0") {
        color1 = "#21c9a6";
      }
      if (data.number == "00") {
        color1 = "#21c9a6";
      }
      //("randomNumber", data.number);

      let newResult = {
        number: data.number,
        color,
        bgcolor: color1,
      };
      let rr = [...result];
      rr.push(newResult);
      winerSound(data.number);
      setResult(rr);
      setIsResult(true);
      setOutcome({
        resultBgColor: color1,
        resultColor: color,
        resultNumber: data.number,
      });

      setUserWinPopup(curUserGameStatus === "win");
      setUserLossPopup(curUserGameStatus === "loss");
      // Show user win and loss

      if (curUserGameStatus === "win") {
        let element = document.getElementById("GameWin");
        if (element) {
          element.play();
        }
      }

      if (curUserGameStatus === "loss") {
        let element = document.getElementById("GameLose");
        if (element) {
          element.play();
        }
      }
    }, timer);
  };

  const handleReset = () => {
    let inner = document.getElementsByClassName("inner")[0];
    inner.setAttribute("data-spinto", "");
  };

  const winerSound = (result) => {
    let inner = document.getElementById(result);
    if (inner) {
      inner.play();
    }
  };

  const handleAge = () => {
    localStorage.setItem("age", true);
    setShow(false);
  };
  useEffect(() => {
    let age = localStorage.getItem("age");
    if (age) {
      setShow(false);
    }
  }, []);

  useEffect(() => {
    socket.on("gameWinAmt", (data) => {
      console.log("datatatattatatat", data);
      const { winAmt, loseAmt, blockchain, winbettingAmt, lossbettingAmt } =
        data;
      setGameWinLoseAmount({
        win: winAmt,
        loss: loseAmt,
        winBetAmount: winbettingAmt,
        lossBetAmount: lossbettingAmt,
      });
      console.log("userwinnerPopUp", userwinnerPopUp);
      const prevBetSession = sessionStorage.getItem("rouletteWinAmount")
        ? sessionStorage.getItem("rouletteWinAmount") * 1
        : 0;
      const newWINAmount = parseFloat(winAmt) > 0 ? parseFloat(winAmt) : 0;
      let winnerAmount = parseFloat(loseAmt) + newWINAmount;
      //alert(`Loss = ${loseAmt} : Win = ${newWINAmount}`);
      sessionStorage.setItem(
        "rouletteWinAmount",
        winnerAmount + prevBetSession
      );

      setCurrency(blockchain);
    });
    return () => {
      socket.off("gameWinAmt");
    };
  }, []);

  const currencyImage = () => {
    if (currency === "btc") {
      return BTC;
    } else if (currency === "ltc") {
      return LTC;
    } else if (currency === "eth") {
      return ETH;
    }
  };

  return (
    <div className="main">
      <div
        ref={wheelRef}
        className={`plate ${spin ? `activate-spin` : ``}`}
        id="plate"
      >
        <ul className={`inner ${isResult ? "rest" : ""}`}>
          <li className="number red-number">
            <label>
              <input type="radio" name="pit" value="32" />
              <span className="pit">32</span>
            </label>
          </li>
          <li className="number black-number">
            <label>
              <input type="radio" name="pit" value="15" />
              <span className="pit">15</span>
            </label>
          </li>
          <li className="number red-number">
            <label>
              <input type="radio" name="pit" value="19" />
              <span className="pit">19</span>
            </label>
          </li>
          <li className="number black-number">
            <label>
              <input type="radio" name="pit" value="4" />
              <span className="pit">4</span>
            </label>
          </li>
          <li className="number red-number">
            <label>
              <input type="radio" name="pit" value="21" />
              <span className="pit">21</span>
            </label>
          </li>
          <li className="number black-number">
            <label>
              <input type="radio" name="pit" value="2" />
              <span className="pit">2</span>
            </label>
          </li>
          <li className="number red-number">
            <label>
              <input type="radio" name="pit" value="25" />
              <span className="pit">25</span>
            </label>
          </li>
          <li className="number black-number">
            <label>
              <input type="radio" name="pit" value="17" />
              <span className="pit">17</span>
            </label>
          </li>
          <li className="number red-number">
            <label>
              <input type="radio" name="pit" value="34" />
              <span className="pit">34</span>
            </label>
          </li>
          <li className="number black-number">
            <label>
              <input type="radio" name="pit" value="6" />
              <span className="pit">6</span>
            </label>
          </li>
          <li className="number red-number">
            <label>
              <input type="radio" name="pit" value="27" />
              <span className="pit">27</span>
            </label>
          </li>
          <li className="number black-number">
            <label>
              <input type="radio" name="pit" value="13" />
              <span className="pit">13</span>
            </label>
          </li>
          <li className="number red-numbber">
            <label>
              <input type="radio" name="pit" value="36" />
              <span className="pit">36</span>
            </label>
          </li>
          <li className="number black-number">
            <label>
              <input type="radio" name="pit" value="11" />
              <span className="pit">11</span>
            </label>
          </li>
          <li className="number red-number">
            <label>
              <input type="radio" name="pit" value="30" />
              <span className="pit">30</span>
            </label>
          </li>
          <li className="number black-number">
            <label>
              <input type="radio" name="pit" value="8" />
              <span className="pit">8</span>
            </label>
          </li>
          <li className="number red-number">
            <label>
              <input type="radio" name="pit" value="23" />
              <span className="pit">23</span>
            </label>
          </li>
          <li className="number black-number">
            <label>
              <input type="radio" name="pit" value="10" />
              <span className="pit">10</span>
            </label>
          </li>
          <li className="number number-new">
            <label>
              <input type="radio" name="pit" value="00" />
              <span className="pit">00</span>
            </label>
          </li>
          <li className="number red-number">
            <label>
              <input type="radio" name="pit" value="5" />
              <span className="pit">5</span>
            </label>
          </li>
          <li className="number black-number">
            <label>
              <input type="radio" name="pit" value="24" />
              <span className="pit">24</span>
            </label>
          </li>
          <li className="number red-number">
            <label>
              <input type="radio" name="pit" value="16" />
              <span className="pit">16</span>
            </label>
          </li>
          <li className="number black-number">
            <label>
              <input type="radio" name="pit" value="33" />
              <span className="pit">33</span>
            </label>
          </li>
          <li className="number red-number">
            <label>
              <input type="radio" name="pit" value="1" />
              <span className="pit">1</span>
            </label>
          </li>
          <li className="number black-number">
            <label>
              <input type="radio" name="pit" value="20" />
              <span className="pit">20</span>
            </label>
          </li>
          <li className="number red-number">
            <label>
              <input type="radio" name="pit" value="14" />
              <span className="pit">14</span>
            </label>
          </li>
          <li className="number black-number">
            <label>
              <input type="radio" name="pit" value="31" />
              <span className="pit">31</span>
            </label>
          </li>
          <li className="number red-number">
            <label>
              <input type="radio" name="pit" value="9" />
              <span className="pit">9</span>
            </label>
          </li>
          <li className="number black-number">
            <label>
              <input type="radio" name="pit" value="22" />
              <span className="pit">22</span>
            </label>
          </li>
          <li className="number red-number">
            <label>
              <input type="radio" name="pit" value="18" />
              <span className="pit">18</span>
            </label>
          </li>
          <li className="number black-number">
            <label>
              <input type="radio" name="pit" value="29" />
              <span className="pit">29</span>
            </label>
          </li>
          <li className="number red-number">
            <label>
              <input type="radio" name="pit" value="7" />
              <span className="pit">7</span>
            </label>
          </li>
          <li className="number black-number">
            <label>
              <input type="radio" name="pit" value="28" />
              <span className="pit">28</span>
            </label>
          </li>
          <li className="number red-number">
            <label>
              <input type="radio" name="pit" value="12" />
              <span className="pit">12</span>
            </label>
          </li>
          <li className="number black-number">
            <label>
              <input type="radio" name="pit" value="35" />
              <span className="pit">35</span>
            </label>
          </li>
          <li className="number red-number">
            <label>
              <input type="radio" name="pit" value="3" />
              <span className="pit">3</span>
            </label>
          </li>
          <li className="number black-number">
            <label>
              <input type="radio" name="pit" value="26" />
              <span className="pit">26</span>
            </label>
          </li>

          <li className="number">
            <label>
              <input type="radio" name="pit" value="0" />
              <span className="pit">0</span>
            </label>
          </li>
        </ul>
        <div className={`data ${isResult ? "reveal" : ""}`}>
          <div className="data-inner">
            <span>{bettingTimer.timer === 0 ? null : bettingTimer.timer}</span>
            {/* <div className="mask">{maskText}</div> */}
            <div className="mask">{maskText}</div>

            <div
              className="result"
              style={{ backgroundColor: outcome.resultBgColor }}
            >
              <div className="result-number">{outcome.resultNumber}</div>
              <div className="result-color">{outcome.resultColor}</div>
            </div>
          </div>
          <audio
            id="startGameMusic"
            type="audio/mpeg"
            muted={!playSound}
            src={GameStartMusic}
          ></audio>
          <audio id="placebets" muted={!playSound} src={placeBets}></audio>
          <audio id="0" muted={!playSound} src={Zero}></audio>
          <audio id="00" muted={!playSound} src={DoubleZero}></audio>
          <audio id="1" muted={!playSound} src={One}></audio>
          <audio id="2" muted={!playSound} src={Two}></audio>
          <audio id="3" muted={!playSound} src={Three}></audio>
          <audio id="4" muted={!playSound} src={Four}></audio>
          <audio id="5" muted={!playSound} src={Five}></audio>
          <audio id="6" muted={!playSound} src={Six}></audio>
          <audio id="7" muted={!playSound} src={Seven}></audio>
          <audio id="8" muted={!playSound} src={Eight}></audio>
          <audio id="9" muted={!playSound} src={Nine}></audio>
          <audio id="10" muted={!playSound} src={Ten}></audio>
          <audio id="11" muted={!playSound} src={Eleven}></audio>
          <audio id="12" muted={!playSound} src={Tweleve}></audio>
          <audio id="13" muted={!playSound} src={Thirteen}></audio>
          <audio id="14" muted={!playSound} src={Fourteen}></audio>
          <audio id="15" muted={!playSound} src={Fifteen}></audio>
          <audio id="16" muted={!playSound} src={Sixteen}></audio>
          <audio id="17" muted={!playSound} src={Seventeen}></audio>
          <audio id="18" muted={!playSound} src={Eighteen}></audio>
          <audio id="19" muted={!playSound} src={Nineteen}></audio>
          <audio id="20" muted={!playSound} src={Twenty}></audio>
          <audio id="21" muted={!playSound} src={TwentyOne}></audio>{" "}
          <audio id="22" muted={!playSound} src={TwentyTWo}></audio>{" "}
          <audio id="23" muted={!playSound} src={TwentyThree}></audio>{" "}
          <audio id="24" muted={!playSound} src={TwentyFour}></audio>{" "}
          <audio id="25" muted={!playSound} src={TwentyFive}></audio>{" "}
          <audio id="26" muted={!playSound} src={TwentySix}></audio>
          <audio id="27" muted={!playSound} src={TwentySeven}></audio>
          <audio id="28" muted={!playSound} src={TwentyEight}></audio>
          <audio id="29" muted={!playSound} src={TwentyNine}></audio>
          <audio id="30" muted={!playSound} src={Thirty}></audio>
          <audio id="31" muted={!playSound} src={ThirtyOne}></audio>
          <audio id="32" muted={!playSound} src={ThirtyTwo}></audio>
          <audio id="33" muted={!playSound} src={ThirtyThree}></audio>
          <audio id="34" muted={!playSound} src={ThirtyFour}></audio>
          <audio id="35" muted={!playSound} src={ThirtyFive}></audio>
          <audio id="36" muted={!playSound} src={ThirtySix}></audio>
          <audio id="One" muted={!playSound} src={CountDownOne}></audio>
          <audio id="Two" muted={!playSound} src={CountDownTwo}></audio>
          <audio id="Three" muted={!playSound} src={CountDownThree}></audio>
          <audio id="Go" muted={!playSound} src={GO}></audio>
          <audio id="Welcome" muted={!playSound} src={Welcome}></audio>
          <audio id="NoMoreBets" muted={!playSound} src={NoMoreBets}></audio>
          <audio id="GameWin" muted={!playSound} src={GameWin}></audio>
          <audio id="GameLose" muted={!playSound} src={GameLose}></audio>
        </div>
      </div>
      {/* <div className="previous-results">
        <ol className="previous-list">
          {gameHistory &&
            gameHistory.map((el, i) => (
              // eslint-disable-next-line react/jsx-key
              <li
                className={`previous-result color-${
                  el.result && el.result.color
                }`}
                key={i}
              >
                <span className={`previous-number`}>
                  {el.result && el.result.number}
                </span>

                <span className="visuallyhidden placeholder">
                  No results yet{" "}
                </span>
              </li>
            ))}
        </ol>
      </div> */}
      <Modal show={show} className="age-popup" centered>
        <Modal.Body>
          <div className="popup-img">
            <img src={ValidAgeImage}></img>
            <h2>Are you 18 or older?</h2>
          </div>
          <div className="popup-btn">
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="primary" onClick={handleAge}>
              Yes
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={welcomePopUp} className="welcome-popup" centered>
        <Modal.Body>
          <div className="welcome-img">
            <img src={mainlogo}></img>
            <h2>
              Welcome to the Roulette <br /> Game
            </h2>
          </div>
          <div className="welcome-btn">
            <Button variant="primary" onClick={handleWelcome}>
              Continue
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* <Modal show={winnerPopUp} className="winner-popup">
        <Modal.Body>
          <div className="winner-img">
            <h2 style={{ backgroundColor: outcome.resultBgColor }}>
              Winning Number
              <p>{outcome.resultNumber}</p>
            </h2>
            {<h2>{outcome.resultNumber % 2 == 0 ? "EVEN" : "ODD"}</h2>}
          </div>
        </Modal.Body> */}
      {winnerPopUp && (
        <>
          <div className="back-drop-shadow" />
          <div className="winner-img winner-user-section">
            <h2 style={{ backgroundColor: outcome.resultBgColor }}>
              Winning Number
              <p>{outcome.resultNumber}</p>
            </h2>
            {<h2>{outcome.resultNumber % 2 == 0 ? "EVEN" : "ODD"}</h2>}
          </div>
        </>
      )}
      {console.log("gameWinLoseAmount", gameWinLoseAmount)}
      {userwinnerPopUp && (
        <div className="winning-animation winning-animation-win">
          <div className="close-img">
            <img src={close} alt="close" />
          </div>

          <div className="winning-amount winns">
            <h2>You Win</h2>
            <div className="flex-win bet-amount-total">
              <p>Total Bet Amount: </p>
              <span>
                <img src={currencyImage()} className="icon-img" />{" "}
                {gameWinLoseAmount &&
                  (
                    gameWinLoseAmount?.winBetAmount +
                    gameWinLoseAmount?.lossBetAmount
                  )?.toFixed(6)}
              </span>
            </div>
            <div className="flex-win">
              <p>Win Amount:</p>
              <span>
                <img src={currencyImage()} className="icon-img" />{" "}
                {gameWinLoseAmount?.win?.toFixed(6)}
              </span>
            </div>

            <div className="flex-win">
              <p>Lose Amount:</p>
              <span style={{ color: "#ff7635" }}>
                {gameWinLoseAmount && "-"}{" "}
                <img src={currencyImage()} className="icon-img" />
                {gameWinLoseAmount?.loss?.toFixed(6).substring(1)}
              </span>
            </div>
          </div>

          <div className="before"></div>
          <div className="after"></div>
        </div>
      )}
      {userLossPopup && (
        <div className="winning-animation winning-animation-lose">
          <div className="close-img">
            <img src={close} alt="close" />
          </div>
          <div className="winning-amount winns">
            <h2>You Lose</h2>
            <div className="flex-win">
              <p>Total Bet Amount:</p>
              <span>
                <img src={currencyImage()} className="icon-img" />{" "}
                {gameWinLoseAmount &&
                  (
                    gameWinLoseAmount?.winBetAmount +
                    gameWinLoseAmount?.lossBetAmount
                  )?.toFixed(6)}
              </span>
            </div>
            <div className="flex-win">
              <p>Lose Amount:</p>
              <span>
                {gameWinLoseAmount && "-"}{" "}
                <img src={currencyImage()} className="icon-img" />
                {gameWinLoseAmount?.win?.toFixed(6).substring(1)}
              </span>
            </div>
          </div>

          <div className="before"></div>
          <div className="after"></div>
        </div>
      )}
    </div>
  );
};

export default RouletteWheel;
