import React from "react";
import doller from "../../../assets/images/roulette/doller.jpg";
import BTC from "../../../assets/images/roulette/btc.png";
import ETH from "../../../assets/images/roulette/eth.png";
import LTC from "../../../assets/images/roulette/ltc.png";
import { useSelector } from "react-redux";

const LastGameResult = () => {
  const gameObj = useSelector((state) => state.game);
  const history = gameObj.lastGameHistory.lastbethistory;
  console.log("gameObjgameObj", history);

  const handleAmount = (profit_loss) => {
    if (profit_loss) {
      return profit_loss?.toFixed(6);
    } else {
      return "0";
    }
  };

  return (
    <>
      <div className="winner-section last-result">
        <div className="select-chips-section">
          <h6>Last Game Result  </h6>
          <div className="result-listiing-section">
          <div className="result-listing">
            <p>Bet Amount </p>
            <span>
              <img
                className="btc-img"
                src={
                  history?.blockchain === "btc"
                    ? BTC
                    : history?.blockchain === "ltc"
                    ? LTC
                    : ETH
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = doller;
                }}
              />
              &nbsp;
              {history?.betAmount?.toFixed(6)}{" "}
            </span>
          </div>
          <div className="result-listing">
            <p>Result</p>
            <span>{history?.result}</span>
          </div>
          <div className="result-listing">
            <p>Win </p>
            <span>
              {" "}
              <img
                className="btc-img"
                src={
                  history?.blockchain === "btc"
                    ? BTC
                    : history?.blockchain === "ltc"
                    ? LTC
                    : ETH
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = doller;
                }}
              />
              &nbsp;{handleAmount(history?.win)}{" "}
            </span>
          </div>
          <div className="result-listing">
            <p>Loss </p>
            <span>
              {" "}
              <img
                className="btc-img"
                src={
                  history?.blockchain === "btc"
                    ? BTC
                    : history?.blockchain === "ltc"
                    ? LTC
                    : ETH
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = doller;
                }}
              />
              &nbsp;{handleAmount(history?.loss)}{" "}
            </span>
          </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LastGameResult;
