/* eslint-disable react/prop-types */
import React from "react";
import { Modal } from "react-bootstrap";
import user from "../../assets/images/roulette/user2.jpg";
import close from "../../assets/images/header/close.svg";
import slotImg from "../../assets/images/roulette/slot-cover.jpg";
import blackjack from "../../assets/images/roulette/blackjack-cover.jpg";
import defaultImg from "../../assets/images/roulette/dealer3.jpg";
import "./result.css";
import CopyToClipboard from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import BTC from "../../assets/images/roulette/btc.png";
import ETH from "../../assets/images/roulette/eth.png";
import LTC from "../../assets/images/roulette/ltc.png";
const Result = ({ show, handleClose, gameResult }) => {
  console.log("game result is here===>", gameResult);
  const copyClipBoard = () => {
    toast.success("Copied to Clipboard", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: 1,
    });
  };

  // const slotResult = () => {
  //   // const { result } = gameResult?.gameId;
  //   if (gameResult) {
  //     console.log("hh", gameResult?.gameId?.result);
  //     let res = gameResult?.gameId?.result;
  //     res.length > 0 &&
  //       res.forEach((el) => {
  //         return el;
  //       });
  //   }
  // };

  // console.log("slotResult", slotResult());
  //console.log("gameu", JSON.parse(JSON.stringify(gameResult?.gameId?.result)));

  return (
    <div className="bet-history">
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="history-popup"
        centered
        className="bet-history-popup"
      >
        <Modal.Body>
          <div className="history-heading">
            <h6>Bet History</h6>
            <img src={close} alt="close" onClick={handleClose} />
          </div>
          <div className="history-popup-content">
            <div className="user-image-section">
              <img
                src={
                  gameResult &&
                  (gameResult?.gameType === "Roulette"
                    ? user
                    : gameResult?.gameType === "Slot"
                    ? slotImg
                    : blackjack)
                }
                alt="user"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = defaultImg;
                }}
              />
              <div className="round-id-section">
                <h6>
                  Round Id:{" "}
                  <span>{gameResult && gameResult?.gameId?.nonce}</span>
                </h6>
                <h6>
                  Result:{" "}
                  {gameResult?.gameType === "Slot" ? (
                    <span title={gameResult?.result}>
                      {gameResult &&
                        gameResult?.result?.substring(0, 30) + "..."}
                    </span>
                  ) : (
                    <span title={gameResult?.result}>
                      {gameResult && gameResult?.result}
                    </span>
                  )}
                </h6>
              </div>
            </div>
            <div className="payout-section">
              <div className="bet">
                <p>Bet</p>
                <h6>
                  {gameResult && gameResult?.betAmount.toFixed(6)}
                  &nbsp;
                  <img
                    src={
                      gameResult?.blockchain === "btc"
                        ? BTC
                        : gameResult?.blockchain === "ltc"
                        ? LTC
                        : ETH
                    }
                    style={{ width: "15px", height: "15px" }}
                  />
                </h6>
              </div>
              <div className="bet">
                <p>Multiplier</p>
                <h6>
                  {gameResult && gameResult?.multiplier > 0
                    ? gameResult?.multiplier?.toFixed(6)
                    : "0.000000"}
                </h6>
              </div>
              <div className="bet">
                <p>Payout</p>
                <h6>
                  {gameResult && gameResult?.profit_loss
                    ? gameResult?.profit_loss.toFixed(6)
                    : "0.00"}
                  &nbsp;
                  <img
                    src={
                      gameResult?.blockchain === "btc"
                        ? BTC
                        : gameResult?.blockchain === "ltc"
                        ? LTC
                        : ETH
                    }
                    style={{ width: "15px", height: "15px" }}
                  />
                </h6>
              </div>
            </div>
            <div className="payout-textid">
              {gameResult?.gameType !== "Blackjack" && (
                <div className="bet">
                  <p>Bet hash</p>
                  {gameResult?.gameType === "Slot" ? (
                    <h6 title={"[[" + gameResult?.gameId?.result + "]]"}>
                      {JSON.parse(
                        JSON.stringify(gameResult?.gameId?.result)
                      )[0][0].substring(0, 45) + "..."}
                      {/* [{" "}
                    {gameResult?.gameId?.result?.map((el) => {
                      return (
                        <>
                          [{" "}
                          {el.map((elem, index) => {
                            if (elem.length - 1 === index) {
                              return `'${elem.substring(0, 7)}...'`;
                            } else {
                              return `'${elem.substring(0, 7)}...', `;
                            }
                          })}{" "}
                          ]
                          <br />
                        </>
                      );
                    })}{" "}
                    ] */}
                      <span>
                        <CopyToClipboard
                          text={gameResult && gameResult.gameId?.result}
                        >
                          <i
                            onClick={() => {
                              copyClipBoard();
                            }}
                            className="fa fa-clone"
                            aria-hidden="true"
                          ></i>
                        </CopyToClipboard>
                        <Link
                          to={`/slotresult/${
                            gameResult &&
                            JSON.stringify(gameResult.gameId?.result)
                          }/${gameResult && gameResult.gameId?.nonce}`}
                          target="_blank"
                        >
                          <i
                            className="fa fa-external-link"
                            aria-hidden="true"
                          ></i>{" "}
                        </Link>
                      </span>
                    </h6>
                  ) : (
                    <h6>
                      {gameResult && gameResult.gameId?.secret?.slice(0, 43)}...
                      <span>
                        <CopyToClipboard
                          text={gameResult && gameResult.gameId?.secret}
                        >
                          <i
                            onClick={() => {
                              copyClipBoard();
                            }}
                            className="fa fa-clone"
                            aria-hidden="true"
                          ></i>
                        </CopyToClipboard>

                        <Link
                          to={`/rouletteresult/${
                            gameResult && gameResult.gameId?.secret
                          }/${gameResult && gameResult.gameId?.nonce}`}
                          target="_blank"
                        >
                          <i
                            className="fa fa-external-link"
                            aria-hidden="true"
                          ></i>{" "}
                        </Link>
                      </span>
                    </h6>
                  )}
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Result;
