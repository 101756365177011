/* eslint-disable react/prop-types */
import React from "react";
import { Modal, Table } from "react-bootstrap";
import BTC from "../../assets/images/roulette/btc.png";
import ETH from "../../assets/images/roulette/eth.png";
import LTC from "../../assets/images/roulette/ltc.png";
import "./info.css";

const Info = ({ info, handleInfoClose, Currency }) => {
  const currImage = () => {
    if (Currency === "BTC") {
      return BTC;
    }
    if (Currency === "LTC") {
      return LTC;
    }
    if (Currency === "ETH") {
      return ETH;
    }
  };
  return (
    <div className="limit-modal-sec">
      <Modal
        className="limit-modal"
        show={info}
        onHide={handleInfoClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>TABLE LIMITS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="limit-modal-table">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Bet</th>
                  <th>
                    Max Bet <img src={currImage()} className="btc-img" />{" "}
                  </th>
                  <th>Payout</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Straight Up</td>
                  <td>0.2</td>
                  <td>x36</td>
                </tr>
                <tr>
                  <td>Split</td>
                  <td>0.5</td>
                  <td>x18</td>
                </tr>{" "}
                <tr>
                  <td>Street</td>
                  <td>0.8</td>
                  <td>x12</td>
                </tr>{" "}
                <tr>
                  <td>Corner</td>
                  <td>1.1</td>
                  <td>x9</td>
                </tr>{" "}
                <tr>
                  <td>Six Line</td>
                  <td>1.6</td>
                  <td>x6</td>
                </tr>{" "}
                <tr>
                  <td>Column/Dozen</td>
                  <td>3.3</td>
                  <td>x3</td>
                </tr>{" "}
                <tr>
                  <td>Red/Black </td>
                  <td>5</td>
                  <td>x2</td>
                </tr>
                <tr>
                  <td>Even/Odd </td>
                  <td>5</td>
                  <td>x2</td>
                </tr>{" "}
                <tr>
                  <td>Low/High</td>
                  <td>5</td>
                  <td>x2</td>
                </tr>
                <tr>
                  <td>Straight Up</td>
                  <td>0.2</td>
                  <td>x36</td>
                </tr>
                <tr>
                  <td>Straight Up</td>
                  <td>0.2</td>
                  <td>x36</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Info;
