import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import RouletteWheel from "./RouletteWheel";
import {
  getUserProfile,
  joinGame,
  addJoinUser,
  updateWallet,
  gameSound,
  Changecurrency,
  getLastGameHistory,
  // prevGameData,
} from "../../reducers/gameReducers";
import { socket } from "../../config/socket";
import GameStartMusic from "../../assets/sounds/mixkit-money-bag-drop-1989.wav";
import CoinSound from "../../assets/sounds/bet-chips.aac";
import { toast } from "react-toastify";
import Layout from "../layout/layout";
// import BTC from "../../assets/images/roulette/btc.png";

import useRevertBet from "../../HOOK/useRevertBet";
import "./header.css";
import "./roulette.css";
import BetHistory from "../BetHistory/BetHistory";
import Info from "../infoPopup/info";
// import MobileBetTable from "../roulette/BetTable/MobileBetTable";
import Statistics from "./statistics";
import PayoutBetSection from "./BetTable/PayoutBetSection";
import WebBetTable from "./BetTable/WebBetTable";
import PlaceBetSection from "./BetTable/PlaceBetSection";
import GameRule from "./model/GameRule";
import GameHistory from "./rouletteHistory/GameHistory";
import LastGameResult from "./BetTable/LastGameResult";
// import close from "../../assets/images/header/close.svg";

const Roulette = () => {
  const dispatch = useDispatch();
  const gameObj = useSelector((state) => state.game);
  const {
    selectedCurrency: { walletAmount, currency, blockchain },
  } = gameObj ?? {};
  const playSound = gameObj.playSound;
  const { cryptoWallet } = gameObj?.userData;
  const [token, setToken] = useState(true);
  const { showingResult } = gameObj;
  const { userData } = gameObj;
  const { wallet: userWallet } = userData || {};
  const [userBets, setUserBets] = useState([]);
  const [betAmount, setBetAmount] = useState(0);
  const [bettingTimer, setBettingTimer] = useState({ timer: 0 });
  const [info, setInfo] = useState(false);
  const wheelRef = useRef(null);
  const tableRef = useRef(null);
  const [showSelect, setShowSelect] = useState(false);
  const [browserWidth, setBrowerWidth] = useState(1100);
  const [hover, setHover] = useState({
    type: "",
    value: [],
    specialValueType: "",
  });
  // const [previousBets, setPreviousBets] = useState([]);
  const [
    selectedBets,
    prevSelectedBet,
    addBet,
    resetAllBet,
    revertResetBets,
    resetOneBet,
    resetAfterBet,
    selectedBetsTest,
    getBets,
    revertLastRoundBets,
    lastBetsOrder,
  ] = useRevertBet(userBets);

  console.log("selectedBets", revertLastRoundBets, { lastBetsOrder });

  const debounce = (callback, wait) => {
    let timeoutId = null;
    return (...args) => {
      window.clearTimeout(timeoutId);
      timeoutId = window.setTimeout(() => {
        callback.apply(null, args);
      }, wait);
    };
  };

  useEffect(() => {
    // https://stackoverflow.com/questions/5484578/how-to-get-document-height-and-width-without-using-jquery
    var bWidth = document.body.clientWidth;
    if (bWidth) {
      setBrowerWidth(bWidth);
    }
    const updateWidth = () => {
      const newWidth = document.body.clientWidth;
      if (newWidth) {
        setBrowerWidth(newWidth);
      }
    };
    window.addEventListener("resize", () => debounce(updateWidth, 500));
    return () => {
      window.removeEventListener("resize", () => console.log(""));
    };
  }, []);

  // https://www.codegrepper.com/code-examples/javascript/scroll+to+a+div+in+reactjs+using+useRef
  const scrollToRef = (ref) =>
    window.scrollTo(0, ref?.current?.offsetTop, "smooth");

  const [classOnScroll, setClassOnScroll] = useState(false);

  useEffect(() => {
    socket.on("preTimer", (data) => {
      setBettingTimer(data);
      if (data.timer === 4) {
        makeBet();
        scrollToRef(wheelRef);
        setClassOnScroll(false);
      }
      if (data.timer === 24) {
        setClassOnScroll(() => true);
        scrollToRef(tableRef);
      }
      //   console.log("data", data.timer);
    });
    return () => {
      socket.off("preTimer");
    };
  }, [selectedBets, selectedBetsTest]);

  useEffect(() => {
    if (cryptoWallet) {
      cryptoWallet[blockchain?.toLowerCase()]?.amount ?? 0;
    }
  }, [cryptoWallet, blockchain]);

  const makeBet = async () => {
    try {
      if (selectedBetsTest.totalBet > 0) {
        if (showingResult) {
          toast.error("You can't bet now !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId: 1,
          });
          return;
        }

        if (betAmount <= 0) {
          toast.error("Bet amount should be greater then 0", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId: 1,
          });
          return;
        }

        if (bettingTimer?.timer <= 2) {
          toast.error("Game is lock!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId: 1,
          });
          return;
        }

        if (!selectedBetsTest?.totalBet > 0) {
          toast.error("You have not bet on any number!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId: 1,
          });
          return;
        }

        if (!userWallet || selectedBetsTest?.totalBet > userWallet) {
          toast.error("Amount is not sufficient to make bet!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId: 1,
          });
          return;
        }

        // if (
        //   !userWallet ||
        //   selectedBets.reduce((prev, curr) => prev + curr.betAmount, 0) >
        //     userWallet
        // ) {
        //   toast.error("Amount is not sufficient to make bet!", {
        //     position: "top-right",
        //     autoClose: 5000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     toastId: 1,
        //   });
        //   return;
        // }

        let bets = [];
        const userBets = getBets();
        for await (const el of userBets) {
          if (!el) {
            return;
          }
          const { type, value, betAmount: amount, blockchain } = el;
          const joinObj = {
            amount: amount * 1,
            type: type,
            value: value,
            blockchain,
          };

          if (showingResult) {
            alert("Please bet in next round");
            return;
          }
          bets.push(joinObj);
        }
        if (!showingResult) {
          dispatch(joinGame({ betObj: bets }));
          // setUserBets(() => [...userBets, ...bets]);
          // setStorePreviousBets([...userBets]);
        }
      }

      // if (!showingResult && bets.length) {
      //   dispatch(joinGame({ betObj: bets }));
      //   setUserBets(() => [...userBets, ...bets]);
      // }
    } catch (error) {
      console.log("err", error);
    }
  };

  // console.log("storePreviousBets===============", storePreviousBets);
  useEffect(() => {
    if (localStorage.getItem("usertoken")) {
      dispatch(getUserProfile("profile"));
    }
    socket.on("reset", () => {
      console.log("reffffffff");
      // setUserBets([]);
      // setWinnerNumber(-1);
    });
    const userToken = localStorage.getItem("usertoken");
    if (userToken) {
      setToken(false);
    }
    return () => {
      socket.off("reset");
    };
  }, []);

  useEffect(() => {
    socket.on("rouletteUserJoin", (data) => {
      dispatch(addJoinUser({ joinData: data.userObj }));
    });
    socket.on("winners", () => {
      // setWinnerNumber(data.winnerNumber);
    });
    socket.on("userWinAmount", (data) => {
      console.log("data============>>", data);
      dispatch(updateWallet(data));
    });
    return () => {
      socket.off("userWinAmount");
      dispatch(getLastGameHistory());
      socket.off("winners");
      socket.off("rouletteUserJoin");
    };
  }, [dispatch]);

  const increseBetAmount = (amount) => {
    setBetAmount(amount);

    if (showingResult || token) {
      return;
    }
    if (parseFloat(betAmount) + amount > userWallet) {
      return;
    }
    let element = document.getElementById("amount");
    if (element) {
      element.play();
    }
  };

  useEffect(() => {
    if (cryptoWallet) {
      const currData = {
        amount: cryptoWallet[blockchain.toLowerCase()].amount,
        curr: currency || "BTC",
        blockchain: blockchain || "btc",
      };
      dispatch(Changecurrency(currData));
    }
  }, [cryptoWallet]);
  useEffect(() => {
    let myy = JSON.parse(localStorage.getItem("playSound"));
    if (myy === false) {
      dispatch(gameSound(false));
    } else if (myy === true) {
      dispatch(gameSound(true));
    } else {
      dispatch(gameSound(true));
    }
  }, []);

  const handleMouseOver = (type, value, payOut, specialValueType) => {
    console.log("typeiiiiiiiiiiii", specialValueType);
    // this is for remove same bet value when we select chips on  colums the bet value same for 3 and 2 and 1
    if (specialValueType === "Right") {
      type = "1st Column";
    } else if (specialValueType === "Middle") {
      type = "2nd Column";
    } else if (specialValueType === "Left") {
      type = "3rd Column";
    }
    // for six line
    else if (specialValueType === "1 to 6") {
      type = "Sixline";
    } else if (specialValueType === "4 to 9") {
      type = "Sixline";
    } else if (specialValueType === "7 to 12") {
      type = "Sixline";
    } else if (specialValueType === "10 to 15") {
      type = "Sixline";
    } else if (specialValueType === "13 to 18") {
      type = "Sixline";
    } else if (specialValueType === "16 to 21") {
      type = "Sixline";
    } else if (specialValueType === "19 to 24") {
      type = "Sixline";
    } else if (specialValueType === "22 to 27") {
      type = "Sixline";
    } else if (specialValueType === "25 to 30") {
      type = "Sixline";
    } else if (specialValueType === "28 to 33") {
      type = "Sixline";
    } else if (specialValueType === "31 to 36") {
      type = "Sixline";
    }

    // for below cloums
    else if (specialValueType === "1 to 12") {
      type = "Dozen 1st 12";
    } else if (specialValueType === "13 to 24") {
      type = "Dozen 2nd 12";
    } else if (specialValueType === "25 to 36") {
      type = "Dozen 3rd 12";
    }
    console.log("typetypetype", type);
    if (showingResult) {
      return;
    }
    setHover((prev) => {
      return {
        ...prev,
        type,
        value,
        payOut,
        specialValueType,
      };
    });
  };

  const handleMouseOut = () => {
    setHover((prev) => {
      return {
        ...prev,
        type: "",
        value: [],
        payOut: "",
        specialValueType: "",
      };
    });
  };

  const [showMobileBet, setShowMobileBet] = useState(false);

  const handleCurrency = (curr, amount) => {
    if (curr === "BTC") {
      //   setCurrency(BTC);
      //   setWalletAmount(amount);
      //   setShowSelect(false);
      //   setSelectedBlockChain("btc");
      const currData = {
        amount: amount,
        curr: "BTC",
        blockchain: "btc",
      };
      dispatch(Changecurrency(currData));
      setShowSelect(false);
    } else if (curr === "ETH") {
      const currData = {
        amount: amount,
        curr: "ETH",
        blockchain: "eth",
      };
      dispatch(Changecurrency(currData));
      setShowSelect(false);
    } else {
      const currData = {
        amount: amount,
        curr: "LTC",
        blockchain: "ltc",
      };
      dispatch(Changecurrency(currData));
      setShowSelect(false);
    }
  };

  const handleinfoShow = () => {
    setInfo(true);
  };
  const handleInfoClose = () => {
    setInfo(false);
  };

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (event.srcElement.className !== "close-option") {
            //setShowSelect(false);
          }
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const handleSelect = () => {
    setShowSelect(true);
  };

  useEffect(() => {
    dispatch(getLastGameHistory());
  }, []);

  // const handlePreviousRoundBets = () => {
  //   // console.log("jivan", storePreviousBets);
  //   // dispatch(prevGameData({ storePreviousBets }));
  // };
  return (
    <Layout showMobileBet={showMobileBet} setShowMobileBet={setShowMobileBet}>
      <div className="roulette-game-page">
        {/* <div className="winning-animation">
          <div className="close-img">
            <img src={close} alt="close" />
          </div>
          <div className="winning-amount">
            <p>Winning Amount</p>
            <h2>$12,500.00</h2>
          </div>

          <div className="before"></div>
          <div className="after"></div>
        </div> */}
        {/* -------------------Mobile bet section start----------- */}

        {/* -------------------Mobile bet section end----------- */}

        <div className="game-table-section">
          <div className="roulette-table-section">
            <RouletteWheel
              wheelRef={wheelRef}
              bettingTimer={bettingTimer}
              setUserBets={setUserBets}
              playSound={playSound}
              resetAfterBet={resetAfterBet}
              setShowMobileBet={setShowMobileBet}
            />
            {/* Here i am show the wheel result */}
            <GameHistory />

            {/* here i am call the place bet section component */}
            <div className="md-ddnone">
              <PlaceBetSection
                revertLastRoundBets={revertLastRoundBets}
                lastBetsOrder={lastBetsOrder}
                selectedBetsTest={selectedBetsTest}
                betAmount={betAmount}
                token={token}
                increseBetAmount={increseBetAmount}
                handleinfoShow={handleinfoShow}
                handleSelect={handleSelect}
                showSelect={showSelect}
                handleCurrency={handleCurrency}
                selectedBets={selectedBets}
                setBetAmount={setBetAmount}
                wrapperRef={wrapperRef}
                WalletAmount={walletAmount}
                Currency={currency}
                userBets={userBets}
                resetAllBet={resetAllBet}
                resetOneBet={resetOneBet}
                prevSelectedBet={prevSelectedBet}
                revertResetBets={revertResetBets}
                bettingTimer={bettingTimer.timer}
                hover={hover}
                selectedBlockchain={blockchain}
                getBets={getBets}
              />
            </div>

            {/* We'll show the bet table only on small displays and tablet */}
            {browserWidth <= 970 && (
              <div
                ref={tableRef}
                className={`md-ddnone ${classOnScroll ? "classname" : ""}`}
              >
                <WebBetTable
                  userBets={userBets}
                  selectedBets={selectedBets}
                  betAmount={betAmount}
                  addBet={addBet}
                  selectedBlockchain={blockchain}
                  // Check here
                  handleMouseOver={handleMouseOver}
                  handleMouseOut={handleMouseOut}
                  hover={hover}
                  bettingTimer={bettingTimer}
                  selectedBetsTest={selectedBetsTest}
                />
              </div>
            )}

            {/* Here i am show the game stats in percentage (which is visible only in desktop display) */}
            <div className="sm-ddnone">
              <Statistics />
            </div>
          </div>
          <div className="rolulette-right-section">
            {/* here i am call the payout section component which show on the hover on bet table */}
            <PayoutBetSection
              revertLastRoundBets={revertLastRoundBets}
              lastBetsOrder={lastBetsOrder}
              selectedBetsTest={selectedBetsTest}
              userBets={userBets}
              resetOneBet={resetOneBet}
              resetAllBet={resetAllBet}
              prevSelectedBet={prevSelectedBet}
              revertResetBets={revertResetBets}
              hover={hover}
              bettingTimer={bettingTimer.timer}
              selectedBlockchain={blockchain}
              getBets={getBets}
            />

            {/* Here i am call  the bet table component show on the wb view */}
            {browserWidth > 970 && (
              <div
                className="sm-ddnone"
                // style={{ padding: "0 10px" }}
              >
                <WebBetTable
                  userBets={userBets}
                  selectedBets={selectedBets}
                  betAmount={betAmount}
                  addBet={addBet}
                  selectedBlockchain={blockchain}
                  // Check here
                  handleMouseOver={handleMouseOver}
                  handleMouseOut={handleMouseOut}
                  hover={hover}
                  bettingTimer={bettingTimer}
                  selectedBetsTest={selectedBetsTest}
                  //tableRef={tableRef}
                />
              </div>
            )}
            {/* here i am call the place bet section component (visible in desktop display) */}
            <div className="sm-ddnone">
              <PlaceBetSection
                revertLastRoundBets={revertLastRoundBets}
                lastBetsOrder={lastBetsOrder}
                selectedBetsTest={selectedBetsTest}
                betAmount={betAmount}
                token={token}
                increseBetAmount={increseBetAmount}
                handleinfoShow={handleinfoShow}
                handleSelect={handleSelect}
                showSelect={showSelect}
                handleCurrency={handleCurrency}
                selectedBets={selectedBets}
                setBetAmount={setBetAmount}
                wrapperRef={wrapperRef}
                WalletAmount={walletAmount}
                Currency={currency}
                userBets={userBets}
                resetAllBet={resetAllBet}
                resetOneBet={resetOneBet}
                prevSelectedBet={prevSelectedBet}
                revertResetBets={revertResetBets}
                bettingTimer={bettingTimer.timer}
                hover={hover}
                selectedBlockchain={blockchain}
                getBets={getBets}
              />
            </div>
            <LastGameResult />
          </div>
        </div>

        {/* Change props */}
        <BetHistory />
      </div>
      <audio id="amount" src={GameStartMusic} muted={!playSound}></audio>
      <audio id="coinsound" src={CoinSound} muted={!playSound}></audio>

      <audio id="amount" src={GameStartMusic} muted={!playSound}></audio>
      <audio id="coinsound" src={CoinSound} muted={!playSound}></audio>
      <GameRule />
      <Info info={info} handleInfoClose={handleInfoClose} Currency={currency} />
    </Layout>
  );
};
export default Roulette;
