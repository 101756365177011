import React from 'react';
import { useSelector } from 'react-redux';
const GameHistory = () => {
	const gameObj = useSelector((state) => state.game);
	const gameHistory = gameObj.gameHistory;
	return (
		<>
			{gameHistory && (
				<div className="bet-section">
					{gameHistory &&
						gameHistory.map((el, i) => (
							<div
								className={`bet-box ${el.result && el.result.color}`}
								key={i}
							>
								{' '}
								{el.result && el.result.number}
							</div>
						))}
				</div>
			)}
		</>
	);
};
export default GameHistory;
