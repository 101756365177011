/* eslint-disable no-useless-concat */
import axios from 'axios';
import { server } from './keys';

export const gameInstance = () => {
	return axios.create({
		baseURL: `${server}/api/game`,
		headers: {
			Authorization: localStorage.getItem('usertoken')
				? `Bearer ${localStorage.getItem('usertoken')}`
				: '',
		},
		// withCredentials: true,
	});
};

export const betInstances = () => {
	return axios.create({
		baseURL: `${server}/api/bets`,
		headers: {
			Authorization: localStorage.getItem('usertoken')
				? `Bearer ${localStorage.getItem('usertoken')}`
				: '',
		},
		// withCredentials: true,
	});
};
