import React from "react";
import { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { client } from "../../config/keys";
import PropTypes from "prop-types";
const LeaveConfirmPopup = ({ setConfirmExit, confirmExit }) => {
  const handleHome = () => {
    window.location.href = `${client}/`;
  };
  const [isLoading, setLoading] = useState(false);
  return (
    <Modal
      show={confirmExit}
      onHide={() => {
        setConfirmExit(false);
        setLoading(false);
      }}
      centered
      className="friends-popup leave-confirm"
    >
      <Modal.Body>
        <div className="block">
          <p>Are sure you want to leave the Game ?</p>
          <div className="sub-btn text-center">
            <Button disabled={isLoading} onClick={() => handleHome()}>
              {isLoading ? <Spinner animation="border" /> : "Exit Room"}
            </Button>
            <Button
              onClick={() => {
                setConfirmExit(false);
                setLoading(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default LeaveConfirmPopup;
LeaveConfirmPopup.propTypes = {
  setConfirmExit: PropTypes.any,
  confirmExit: PropTypes.any,
  handleExitRoom: PropTypes.any,
};
