import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStatics } from "../../reducers/gameReducers";
//import SVG from '../SVG';
const Statistics = () => {
  const dispatch = useDispatch();
  const gameObj = useSelector((state) => state.game);
  const [round, setRound] = useState(50);
  const {
    colorStats,
    oddEvenStats,
    hotColdStats,
    numbersStats,
    dozenStats,
    columnStats,
  } = gameObj.statics;
  const { cold, hot } = hotColdStats;
  const { firstDozen, secondDozen, thirdDozen } = dozenStats || [];
  const { firstColumn, secondColumn, thirdColumn } = columnStats || [];

  useEffect(() => {
    dispatch(getStatics({ limit: round }));
  }, [round]);

  const handleChangeRounds = (e) => {
    setRound(parseInt(e.target.value));
  };
  // const colorObject = {
  //   primary: '#1949F3',
  //   secondary: '#CAD6FF',
  //   secondaryDown: '#DFE6FF',
  //   textColor: 'green', //'#021454',
  // };
  return (
    <div className="statistic-section">
      <div className="statistics-options">
        <h6>STATISTICS</h6>
        {/* <SVG color={colorObject} /> */}
        <span>
          <select
            className="stat-select"
            onChange={(e) => handleChangeRounds(e)}
          >
            <option>Last 50 Rounds</option>
            <option value="50"> 50 Rounds </option>
            <option value="100"> 100 Rounds</option>
            <option value="200">200 Rounds</option>
          </select>
        </span>
      </div>
      <div className="stastics">
        {/* <div className="stat-flex"></div> */}
        <div className="left-statistic-section">
          <div className="stat-flex hot-cold-box">
            <span>HOT</span>
            <span>COLD</span>
          </div>
          {/* Hot cold */}
          <div className="stat-flex hot-number-box">
            <div className="left-hot-number">
              {hot && (
                <div className="stat-bet-section">
                  {hot.length > 0 &&
                    hot.map((el, i) => (
                      <div className={`bet-box ${el.color}`} key={i}>
                        {" "}
                        {el._id}
                      </div>
                    ))}
                </div>
              )}
            </div>
            <div className="right-hot-number">
              {cold && (
                <div className="stat-bet-section">
                  {cold.length > 0 &&
                    cold.map((el, i) => (
                      <div className={`bet-box ${el.color}`} key={i}>
                        {" "}
                        {el._id}
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="right-statistic-section">
          {/* Color stats */}
          <div className="stat-meter">
            <div
              className="stat-meter-red stat-flex"
              style={{ width: `${colorStats?.red?.percent.toFixed(2)}%` }}
            >
              <span>Red</span>
              <span>{Math.trunc(colorStats?.red?.percent)}%</span>
            </div>
            <div
              className="stat-meter-green"
              style={{
                width: `${colorStats?.green?.percent?.toFixed(2)}%`,
              }}
            >
              {/* <span>10%</span> */}
            </div>
            <div
              className="stat-meter-dark stat-flex"
              style={{
                width: `${colorStats?.black?.percent?.toFixed(2)}%`,
              }}
            >
              <span>Black</span>
              <span>{Math.trunc(colorStats?.black?.percent)}%</span>
            </div>
          </div>
          {/* odd even stats */}
          <div className="stat-meter">
            <div
              className="stat-meter-odd stat-flex"
              style={{
                width: `${oddEvenStats?.odd?.percent.toFixed(2)}%`,
              }}
            >
              <span>odd</span>
              <span>{Math.trunc(oddEvenStats?.odd?.percent)}%</span>
            </div>

            {/* <div
						className="stat-meter-green"
						style={{
							width: `${colorPercent && colorPercent.green.toFixed(2)}%`,
						}}
					>
						{/* <span>10%</span> */}
            {/* </div> */}
            <div
              className="stat-meter-dark stat-flex"
              style={{
                width: `${oddEvenStats?.even?.percent.toFixed(2)}%`,
              }}
            >
              <span>Even</span>
              <span>{Math.trunc(oddEvenStats?.even?.percent)}%</span>
            </div>
          </div>

          <div className="stat-meter">
            <div
              className="stat-meter-odd stat-flex"
              style={{
                width: `${numbersStats?.oneToNineteen?.percent.toFixed(2)}%`,
              }}
            >
              <span>1-18</span>
              <span>{Math.trunc(numbersStats?.oneToNineteen?.percent)}%</span>
            </div>

            <div
              className="stat-meter-green"
              style={{
                width: `${numbersStats?.zero?.percent?.toFixed(2)}%`,
              }}
            >
              {/* <span>{numbersStats.zero.percent.toFixed(2)}%</span> */}
            </div>
            <div
              className="stat-meter-dark stat-flex"
              style={{
                width: `${numbersStats?.nineteenToThirtySix?.percent.toFixed(
                  2
                )}%`,
              }}
            >
              <span>19-36</span>
              <span>
                {Math.trunc(numbersStats?.nineteenToThirtySix?.percent)}%
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="stastics">
        <div className="left-statistic-section">
          <h4>DOZENS</h4>
          <div className="dozens-list">
            <p>1st 12</p>
            <h6>{firstDozen && Math.trunc(firstDozen?.percent)}%</h6>
          </div>
          <div className="dozens-list">
            <p>2nd 12</p>
            <h6>{secondDozen && Math.trunc(secondDozen?.percent)}%</h6>
          </div>
          <div className="dozens-list">
            <p>3rd 12</p>
            <h6>{thirdDozen && Math.trunc(thirdDozen?.percent)}%</h6>
          </div>
        </div>
        <div className="right-statistic-section">
          <h4>COLUMNS</h4>
          <div className="dozens-list">
            <p>{firstColumn && Math.trunc(firstColumn?.percent)}%</p>
            <h6>1</h6>
          </div>
          <div className="dozens-list">
            <p>{secondColumn && Math.trunc(secondColumn?.percent)}%</p>
            <h6>2</h6>
          </div>
          <div className="dozens-list">
            <p>{thirdColumn && Math.trunc(thirdColumn?.percent)}%</p>
            <h6>3</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
