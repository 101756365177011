import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getFareResult } from '../../reducers/gameReducers';
import { Button, Form } from 'react-bootstrap';
import Layout from '../layout/layout';
//import coin from '../../assets/images/header/wallet.png';
const RouletteResult = () => {
	const params = useParams();
	const dispatch = useDispatch();
	const initialValues = {
		secret: '',
		nonce: '',
	};

	const [values, setValues] = useState(initialValues);
	const [result, setResult] = useState();

	useEffect(() => {
		const { secret, nonce } = params;
		if (secret && nonce) {
			setValues((prev) => {
				return { ...prev, secret, nonce };
			});
		} else {
			setValues(initialValues);
			setResult('');
		}
	}, [params]);

	//const [newResult, setNewResult] = useState("");

	const handleInputChange = (e) => {
		const { name, value } = e.target;

		setValues({
			...values,
			[name]: value,
		});
	};

	useEffect(() => {
		dispatch(getFareResult({ ...values }));

		//  setNewResult(fullSeed);
	}, []);

	const handleSubmit = async () => {
		if (values.nonce && values.secret) {
			const res = await dispatch(getFareResult({ ...values }));

			setResult(res.payload.fareResult.result);
		}
	};

	return (
		<Layout>
			<div className="game-result">
				<h2>Fairness Calculation</h2>
				<Form onSubmit={handleSubmit}>
					<div>
						<pre>
							{`const getResult = async (secret, nonce, divisions = 38) => {
                   const fullSeed = crypto
                   .createHash("sha256")
                    .update(secret:nonce)
                     .digest("hex");

                      const seed = fullSeed.substr(0, 8);

                       return parseInt(seed, 16) % divisions;
                    }`}
							;
						</pre>
					</div>
					<Form.Group className="mb-3" controlId="formBasicEmail">
						<Form.Label>secret</Form.Label>
						<Form.Control
							type="text"
							name="secret"
							placeholder="secret"
							value={values.secret}
							required
							onChange={handleInputChange}
						/>
					</Form.Group>

					<Form.Group className="mb-3" controlId="formBasicEmail">
						<Form.Label>nonce</Form.Label>
						<Form.Control
							type="text"
							name="nonce"
							value={values.nonce}
							placeholder="nonce"
							required
							onChange={handleInputChange}
						/>
					</Form.Group>
					{result && (
						<Form.Group className="mb-3" controlId="formBasicPassword">
							<Form.Label>Final Result</Form.Label>
							<Form.Control
								type="text"
								placeholder="final Result"
								value={result}
							/>
						</Form.Group>
					)}

					<div className='verify-btn'>
					<Button
						variant="primary"
						type="button"
						onClick={() => handleSubmit()}
					>
						Submit
					</Button>
					</div>

					{/* <Link
          to={{
            pathname: "/",
            state: {
              winners: "WINNERS",
            },
          }}
        >
          Back
        </Link> */}
				</Form>
			</div>
		</Layout>
	);
};

export default RouletteResult;

RouletteResult.propTypes = {};
