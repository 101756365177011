/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import useGameHistory from "./useGameHistory";
import { casinoServer } from "../../config/keys";
import user from "../../assets/images/header/owner.png";
import ReactPaginate from "react-paginate";
import RouleteeSvg from "../../assets/images/footer/roulette.svg";
import BTC from "../../assets/images/roulette/btc.png";
import ETH from "../../assets/images/roulette/eth.png";
import LTC from "../../assets/images/roulette/ltc.png";
import doller from "../../assets/images/roulette/doller.jpg";
const WinHistory = () => {
  const itemperPage = 10;
  const [historyBetsSkip] = useState(0);
  const [currentItems, setCurrentItems] = useState(null);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const [gameHistory, gamesBets, userBets, loading, winHistory] =
    useGameHistory(historyBetsSkip);
  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemperPage;
    setCurrentItems(winHistory.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(winHistory.length / itemperPage));
  }, [itemOffset, itemperPage, winHistory]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemperPage) % winHistory.length;
    setItemOffset(newOffset);
  };

  console.log("currentItems", currentItems);
  const handleAmount = (profit_loss) => {
    if (profit_loss) {
      return profit_loss?.toFixed(6);
    } else {
      return "0";
    }
  };

  return (
    <div>
      <Table bordered responsive variant="dark">
        <thead>
          <tr>
            <th>Game</th>
            <th>User</th>
            <th>Bet Amount</th>
            <th>Result</th>
            <th>Payout (BSV)</th>
          </tr>
        </thead>

        <tbody>
          {currentItems &&
            currentItems?.length &&
            currentItems.map((el, i) => (
              <React.Fragment key={`gamesBetTr-${Date.now() + i}`}>
                <tr
                  // onClick={() => handleShow(el)}
                  key={`gamesBetTr-${Date.now() + i}`}
                  className="pointer-cursor"
                >
                  <td>
                    <div className="user-pic">
                      <img src={RouleteeSvg} alt="jdhjfhdsjjfd" />{" "}
                      {el?.gameType ? el?.gameType : "---"}{" "}
                    </div>
                  </td>
                  <td>
                    <div className="user-pic">
                      <img
                        src={
                          el?.userid?.profilePic
                            ? `${casinoServer}/${el?.userid?.profilePic}`
                            : user
                        }
                        alt="avtar"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = user;
                        }}
                      />{" "}
                      <span title={el?.userid?.username}>
                        {el?.userid?.username.length > 8
                          ? el?.userid?.username.slice(0, 8) + "..."
                          : el?.userid?.username}
                      </span>
                    </div>
                  </td>
                  <td>
                    <img
                      src={
                        el.blockchain === "btc"
                          ? BTC
                          : el.blockchain === "ltc"
                          ? LTC
                          : ETH
                      }
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = doller;
                      }}
                    />
                    &nbsp;
                    {el?.betAmount ? el.betAmount?.toFixed(6) : "0"}
                  </td>
                  <td>
                    {" "}
                    <span title={el?.result}>
                      {el?.result !== "undefined"
                        ? el?.result?.length > 25
                          ? el?.result.slice(0, 25) + "..."
                          : el?.result
                        : "---"}{" "}
                    </span>
                  </td>
                  <td
                    className={`${
                      el?.profit_loss
                        ? el.profit_loss > 0
                          ? "text-success"
                          : "text-danger"
                        : ""
                    }`}
                  >
                    {handleAmount(el?.profit_loss) > 0 ? (
                      <>
                        {" "}
                        {
                          <img
                            src={
                              el.blockchain === "btc"
                                ? BTC
                                : el.blockchain === "ltc"
                                ? LTC
                                : ETH
                            }
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = doller;
                            }}
                          />
                        }
                        {handleAmount(el?.profit_loss)}
                      </>
                    ) : (
                      <>
                        - {""}
                        {
                          <img
                            src={
                              el.blockchain === "btc"
                                ? BTC
                                : el.blockchain === "ltc"
                                ? LTC
                                : ETH
                            }
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = doller;
                            }}
                          />
                        }
                        {handleAmount(el?.profit_loss).substring(1)}
                      </>
                    )}
                  </td>
                </tr>
              </React.Fragment>
            ))}
        </tbody>
      </Table>
      <div className="pagination">
        {winHistory?.length > 0 ? (
          <ReactPaginate
            previousLabel=" Previous"
            marginPagesDisplayed={-2}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName="pagination-chk"
            previousLinkClassName="pagination__link"
            nextLinkClassName="pagination__link"
            disabledClassName="pagination__link--disabled"
            activeClassName="pagination__link--active"
            nextLabel="Next "
          />
        ) : null}
      </div>
    </div>
  );
};

export default WinHistory;
