import propTypes from "prop-types";
import { memo } from "react";

const ShowFixBetAmount = ({ betTotal }) => {
  const toFixed = (x) => {
    if (Math.abs(x) < 1.0) {
      var am = parseInt(x.toString().split("e-")[1]);
      if (am) {
        x *= Math.pow(10, e - 1);
        x = "0." + new Array(e).join("0") + x.toString().substring(5);
      }
      return am;
    } else {
      var e = parseInt(x.toString().split("+")[1]);
      if (e > 20) {
        e -= 20;
        x /= Math.pow(10, e);
        x += new Array(e + 1).join("0");
      }
    }
    const str = "0." + x.split(".")[1].split("0")?.join("");

    return str;
  };

  let showAmount = 0;
  if (!betTotal) {
    return 0;
  }

  if (betTotal < 0) {
    showAmount = toFixed(betTotal);
  } else if (betTotal >= 1) {
    showAmount = betTotal?.toFixed(6) || betTotal;
  } else {
    showAmount = betTotal?.toFixed(6) || betTotal;
  }

  return <>{showAmount}</>;
};
const ShowFixBetAmountMemo = memo(ShowFixBetAmount);
export default ShowFixBetAmountMemo;

ShowFixBetAmount.propTypes = {
  betTotal: propTypes.number,
};
