import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getFareResult } from "../reducers/gameReducers";
import sym1 from "../assets/images/slot/item1.png";
import sym2 from "../assets/images/slot/item2.png";
import sym3 from "../assets/images/slot/item3.png";
import sym4 from "../assets/images/slot/item4.png";
import sym5 from "../assets/images/slot/item5.png";
import sym6 from "../assets/images/slot/item6.png";
import sym7 from "../assets/images/slot/item7.png";
import sym8 from "../assets/images/slot/item8.png";
import sym9 from "../assets/images/slot/item9.png";
import sym10 from "../assets/images/slot/item10.png";
import sym11 from "../assets/images/slot/item11.png";
import { Button, Form } from "react-bootstrap";
import Layout from "./layout/layout";
//import coin from '../../assets/images/header/wallet.png';
const RouletteResult = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const initialValues = {
    secret: "",
    nonce: "",
  };
  const IMAGES = [
    sym1,
    sym2,
    sym3,
    sym4,
    sym5,
    sym6,
    sym7,
    sym8,
    sym9,
    sym10,
    sym11,
  ];

  console.log("IMAGES", IMAGES);

  const [values, setValues] = useState(initialValues);
  const [result, setResult] = useState();

  useEffect(() => {
    const { secret, nonce } = params;
    if (secret && nonce) {
      setValues((prev) => {
        return { ...prev, secret, nonce };
      });
    } else {
      setValues(initialValues);
      setResult("");
    }
  }, [params]);

  //const [newResult, setNewResult] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  useEffect(() => {
    dispatch(getFareResult({ ...values }));

    //  setNewResult(fullSeed);
  }, []);

  console.log("value", values?.secret);
  // let d = values?.secret;

  if (values.secret) {
    console.log("dnnnnnn", JSON.parse(`${values.secret}`));
  }

  const handleSubmit = async (gameType) => {
    if (values.nonce && values.secret) {
      const res = await dispatch(getFareResult({ ...values, gameType }));

      setResult(res.payload.fareResult.result);
    }
  };

  const getImage = useCallback((number) => {
    switch (number) {
      case 0:
        return sym1;
      case 1:
        return sym2;
      case 2:
        return sym3;
      case 3:
        return sym4;
      case 4:
        return sym5;
      case 5:
        return sym6;
      case 6:
        return sym7;
      case 7:
        return sym8;
      case 8:
        return sym9;
      case 9:
        return sym10;
      case 10:
        return sym11;
    }
  }, []);

  console.log(
    "jj",
    values.secret && JSON.parse(`${values.secret}`)[0][0].substring(0, 7)
  );

  return (
    <Layout>
      <div className="game-result">
        <h2>Fairness Calculation</h2>
        <Form onSubmit={handleSubmit}>
          <div>
            <pre>
              {`const convertHashToNumber = async (hashArray, nonce) => {
      if (hashArray) {  
    let parseData = JSON.parse(hashArray);
    let numberArray = [[], [], [], []];
    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 5; j++) {
        numberArray[i][j] = await getResult(parseData[i][j], nonce);
        numberArray[1] = numberArray[2];
        numberArray[3] = numberArray[0];
      }
    }
    return numberArray;
  }
};`}
              <br />
              {"  "}
              {`const getResult = async (secret, nonce, divisions = 11) => {
                   const fullSeed = crypto
                   .createHash("sha256")
                    .update(secret:nonce)
                     .digest("hex");
                      const seed = fullSeed.substr(0, 8);
                       return parseInt(seed, 16) % divisions;
                    }`}
              ;
            </pre>
          </div>

          <div className="bet">
            <p>secret</p>[
            <span title={values.secret}>
              [
              {values.secret &&
                JSON.parse(`${values.secret}`)[0][0].substring(0, 75) + "..."}
              ]
            </span>
            {/* {values.secret &&
              JSON.parse(`${values.secret}`).map((el) => {
                console.log("elll", el);
                return (
                  <>
                    [{" "}
                    {el &&
                      el.map((elem) => {
                        return `'${elem.substring(0, 7)}...', `;
                      })}{" "}
                    ]
                    <br />
                  </>
                );
              })}{" "} */}
            ]
          </div>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>nonce</Form.Label>
            <Form.Control
              type="text"
              name="nonce"
              value={values.nonce}
              placeholder="nonce"
              required
              onChange={handleInputChange}
            />
          </Form.Group>
          <div className="slot-result-section">
            <div className="left-slot-result">
              {result &&
                result?.map((el, i) => {
                  return (
                    <div className="slot-numbers" key={i}>
                      [{" "}
                      {el.map((elem, index) => {
                        console.log("elem", elem);
                        if (elem.length - 1 === index) {
                          return elem;
                        } else {
                          return `${elem}, `;
                        }
                      })}
                      ]
                      <br />
                    </div>
                  );
                })}{" "}
            </div>
            <div className="right-slot-result">
              {result &&
                result?.map((el, i) => {
                  return (
                    <div className="slot-images" key={i}>
                      {el.map((elem, index) => {
                        if (elem.length - 1 === index) {
                          return elem;
                        } else {
                          return (
                            <img
                              style={{ width: "100px" }}
                              src={getImage(elem)}
                            />
                          );
                        }
                      })}{" "}
                      <br />
                    </div>
                  );
                })}{" "}
            </div>
          </div>
          <div className="verify-btn">
            <Button
              variant="primary"
              type="button"
              onClick={() => handleSubmit("Slot")}
            >
              Verify Result
            </Button>
          </div>
          {/* <Link
          to={{
            pathname: "/",
            state: {
              winners: "WINNERS",
            },
          }}
        >
          Back
        </Link> */}
        </Form>
      </div>
    </Layout>
  );
};

export default RouletteResult;

RouletteResult.propTypes = {};
