import i18n from "i18next";
import englishTranslation from "../i18n/en/translation.json";
import turkishTranslation from "../i18n/tr/translation.json";
import { initReactI18next } from "react-i18next";

export const resources = {
  en: {
    translation: englishTranslation,
  },
  tr: {
    translation: turkishTranslation,
  },
};

i18n.use(initReactI18next).init({
  lng: "en",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources,
});

export default i18n;
