import {React, useState} from "react";
import close from "../../../assets/images/header/close.svg";
import {Button} from "react-bootstrap";
import "../roulette.css";
import Statistics from "../../roulette/statistics";
import { Helmet } from "react-helmet";

const MobileStatsPopup = () => {

    const [statspopupclose, setstatsClose] = useState(false);
    const handlestatspopupClose = () => setstatsClose(!statspopupclose);

  return (
    <>
    <Helmet>
      <html className={`${statspopupclose ? 'hidden-body' : ''}`} lang="en" />
    </Helmet>
    <Button
      onClick={handlestatspopupClose}

      className="mobile-view-button btn-sm "
    >
      View Statistics
    </Button>

    <div
      className={`small-bet-section ${
        !statspopupclose ? 'close-bet-section' : 'open-bet-section'
      }`}

    >
    {/* <div
        className={`small-bet-section ${statspopupclose && "open-bet-section"}`}
      > */}
      <div className="small-bet-container">
        <div className="close-bet-section">
          {" "}
          <img
            src={close}
            alt="close"
            onClick={handlestatspopupClose}
          />
        </div>

        {/* Statistics Table */}
        <Statistics />
      </div>
    </div>
  </>
  )
}

export default MobileStatsPopup